/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState, useLayoutEffect} from 'react'
import {KTIcon} from '../../../../helpers'
import * as am5 from '@amcharts/amcharts5'
import * as am5map from '@amcharts/amcharts5/map'
import * as am5xy from '@amcharts/amcharts5/xy'
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated'
import am5geodata_worldLow from '@amcharts/amcharts5-geodata/worldLow'
import {useUserContext} from '../../../../../UserContext'
import config from '../../../../../config.json'

type Props = {
  title?: string
}

const EntMapCard: React.FC<Props> = ({title = 'Map'}) => {
  const [countryInfo, setCountryInfo] = useState([
    {
      id: 'US',
      number: '10',
      polygonSettings: {
        fill: am5.color(0xff3c38),
      },
    },
    {
      id: 'AE',
      number: '100',
      polygonSettings: {
        fill: am5.color(0xff3c38),
      },
    },
    {
      id: 'CA',
      number: '50',
      polygonSettings: {
        fill: am5.color(0xff3c38),
      },
    },
    {
      id: 'MX',
      number: '25',
      polygonSettings: {
        fill: am5.color(0xff3c38),
      },
    },
    {
      id: 'PK',
      number: '300',
      polygonSettings: {
        fill: am5.color(0xff3c38),
      },
    },
  ])
  const [updatedCountryInfo, setUpdatedCountryInfo] = useState(false)

  const {user} = useUserContext()

  async function callEmpData() {
    if (user?.['user id']) {
      const allEmpDataSet = await fetch(config.domainName + ':' + config.port + '/api/employee', {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({
          entity_id: user['user id'],
        }),
      })

      const allEmpDataCont = await allEmpDataSet.json()

      const dummyData: string[] = allEmpDataCont.employees.map(
        (employee: any) => employee.nationality
      )
      // Create an empty object to store the counts
      const countryCounts: {[key: string]: number} = {}

      // Iterate through the input array and count occurrences
      dummyData.forEach((country) => {
        if (countryCounts[country]) {
          countryCounts[country]++
        } else {
          countryCounts[country] = 1
        }
      })

      // Convert the counts into the desired format
      const outputArray = Object.keys(countryCounts).map((country) => ({
        id: country,
        number: countryCounts[country].toString(),
        polygonSettings: {
          fill: am5.color(0xff3c38),
        },
      }))

      setCountryInfo(outputArray)
      setUpdatedCountryInfo(true)
    }
  }

  useEffect(() => {
    callEmpData()
  }, [])

  useLayoutEffect(() => {
    if (updatedCountryInfo) {
      const root = am5.Root.new('mapchartdiv')
      const chart = root.container.children.push(
        am5map.MapChart.new(root, {
          projection: am5map.geoMercator(),
          panX: 'rotateX',
          wheelY: 'zoom',
          minZoomLevel: 0.5,
          maxZoomLevel: 16,
          homeZoomLevel: 3,
          homeGeoPoint: {longitude: 55, latitude: 25},
        })
      )

      const polygonSeries = chart.series.push(
        am5map.MapPolygonSeries.new(root, {
          geoJSON: am5geodata_worldLow, // Use built-in world map data
          exclude: ['AQ'],
        })
      )
      polygonSeries.events.on('datavalidated', function () {
        chart.goHome()
      })
      polygonSeries.mapPolygons.template.setAll({
        stroke: am5.color(0xffffff),
        templateField: 'polygonSettings',
        fillOpacity: 1,
        fill: am5.color('#045495'),
        tooltipText: '{name} \n{number}', // Display country name and number in tooltip
        interactive: true,
      })

      polygonSeries.mapPolygons.template.states.create('hover', {
        fill: am5.color('#0550f0'),
      })

      // Set countryInfo data dynamically
      polygonSeries.data.setAll(countryInfo)
    }
  }, [countryInfo, updatedCountryInfo]) // Include countryInfo in the dependency array to re-render on data changes

  return (
    <div className='card h-100 w-100'>
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>{title}</span>
        </h3>
      </div>
      <div className='card-body'>
        <div id='mapchartdiv' style={{width: '100%', height: '100%'}}></div>
      </div>
    </div>
  )
}

export {EntMapCard}
