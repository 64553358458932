import {useEffect, useState, FC, useRef} from 'react'
import config from '../../../../../config.json'
import {useUserContext} from '../../../../../UserContext'
import {useThemeMode} from '../../../../../_metronic/partials/layout/theme-mode/ThemeModeProvider'

interface EmpLMInfo {
  managerName: string
  lmScore: number
  dateModified: string
  managerPosition: string
  managerEmpNum: string
}

// Helper function to clean up color values
function cleanColor(color: any) {
  // remove any leading or trailing whitespace
  color = color.trim()
  // remove any leading # characters
  color = color.replace(/^#+/, '')
  // ensure the color string is exactly 6 characters long
  if (color.length < 6) {
    color = color.padStart(6, '0')
  } else if (color.length > 6) {
    color = color.slice(0, 6)
  }
  // add a leading # character
  color = '#' + color
  return color
}

const threshold = 2
const chartIndex = 'LineManager-Chart'

export const LineManager: FC<{
  isCompact?: boolean
  employeeQuery?: string
  className?: string
}> = ({isCompact = false, employeeQuery, className}) => {
  const [empLMInfo, setEmpLMInfo] = useState<EmpLMInfo>()
  const [updatedLMInfo, setUpdatedLMInfo] = useState<EmpLMInfo>()
  const [updatedData, setUpdatedData] = useState<boolean>(false)
  const {user} = useUserContext()
  const chartRef = useRef<HTMLDivElement>(null)
  const {mode} = useThemeMode()
  const [showAlert, setShowAlert] = useState<boolean>(false)
  const [alertMessage, setAlertMessage] = useState<string>('')
  const [alertClassName, setAlertClassName] = useState<string>('alert-success') // Class name for the alert

  const [showLoading, setShowLoading] = useState(false) // State to control showing the warning message
  const [showEdit, setShowEdit] = useState(false) // State to control showing the warning message

  const [sliderVal, setSliderVal] = useState(0)

  let pieTest = {
    'total skills': 100,
    'total score': empLMInfo ? empLMInfo.lmScore : 0,
  }

  const warningTime = 5000
  const chartSize = 100
  const chartLine = 20
  const chartRotate = 0

  async function callLineManagerInfo(employeeQuery: string | undefined) {
    if (employeeQuery) {
      const empLineManagerInfo = await fetch(
        config.domainName + ':' + config.port + '/api/employeequery',
        {
          method: 'POST',
          headers: {'Content-Type': 'application/json'},
          body: JSON.stringify({
            function: 'GetLineManagerScore',
            employee_id: employeeQuery,
          }),
        }
      )
      const empLineManagerInfoCont = await empLineManagerInfo.json()
      if (empLineManagerInfoCont['score']) {
        const lmInfo: EmpLMInfo = {
          managerName: empLineManagerInfoCont['manager name'],
          lmScore: empLineManagerInfoCont['score'],
          dateModified: empLineManagerInfoCont['date modified'],
          managerPosition: empLineManagerInfoCont['manager position'],
          managerEmpNum: empLineManagerInfoCont['manager employee number'],
        }

        setEmpLMInfo(lmInfo)
        setUpdatedLMInfo(lmInfo)
        setUpdatedData(false)
      }
    }
  }

  async function setLineManagerInfo(employeeQuery: string | undefined) {
    if (employeeQuery && updatedLMInfo) {
      const empLineManagerInfo = await fetch(
        config.domainName + ':' + config.port + '/api/employeequery',
        {
          method: 'POST',
          headers: {'Content-Type': 'application/json'},
          body: JSON.stringify({
            function: 'SetLineManagerScore',
            employee_id: employeeQuery,
            score: updatedLMInfo.lmScore.toString(),
            'manager employee number': updatedLMInfo.managerEmpNum,
            'manager position': updatedLMInfo.managerPosition,
            'manager name': updatedLMInfo.managerName,
          }),
        }
      )
      const empLineManagerInfoCont = await empLineManagerInfo.json()

      setUpdatedData(true)

      if (empLineManagerInfo.status == 200) {
        setAlertMessage('Success' || '')
        setAlertClassName(empLineManagerInfo.status !== null ? 'alert-success' : 'alert-danger')
        setShowAlert(true)
        setTimeout(() => {
          setShowAlert(false)
        }, 3000)
      } else {
        setAlertMessage('Error setting training')
        setAlertClassName(empLineManagerInfo.status !== null ? 'alert-success' : 'alert-danger')
      }
    }
  }

  useEffect(() => {
    if (employeeQuery) {
      callLineManagerInfo(employeeQuery)
    } else {
      callLineManagerInfo(user?.['user id'])
    }
  }, [employeeQuery, updatedData])

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowLoading(true)
    }, warningTime)
    return () => clearTimeout(timer)
  }, [])

  useEffect(() => {
    refreshChart()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mode, pieTest, sliderVal])

  const refreshChart = () => {
    if (!chartRef.current || !updatedLMInfo) {
      return
    }

    setTimeout(() => {
      initChart(
        chartSize,
        chartLine,
        chartRotate,
        100,
        updatedLMInfo.lmScore * 100,
        chartIndex,
        isCompact
      )
    }, 10)
  }

  const updateData = (value: string, id: string) => {
    var currentState: EmpLMInfo | undefined = {...updatedLMInfo} as EmpLMInfo // Explicitly set the type as EmpLMInfo

    if (currentState) {
      if (id == 'empPos') {
        currentState.managerPosition = value
      } else if (id == 'empNum') {
        currentState.managerEmpNum = value
      } else if (id == 'empName') {
        currentState.managerName = value
      } else if (id == 'score') {
        currentState.lmScore = (value as unknown as number) / 100
      }

      setUpdatedLMInfo(currentState)
    }
  }

  const saveDetails = () => {
    if (employeeQuery) {
      setLineManagerInfo(employeeQuery)
    } else {
      setLineManagerInfo(user?.['user id'])
    }
    setShowEdit(!showEdit)
  }

  const cancelDetails = () => {
    // console.log(empLMInfo)
    // console.log(updatedLMInfo)
    setUpdatedLMInfo(empLMInfo)

    setShowEdit(!showEdit)
  }

  const setShowEditButton = () => {
    setShowEdit(!showEdit)
  }

  return (
    <div className={className}>
      <div className='h-100 d-flex flex-row'>
        <div className={isCompact ? 'col-12 mt-0 h-100' : 'col-8 mb-5 h-100'}>
          <div className={`card mb-4 mb-xxl-8 h-100 overflow-scroll`}>
            <div className='card-header align-items-center mb-1 border-0 pt-5 '>
              <h3 className='card-title align-items-start flex-column'>
                <span className='card-label fw-bold fs-3 '>Line Manager</span>
              </h3>
              {user?.['user type'] == 'entity-editor' && updatedLMInfo && (
                <i
                  className={
                    showEdit
                      ? 'fa-solid  fa-xmark text-hover-dark fs-2'
                      : 'fa-solid fa-pen-to-square text-hover-dark fs-2'
                  }
                  onClick={setShowEditButton}
                ></i>
              )}
            </div>
            {updatedLMInfo ? (
              <div className='card-body py-5 justify-content-center align-content-center'>
                <div className='table-responsive'>
                  {!showEdit && (
                    <div className='d-flex flex-center me-5 pt-2'>
                      <div
                        id={chartIndex}
                        ref={chartRef}
                        style={{
                          minWidth: `${chartSize / 2}px`,
                          minHeight: `${chartSize / 2}px`,
                        }}
                        data-kt-size={chartSize / 3}
                        data-kt-line={chartLine / 2}
                      ></div>
                      <div style={{width: '50px'}}></div>
                      <div className='fw-bolder text-white-700 text-xxl-end fs-2x'>
                        <span>{Math.round(updatedLMInfo.lmScore * 100)}%</span>
                      </div>
                    </div>
                  )}
                  <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4 table-fixed'>
                    <tbody>
                      {showEdit && (
                        <tr>
                          <td className='text-dark fw-bold text-hover-primary mb-1 fs-5'>Score</td>
                          <td>
                            <input
                              type='range'
                              defaultValue={Math.round(updatedLMInfo.lmScore * 100)}
                              min={0}
                              max={100}
                              step={5}
                              className='form-range'
                              onChange={(e) => updateData(e.target.value, 'score')}
                            />
                            <span>{Math.round(updatedLMInfo.lmScore * 100)}</span>
                          </td>
                        </tr>
                      )}
                      <tr>
                        <td className='text-dark fw-bold text-hover-primary mb-1 fs-5'>
                          Last Updated
                        </td>
                        <td>{new Date(updatedLMInfo.dateModified).toLocaleDateString()}</td>
                      </tr>
                      <tr>
                        <td className='text-dark fw-bold text-hover-primary mb-1 fs-5'>
                          Updated by
                        </td>
                        {showEdit ? (
                          <td>
                            <div>
                              <input
                                type='text'
                                className='form-control'
                                defaultValue={updatedLMInfo.managerName}
                                id='updatedByField'
                                onChange={(e) => updateData(e.target.value, 'empName')}
                              />
                            </div>
                          </td>
                        ) : (
                          <td>{updatedLMInfo.managerName}</td>
                        )}
                      </tr>
                      <tr>
                        <td className='text-dark fw-bold text-hover-primary mb-1 fs-5'>Position</td>
                        {showEdit ? (
                          <td>
                            <div>
                              <input
                                type='text'
                                className='form-control'
                                defaultValue={updatedLMInfo.managerPosition}
                                id='positionField'
                                onChange={(e) => updateData(e.target.value, 'empPos')}
                              />
                            </div>
                          </td>
                        ) : (
                          <td>{updatedLMInfo.managerPosition}</td>
                        )}
                      </tr>
                      <tr>
                        <td className='text-dark fw-bold text-hover-primary mb-1 fs-5'>
                          Employee Number
                        </td>
                        {showEdit ? (
                          <td>
                            <div>
                              <input
                                type='text'
                                className='form-control'
                                defaultValue={updatedLMInfo.managerEmpNum}
                                id='empNumberField'
                                onChange={(e) => updateData(e.target.value, 'empNum')}
                              />
                            </div>
                          </td>
                        ) : (
                          <td>{updatedLMInfo.managerEmpNum}</td>
                        )}
                      </tr>
                    </tbody>
                  </table>
                  {showAlert && (
                    <div className={`alert ${alertClassName} justify-content-center  `}>
                      {alertMessage}
                    </div>
                  )}
                  {showEdit && (
                    <div className='d-flex flex-row py-5 justify-content-end'>
                      <a onClick={cancelDetails} className='btn btn-light'>
                        Cancel
                      </a>
                      <div className='mx-2'></div>
                      <a onClick={saveDetails} className='btn btn-primary'>
                        Save
                      </a>
                    </div>
                  )}
                </div>
              </div>
            ) : showLoading ? (
              <div className='card-body py-3'>
                <div className='table-responsive'>
                  <div className='d-flex justify-content-center m-5 fw-bold fs-4 flex-column-fluid'>
                    There are no Line Manager score available. Please go to the Assessments tab to
                    see if you have any pending assessments!
                  </div>
                </div>
              </div>
            ) : (
              <div className='card-body h-100 pt-2 pb-4 d-flex justify-content-center flex-column-fluid '>
                <span
                  className='spinner-border spinner-border-lg spinner-info'
                  style={{
                    width: '80px',
                    height: '80px',
                    border: ' var(--bs-spinner-border-width) solid #0550F0',
                    borderRightColor: 'var(--bs-light)',
                    borderWidth: '10px',
                  }}
                ></span>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

const initChart = function (
  chartSize: number = 70,
  chartLine: number = 11,
  chartRotate: number = 145,
  number1: number,
  number2: number,
  chartIndex: string,
  isCompact: boolean
) {
  if (isCompact == true) {
    chartSize = chartSize / 2
    chartLine = chartLine / 2
    chartRotate = chartRotate / 2
  }
  const el = document.getElementById(chartIndex)
  if (!el) {
    return
  }
  el.innerHTML = ''

  var options = {
    size: chartSize,
    lineWidth: chartLine,
    rotate: chartRotate,
  }

  const canvas = document.createElement('canvas')
  const span = document.createElement('span')

  // @ts-ignore
  if (typeof G_vmlCanvasManager !== 'undefined') {
    // @ts-ignore
    G_vmlCanvasManager.initElement(canvas)
  }

  const ctx = canvas.getContext('2d')
  canvas.width = canvas.height = options.size

  el.appendChild(span)
  el.appendChild(canvas)

  // @ts-ignore
  ctx.translate(options.size / 2, options.size / 2) // change center
  // @ts-ignore
  ctx.rotate((-1 / 2 + options.rotate / 180) * Math.PI) // rotate -90 deg

  const radius = (options.size - options.lineWidth) / 2

  const drawCircle = function (color: string, lineWidth: number, percent: number) {
    percent = Math.min(Math.max(0, percent || 1), 1)
    if (!ctx) {
      return
    }

    ctx.beginPath()
    ctx.arc(0, 0, radius, 0, Math.PI * 2 * percent, false)
    ctx.strokeStyle = color
    ctx.lineCap = 'round' // butt, round or square
    ctx.lineWidth = lineWidth
    ctx.stroke()
  }

  let percentage = number2 / number1 // Assuming the maximum grade is 100
  let color = cleanColor(
    getComputedStyle(document.documentElement).getPropertyValue('--bs-beginner-grade')
  ) // Default color for beginner

  if (percentage >= 0.75) {
    color = cleanColor(
      getComputedStyle(document.documentElement).getPropertyValue('--bs-proficient-grade')
    ) // Proficient (>= 100%)
  } else if (percentage >= 0.5) {
    color = cleanColor(
      getComputedStyle(document.documentElement).getPropertyValue('--bs-experienced-grade')
    ) // Experienced (>= 75%)
  } else if (percentage >= 0.25) {
    color = cleanColor(
      getComputedStyle(document.documentElement).getPropertyValue('--bs-intermediate-grade')
    ) // Intermediate (>= 50%)
  } else if (percentage <= 0.25) {
    color = cleanColor(
      getComputedStyle(document.documentElement).getPropertyValue('--bs-beginner-grade')
    ) // Beginner (>= 25%)
  }

  if (percentage <= threshold / 100) {
    percentage = threshold / 100 // Set percent to a specific value when it is zero
  }

  // Init 2
  drawCircle(
    cleanColor(getComputedStyle(document.documentElement).getPropertyValue('--bs-medium')),
    options.lineWidth,
    number1 / number1
  )
  drawCircle(color, options.lineWidth, percentage)
}
