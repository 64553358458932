/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import {KTIcon} from '../../../../helpers'
import {EntityDetails} from '../../../../../dataTypes'
import {useUserContext} from '../../../../../UserContext'
import config from '../../../../../config.json'
import WarningSpinner from '../../../../../app/modules/profile/components/WarningSpinner'
import {DashCard} from './DashCard'

type Props = {
  className: string
  color?: string
  svgIcon?: string
  iconColor?: string
  title: string
  titleColor?: string
  titleType?: string
  description: string
  descriptionColor?: string
  refreshBool?:boolean

}

const EntDeptDashCard: React.FC<Props> = ({
  className,
  color,
  svgIcon,
  iconColor,
  title,
  titleColor,
  titleType,
  description,
  descriptionColor,
  refreshBool,
}) => {
  const {user} = useUserContext()
  const [entityData, setEntityData] = useState<EntityDetails>()
  const [entityDepartmentInfo, setEntityDepartmentInfo] = useState<string[][]>()

  async function callEntInfo() {
    if (user?.['user id']) {
      const entDepartment = await fetch(
        config.domainName + ':' + config.port + '/api/entityquery',
        {
          method: 'POST',
          headers: {'Content-Type': 'application/json'},
          body: JSON.stringify({
            function: 'EntityDepartmentInformation',
            entity_id: user['user id'],
          }),
        }
      )
      const entDepartmentCont = await entDepartment.json()
      // console.log(entDepartmentCont)
      setEntityDepartmentInfo(entDepartmentCont['department information'])
    }
    if (user?.['user id']) {
      const entData = await fetch(config.domainName + ':' + config.port + '/api/entityquery', {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({
          function: 'AllEntityData',
          entity_id: user['user id'],
        }),
      })
      const entDataCont = await entData.json()
      //console.log(entDataCont)
      if (entDataCont) {
        const entityData: EntityDetails = {
          'entity grade': entDataCont['entity grade'],
          'entity score': entDataCont['entity score'],
          'number of employees': entDataCont['number of employees'],
          color: entDataCont['color'],
          'entity alias': entDataCont['entity alias'],
          'entity name': entDataCont['entity name'],
        }

        setEntityData(entityData)
      }
    }
  }

  useEffect(() => {
    callEntInfo()
  }, [])

  useEffect(() => {
    if (refreshBool == true) {
      callEntInfo()
    } 
  }, [refreshBool])
  return (
    <div className={`card ${className}`}>
      {entityDepartmentInfo ? (
        <DashCard
          className='h-100'
          svgIcon={svgIcon}
          color={color}
          iconColor={iconColor}
          titleColor={titleColor}
          title={entityDepartmentInfo.length}
          titleType={titleType}
          description={description}
          descriptionColor={descriptionColor}
        />
      ) : (
        <WarningSpinner spinnerWidth='60px' canReload={true} reloadFunc={callEntInfo} />
      )}
    </div>
  )
}

export {EntDeptDashCard}
