import {FC, useEffect} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../_metronic/layout/core'

import {ButtonCard} from '../../_metronic/partials/widgets/_new/cards/ButtonCard'

import {useUserContext} from '../../UserContext'
import {ImportExcelButtonCard} from '../../_metronic/partials/widgets/_new/cards/ImportExcelButtonCard'

interface DashboardProps {
  buttonFunc: any
}

const DashboardPage: FC<DashboardProps> = (props) => (
  <div>
    <div className='row g-2 mb-5 g-xl-2' style={{height: '560px'}}>
      <div className='col-md-9 col-lg-9 col-xl-9 col-xxl-9 h-100'>
        {/* Row 1 - Contains Overall Grade + Overall Assessments + User Assessment Completion*/}
        <div className='row g-2 mb-5 g-xl-2' style={{height: '400px'}}>
          <div className='col-md-4 col-lg-4 col-xl-4 col-xxl-4 h-100'>
            <ImportExcelButtonCard
              className='h-md-3 h-100'
              title='Import excel'
              subtitle='Import table from xlsx file'
            />
          </div>
          <div className='col-md-4 col-lg-4 col-xl-4 col-xxl-4 h-100'>
            <ButtonCard className='h-md-3 h-100' buttonFunc={props.buttonFunc} />
          </div>
          <div className='col-md-4 col-lg-4 col-xl-4 col-xxl-4 h-100'>
            <ButtonCard className='h-md-3 h-100' buttonFunc={props.buttonFunc} />
          </div>
        </div>
        {/* Row 2 - Contains Highest Comp + Lowest Comp */}
        <div className='row g-2 mb-5 g-xl-2' style={{height: '400px'}}>
          <div className='col-md-6 col-lg-6 col-xl-6 col-xxl-6 h-100'>
            <ButtonCard className='h-md-3 h-100' buttonFunc={props.buttonFunc} />
          </div>
          <div className='col-md-6 col-lg-6 col-xl-6 col-xxl-6 h-100'>
            {' '}
            <ButtonCard className='h-md-3 h-100' buttonFunc={props.buttonFunc} />
          </div>
        </div>
      </div>
      <div className='col-md-3 col-lg-3 col-xl-3 col-xxl-3 h-100'>
        <ButtonCard className='h-md-3 h-100' buttonFunc={props.buttonFunc} />
        <div className='row g-2 mb-2 g-xl-2'></div>
      </div>
    </div>
  </div>
)

const AdminDashboard: FC = () => {
  const intl = useIntl()

  const {user} = useUserContext()
  useEffect(() => {
    async function callEntInfo() {
      if (user?.['user id']) {
        // console.log('TESTING')
        // console.log(user)
      }
    }
    callEntInfo()
    // console.log('I AM IN THE ADMIN DASHBOARD')
  }, [])

  const buttonFunc = () => {
    // console.log('THE BUTTON HAS BEEN PRESSED')
  }

  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'ADMIN DASHBOARD'})}</PageTitle>
      <DashboardPage buttonFunc={buttonFunc} />
    </>
  )
}

export {AdminDashboard}