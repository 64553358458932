import {useEffect, useState, FC} from 'react'
import {SkillsTrainingDescription} from '../../../../../_metronic/partials/widgets'
import config from '../../../../../config.json'
import {PotentialSkillGroup, Skill} from '../../../../../dataTypes'
import {useUserContext} from '../../../../../UserContext'
import {SkillsTable} from '../../../../../_metronic/partials/widgets/tables/SkillsTable'
import {EmployeeDetails} from '../../../../../dataTypes'
import OverlayTrigger from 'react-bootstrap/esm/OverlayTrigger'
import {Tooltip} from 'react-bootstrap'
import {CompetenciesTable} from '../../../../../_metronic/partials/widgets/tables/CompetenciesTable'
import {Nav, Tab} from 'react-bootstrap'

type Competency = {
  name: string
  description: string
  score?: number
}
export const CareerPath: FC<{
  isCompact?: boolean
  employeeQuery?: string
  className?: string
}> = ({isCompact = false, employeeQuery, className}) => {
  const [employeeKSA, setEmployeeKSA] = useState<Skill[]>([])
  const [employeeCompetencies, setEmployeeCompetencies] = useState<Competency[]>([])
  const [employeeCompetenciesSkill, setEmployeeCompetenciesSkill] = useState<Skill[]>([])
  const [currentJobRole, setCurrentJobRole] = useState('')
  const [allSkills, setAllSkills] = useState<Skill[]>([])
  const [allPotentialSkills, setAllPotentialSkills] = useState<Skill[]>([])
  const [employeeAptitude, setEmployeeAptitude] = useState<Skill[]>([])
  const [employeeAptitudeArr, setEmployeeAptitudeArr] = useState<Skill[]>([])

  const [secureSkills, setSecureSkills] = useState<Skill[]>([])
  const [employeeSkills, setEmployeeSkills] = useState<PotentialSkillGroup[]>([])
  const [potentialEmpty, setPotentialEmpty] = useState(false)
  const [selectedIndices, setSelectedIndices] = useState<Array<number>>([])

  const [selectedIndex, setSelectedIndex] = useState(-1)
  const [selectedSecureIndex, setSelectedSecureIndex] = useState(-1)
  const {user} = useUserContext()
  // console.log('user', user?.['user id'])
  const [showLoading, setShowLoading] = useState(false) // State to control showing the warning message

  const warningTime = 5000

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowLoading(true)
    }, warningTime)
    return () => clearTimeout(timer)
  }, [])

  let OverallColor = '#ba403e'

  const threshold = 2

  const skillsTooltip = (score: number, title: string, color: string) => (
    <Tooltip id='skillsToolTip' className={` ${color}`} style={{borderRadius: '10px'}}>
      <span className='text-gray-800' style={{fontSize: '12px'}}>
        {title}: {Math.round(score)}%
      </span>
    </Tooltip>
  )
  async function callKSAData(employeeQuery: string | undefined) {
    if (employeeQuery) {
      const empKSA = await fetch(config.domainName + ':' + config.port + '/api/employeequery', {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({
          function: 'EmployeeKSA',
          employee_id: employeeQuery,
        }),
      })

      const empKSACont = await empKSA.json()
      const ksaArray: Skill[] = empKSACont['user ksa'].map((skills: any) => ({
        title: skills['title'],
        score: skills['score'],
        grade: skills['grade'],
        color: skills['color'],
        description: skills['description'],
      }))
      // console.log("ksaarray",ksaArray)
      setEmployeeKSA(ksaArray)
      // setAllSkills(allSkills.concat(ksaArray))
    }
  }
  async function callEmployeeCompetencies(employeeQuery: string | undefined) {
    if (employeeQuery) {
      const empKSA = await fetch(
        config.domainName + ':' + config.port + '/api/employeecompetencies?id=' + employeeQuery,
        {
          method: 'GET',
          headers: {'Content-Type': 'application/json'},
        }
      )
      // console.log(empKSA)
      const empKSACont = await empKSA.json()
      // console.log('employe comp', empKSACont)
      // console.log('employe comp jobrole', empKSACont['job_role'])
      const compArr: Skill[] = empKSACont['job_role']['competencies'].map((comp: any) => ({
        title: comp['competency'],
        description: comp['ksa'],
        score: comp['score'] != null || undefined ? comp['score'] : null,
        grade: comp['grade'] != null || undefined ? comp['grade'] : null,
        color: comp['color'] != null || undefined ? comp['color'] : null,
      }))
      const job = empKSACont['job_role']['job title']
      setCurrentJobRole(job)
      // console.log('compArr i created', compArr)
      setEmployeeCompetenciesSkill(compArr)
      // const compArr: Competency[] = empKSACont['job_role']['competencies'].map((comp: any) => ({
      //   name: comp['competency'],
      //   desc: comp['ksa'],
      //   score: comp['score'] != null || undefined ? comp['score'] : 0,
      // }))
      // console.log('compArr i created', compArr)
      // setEmployeeCompetencies(compArr)
      // setCurrentJobRole()

      // const ksaArray: Skill[] = empKSACont['user ksa'].map((skills: any) => ({
      //   title: skills['title'],
      //   score: skills['score'],
      //   grade: skills['grade'],
      //   color: skills['color'],
      //   description: skills['description'],
      // }))
      // console.log("ksaarray",ksaArray)
      // setEmployeeKSA(ksaArray)
      // setAllSkills(allSkills.concat(ksaArray))

      // if (ColorPercentage >= 0.75) {
      //   OverallColor = '#68B139' // Proficient (>= 100%)
      // } else if (ColorPercentage >= 0.5) {
      //   OverallColor = '#40519C' // Experienced (>= 75%)
      // } else if (ColorPercentage >= 0.25) {
      //   OverallColor = '#EDA500' // Intermediate (>= 50%)
      // } else if (ColorPercentage <= 0.25) {
      //   OverallColor = '#C42B21' // Beginner (>= 25%)
      // }
    }
  }

  async function callAptitudeData(employeeQuery: string | undefined) {
    if (employeeQuery) {
      // console.log("emp query yaar",employeeQuery)
      const empAptitude = await fetch(
        config.domainName + ':' + config.port + '/api/employeequery',
        {
          method: 'POST',
          headers: {'Content-Type': 'application/json'},
          body: JSON.stringify({
            function: 'GetAptitude',
            employee_id: employeeQuery,
          }),
        }
      )

      const empAptitudeCont = await empAptitude.json()
      // console.log('new aptitude', empAptitudeCont)
      const aptitudeArray: Skill[] = empAptitudeCont['aptitude skills'].map((skills: any) => ({
        title: skills['title'],
        score: skills['score'],
        grade: skills['grade'],
        color: skills['color'],
        description: skills['description'],
      }))
      // console.log("ksaarray",ksaArray)
      setEmployeeAptitudeArr(aptitudeArray)
    }
  }

  const updateAllSkill = () => {
    // let mergedSkills2 = [...allPotentialSkills, ...employeeKSA] // Merge
    // let mergedSkills2 = [...allPotentialSkills, ...employeeAptitude] // Merge
    let mergedSkills2 = [...employeeKSA, ...employeeAptitudeArr] // Merge
    // console.log('mergeSkill', mergedSkills2)
    setAllSkills(mergedSkills2)
  }

  useEffect(() => {
    if (employeeQuery) {
      callKSAData(employeeQuery)
      callAptitudeData(employeeQuery)
    } else {
      callKSAData(user?.['user id'])
      callAptitudeData(user?.['user id'])
      callEmployeeCompetencies(user?.['user id'])
    }
  }, [employeeQuery])

  useEffect(() => {
    updateAllSkill()
  }, [employeeAptitudeArr, employeeKSA])

  useEffect(() => {
    const aptitudeArray: Skill[] = [
      {
        title: 'Secure Coding Practices',
        score: 0.96,
        grade: 'proficient',
        color: '#66B32E',
        description:
          '• Secure Developers must have a strong grasp of secure coding principles, including input validation, output encoding, and proper error handling. They should be proficient in programming languages commonly used in web and application development to prevent common vulnerabilities such as SQL injection, Cross-Site Scripting (XSS), and Cross-Site Request Forgery (CSRF).',
      },
      {
        title: 'Vulnerability Assessment',
        score: 0.69,
        grade: 'proficient',
        color: '#40519C',
        description:
          '• Secure Developers should be skilled in identifying vulnerabilities within applications and systems. They need to conduct thorough security assessments and penetration testing to proactively discover weaknesses and potential threats. Understanding how to use tools like OWASP ZAP or Nessus is essential.          ',
      },
      {
        title: 'Security Frameworks and Standards',
        score: 0.42,
        grade: 'proficient',
        color: '#EDA500',
        description:
          '• Familiarity with security frameworks and standards such as OWASP (Open Web Application Security Project) Top Ten, NIST (National Institute of Standards and Technology) guidelines, and CIS (Center for Internet Security) benchmarks is vital. Secure Developers should follow these best practices to ensure the development of secure software..',
      },
      {
        title: 'Communication and Collaboration',
        score: 0.6,
        grade: 'proficient',
        color: '#EDA500',
        description:
          '• Familiarity with security frameworks and standards such as OWASP (Open Web Application Security Project) Top Ten, NIST (National Institute of Standards and Technology) guidelines, and CIS (Center for Internet Security) benchmarks is vital. Secure Developers should follow these best practices to ensure the development of secure software..',
      },
      {
        title: 'Security Frameworks and Standards',
        score: 0.42,
        grade: 'proficient',
        color: '#EDA500',
        description:
          '• Familiarity with security frameworks and standards such as OWASP (Open Web Application Security Project) Top Ten, NIST (National Institute of Standards and Technology) guidelines, and CIS (Center for Internet Security) benchmarks is vital. Secure Developers should follow these best practices to ensure the development of secure software..',
      },
      {
        title: 'Authentication and Authorization',
        score: 0.76,
        grade: 'proficient',
        color: '#EDA500',
        description:
          '• Familiarity with security frameworks and standards such as OWASP (Open Web Application Security Project) Top Ten, NIST (National Institute of Standards and Technology) guidelines, and CIS (Center for Internet Security) benchmarks is vital. Secure Developers should follow these best practices to ensure the development of secure software..',
      },
    ]
    setEmployeeAptitude(aptitudeArray)
  }, [])
  useEffect(() => {
    const aptitudeArray: Skill[] = [
      {
        title: 'Secure Coding Practices',
        score: 0.96,
        grade: 'proficient',
        color: '#66B32E',
        description:
          '• Secure Developers must have a strong grasp of secure coding principles, including input validation, output encoding, and proper error handling. They should be proficient in programming languages commonly used in web and application development to prevent common vulnerabilities such as SQL injection, Cross-Site Scripting (XSS), and Cross-Site Request Forgery (CSRF).',
      },
      {
        title: 'Vulnerability Assessment',
        score: 0.69,
        grade: 'proficient',
        color: '#40519C',
        description:
          '• Secure Developers should be skilled in identifying vulnerabilities within applications and systems. They need to conduct thorough security assessments and penetration testing to proactively discover weaknesses and potential threats. Understanding how to use tools like OWASP ZAP or Nessus is essential.          ',
      },
      {
        title: 'Security Frameworks and Standards',
        score: 0.42,
        grade: 'proficient',
        color: '#EDA500',
        description:
          '• Familiarity with security frameworks and standards such as OWASP (Open Web Application Security Project) Top Ten, NIST (National Institute of Standards and Technology) guidelines, and CIS (Center for Internet Security) benchmarks is vital. Secure Developers should follow these best practices to ensure the development of secure software..',
      },
      {
        title: 'Authentication and Authorization',
        score: 0.6,
        grade: 'proficient',
        color: '#EDA500',
        description:
          '• Familiarity with security frameworks and standards such as OWASP (Open Web Application Security Project) Top Ten, NIST (National Institute of Standards and Technology) guidelines, and CIS (Center for Internet Security) benchmarks is vital. Secure Developers should follow these best practices to ensure the development of secure software..',
      },
      {
        title: 'Security Frameworks and Standards',
        score: 0.42,
        grade: 'proficient',
        color: '#EDA500',
        description:
          '• Familiarity with security frameworks and standards such as OWASP (Open Web Application Security Project) Top Ten, NIST (National Institute of Standards and Technology) guidelines, and CIS (Center for Internet Security) benchmarks is vital. Secure Developers should follow these best practices to ensure the development of secure software..',
      },
      {
        title: 'Incident Response Planning',
        score: 0.76,
        grade: 'proficient',
        color: '#EDA500',
        description:
          '• Familiarity with security frameworks and standards such as OWASP (Open Web Application Security Project) Top Ten, NIST (National Institute of Standards and Technology) guidelines, and CIS (Center for Internet Security) benchmarks is vital. Secure Developers should follow these best practices to ensure the development of secure software..',
      },
      {
        title: 'Secure Coding Documentation',
        score: 0.0,
        grade: 'proficient',
        color: '#EDA500',
        description:
          '• Familiarity with security frameworks and standards such as OWASP (Open Web Application Security Project) Top Ten, NIST (National Institute of Standards and Technology) guidelines, and CIS (Center for Internet Security) benchmarks is vital. Secure Developers should follow these best practices to ensure the development of secure software..',
      },
      {
        title: 'Continuous Learning and Adaptability',
        score: 0.42,
        grade: 'proficient',
        color: '#EDA500',
        description:
          '• Familiarity with security frameworks and standards such as OWASP (Open Web Application Security Project) Top Ten, NIST (National Institute of Standards and Technology) guidelines, and CIS (Center for Internet Security) benchmarks is vital. Secure Developers should follow these best practices to ensure the development of secure software..',
      },
      {
        title: 'Communication and Collaboration',
        score: 0.0,
        grade: 'proficient',
        color: '#EDA500',
        description:
          '• Familiarity with security frameworks and standards such as OWASP (Open Web Application Security Project) Top Ten, NIST (National Institute of Standards and Technology) guidelines, and CIS (Center for Internet Security) benchmarks is vital. Secure Developers should follow these best practices to ensure the development of secure software..',
      },
    ]
    setSecureSkills(aptitudeArray)
  }, [])

  return (
    <div
      // className={className}
      className='mb-5 mb-xl-8 p-5 w-100'
    >
      <Tab.Container defaultActiveKey='Career Path'>
        <div className='d-flex justify-content-center'>
          <Nav variant='pills' className='flex-row'>
            {/* <Nav.Item key={'careerPath'}>
              <Nav.Link eventKey='Career Path'>Career Path</Nav.Link>
            </Nav.Item> */}
            {/* <Nav.Item key={'job1'}>
              <Nav.Link eventKey='Job 1'>Job 1</Nav.Link>
            </Nav.Item>
            <Nav.Item key={'job2'}>
              <Nav.Link eventKey='Job 2'>Job 2</Nav.Link>
            </Nav.Item> */}
          </Nav>
        </div>
        <div
          className='d-flex overflow-scroll h-100 w-100 pt-10'
          style={{minHeight: '500px', width: '100%'}}
        >
          <Tab.Content style={{width: '100%'}}>
            <Tab.Pane key={'careerPath'} eventKey='Career Path'>
              {employeeCompetenciesSkill.length !== 0 ? (
                <>
                  <div className='card card-xxl-stretch mb-5 mb-xl-8 p-5'>
                    <div className='card card-xxl-stretch mb-5 mb-xl-8 p-5 h-100 d-flex flex-row'>
                      <div className={isCompact ? 'col-12 mt-0 h-100' : 'col-8 mb-5 h-100'}>
                        <div className=' flex-row border-0 py-5 pe-5'>
                          <h2 className=' fw-bolder ml-5 align-items-start flex-column fs-1'>
                            Career Path for your job role: {currentJobRole}{' '}
                          </h2>
                          {/* <div className='card-toolbar'></div> */}
                        </div>

                        <div className={`card ${className}`} style={{height: '100% '}}>
                          <CompetenciesTable
                            className='mb-5 mb-xxl-8 h-100'
                            skills={employeeCompetenciesSkill}
                            isCompact={isCompact}
                            title='Career Path'
                            setSelectedIndex={setSelectedSecureIndex}
                            chartIndex='TS-Career-Score-Chart'
                            // indexDash={7}

                            // empFunc={callKSAData}
                            reloadFuncProps={employeeQuery ? employeeQuery : user?.['user id']}
                          />
                        </div>
                      </div>
                      {!isCompact && (
                        <div className='col-4 mt-20 mb-5 ms-2'>
                          <div style={{position: 'sticky', top: '100px'}}>
                            <SkillsTrainingDescription
                              className='mb-5 mb-xxl-1'
                              skill={employeeCompetenciesSkill[selectedSecureIndex]}
                              selectedIndex={selectedSecureIndex}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                    {/* <div className='card card-xxl-stretch mb-5 mb-xl-8 p-5 h-100 d-flex flex-row'>
                      <div className={isCompact ? 'col-12 mt-0 h-100' : 'col-8 mb-5 h-100'}>
                        <div className=' flex-row border-0 py-5 pe-5'>
                          <h2 className=' fw-bolder ml-5 align-items-start flex-column fs-1'>
                            Competencies remaining for your job roles
                          </h2>
                        </div>
                        
                        <div className={`card ${className}`} style={{height: '100% '}}>
                          <CompetenciesTable
                            className='mb-5 mb-xxl-8 h-100'
                            skills={secureSkills}
                            isCompact={isCompact}
                            title='Career Path'
                            setSelectedIndex={setSelectedSecureIndex}
                            chartIndex='TS-Career-Score-Chart'
                            // empFunc={callKSAData}
                            noTick={false}
                            noScore={false}
                            reloadFuncProps={employeeQuery ? employeeQuery : user?.['user id']}
                          />
                        </div>
                      </div>
                      {!isCompact && (
                        <div className='col-4 mt-20 mb-5 ms-2'>
                          <div style={{position: 'sticky', top: '100px'}}>
                            <SkillsTrainingDescription
                              className='mb-5 mb-xxl-1'
                              skill={secureSkills[selectedSecureIndex]}
                              selectedIndex={selectedSecureIndex}
                            />
                          </div>
                        </div>
                      )}
                    </div> */}
                  </div>
                </>
              ) : showLoading ? (
                <div className='d-flex flex-row h-100'>
                  <div style={{height: '100%', width: '100%'}}>
                    <div className={`card  mb-4 mb-xxl-8 h-100`}>
                      <div className='card-header border-0 pt-5 '>
                        <h2 className='card-title align-items-start flex-column'>
                          <span className='card-label fw-bold fs-3 mb-1'>Career Path</span>
                        </h2>
                      </div>

                      <div className='card-body py-3'>
                        <div className='table-responsive'>
                          <div className='d-flex justify-content-center m-5 fw-bold fs-4 flex-column-fluid'>
                            There are no Career Path available. Please go to the Assessments tab to
                            see if you have any pending assessments!
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className='d-flex flex-row h-100'>
                  <div style={{height: '100%', width: '100%'}}>
                    <div className={`card mb-4 mb-xxl-8 h-100`}>
                      <div className='card-header border-0 pt-5 '>
                        <h3 className='card-title align-items-start flex-column'>
                          <span className='card-label fw-bold fs-3 mb-1'>Career Path</span>
                        </h3>
                      </div>
                      <div className='card-body h-100 pt-2 pb-4 d-flex justify-content-center flex-column-fluid '>
                        <span
                          className='spinner-border spinner-border-lg spinner-info'
                          style={{
                            width: '80px',
                            height: '80px',
                            border: ' var(--bs-spinner-border-width) solid #0550F0',
                            borderRightColor: 'var(--bs-light)',
                            borderWidth: '10px',
                          }}
                        ></span>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </Tab.Pane>
            {/* <Tab.Pane key={'job1'} eventKey='Job 1' className=''>
              {allSkills.length !== 0 ? (
                <>
                  <div className='card card-xxl-stretch mb-5 mb-xl-8 p-5 '>
                    <div className='card card-xxl-stretch mb-5 mb-xl-8 p-5 h-100  d-flex flex-row'>
                      <div className={isCompact ? 'col-12 mt-0 h-100 ' : 'col-8 mb-5 h-100 '}>
                        <div className=' flex-row border-0 py-5 pe-5'>
                          <h2 className=' fw-bolder ml-5 align-items-start flex-column fs-1'>
                            Job Path for: {empdata?.['job title']}{' '}
                          </h2>
                        </div>
                        
                        <div className={`card ${className}`} style={{height: '100%'}}>
                          <CompetenciesTable
                            className='mb-5 mb-xxl-8 h-100'
                            skills={allSkills}
                            isCompact={isCompact}
                            title='Job Path'
                            setSelectedIndex={setSelectedIndex}
                            chartIndex='TS-Career-Score-Chart'
                            noTick={false}
                            noScore={false}
                            
                            reloadFuncProps={employeeQuery ? employeeQuery : user?.['user id']}
                          />
                        </div>
                      </div>
                      
                    </div>
                  </div>
                </>
              ) : showLoading ? (
                <div className='d-flex flex-row h-100 '>
                  <div style={{height: '100%', width: '100%'}}>
                    <div className={`card  mb-4 mb-xxl-8 h-100`}>
                      <div className='card-header border-0 pt-5 '>
                        <h2 className='card-title align-items-start flex-column'>
                          <span className='card-label fw-bold fs-3 mb-1'>Career Path</span>
                        </h2>
                      </div>

                      <div className='card-body py-3'>
                        <div className='table-responsive'>
                          <div className='d-flex justify-content-center m-5 fw-bold fs-4 flex-column-fluid'>
                            There are no Career Path available. Please go to the Assessments tab to
                            see if you have any pending assessments!
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className='d-flex flex-row h-100'>
                  <div style={{height: '100%', width: '100%'}}>
                    <div className={`card mb-4 mb-xxl-8 h-100`}>
                      <div className='card-header border-0 pt-5 '>
                        <h3 className='card-title align-items-start flex-column'>
                          <span className='card-label fw-bold fs-3 mb-1'>Career Path</span>
                        </h3>
                      </div>
                      <div className='card-body h-100 pt-2 pb-4 d-flex justify-content-center flex-column-fluid '>
                        <span
                          className='spinner-border spinner-border-lg spinner-info'
                          style={{
                            width: '80px',
                            height: '80px',
                            border: ' var(--bs-spinner-border-width) solid #0550F0',
                            borderRightColor: 'var(--bs-light)',
                            borderWidth: '10px',
                          }}
                        ></span>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </Tab.Pane> */}
            {/* <Tab.Pane key={'job2'} eventKey='Job 2'></Tab.Pane> */}
          </Tab.Content>
        </div>
      </Tab.Container>
    </div>
  )
}
