import {FC, useEffect, useRef, useState} from 'react'
import {useThemeMode} from '../../../layout/theme-mode/ThemeModeProvider'
import {EntityDetails} from '../../../../../dataTypes'
import {useUserContext} from '../../../../../UserContext'
import config from '../../../../../config.json'
import WarningSpinner from '../../../../../app/modules/profile/components/WarningSpinner'
import {Link} from 'react-router-dom'
import {getCSSVariableValue} from '../../../../assets/ts/_utils'
import {useCallInterval} from '../../../../../CallTimeIntervalContext'

type Props = {
  className: string
  title: string
  chartSize?: number
  chartLine?: number
  chartRotate?: number
  chartid?: string
}

const threshold = 0.02

const OverallEntGrade: FC<Props> = ({
  className,
  title,
  chartSize = 100,
  chartLine = 20,
  chartRotate = 0,
  chartid,
}) => {
  const [entityData, setEntityData] = useState<EntityDetails>()

  const chartRef = useRef<HTMLDivElement>(null)
  const {mode} = useThemeMode()
  const {user} = useUserContext()
  const {callInterval} = useCallInterval()

  async function callEntInfo() {
    if (user?.['user id']) {
      const entData = await fetch(config.domainName + ':' + config.port + '/api/entityquery', {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({
          function: 'AllEntityData',
          entity_id: user['user id'],
        }),
      })
      const entDataCont = await entData.json()

      if (entDataCont) {
        const entityData: EntityDetails = {
          'entity grade': entDataCont['entity grade'],
          'entity score': entDataCont['entity score'],
          'number of employees': entDataCont['number of employees'],
          color: entDataCont['color'],
          'entity alias': entDataCont['entity alias'],
          'entity name': entDataCont['entity name'],
        }

        setEntityData(entityData)
      }
    }
  }

  useEffect(() => {
    callEntInfo()
  }, [])

  useEffect(() => {
    const interval = setInterval(() => {
      callEntInfo()
    }, callInterval)

    return () => clearInterval(interval)
  }, [])

  useEffect(() => {
    refreshChart()
  }, [mode, entityData])

  const refreshChart = () => {
    if (!chartRef.current || !entityData) {
      return
    }

    setTimeout(() => {
      initChart(chartSize, chartLine, chartRotate, entityData['entity score'])
    }, 20)
  }

  return (
    <div
      className={`card card-flush bgi-no-repeat bgi-size-contain bgi-position-x-end ${className}`}
    >
      <div className='card-header border-0 pt-5'>
        <div className='card-title d-flex flex-column'>
          <h3 className='card-title align-items-start flex-column'>
            <Link to={'/candidate-overview'}>
              <span className='card-label fw-bold fs-3 mb-1'>{title}</span>
            </Link>
          </h3>
        </div>
      </div>
      {entityData ? (
        <div className='card-body pt-2 pb-4 d-flex flex-wrap align-items-center'>
          <div className='d-flex flex-center me-5 pt-2'>
            <div
              id={'ScoreChart'}
              ref={chartRef}
              style={{minWidth: `${chartSize}px`, minHeight: `${chartSize}px`}}
              data-kt-size={chartSize}
              data-kt-line={chartLine}
            ></div>
          </div>

          <div className='d-flex flex-column content-justify-center flex-row-fluid'>
            <div className='d-flex fw-semibold align-items-center my-3'>
              <div className='text-dark-500 flex-grow-1 me-4 fs-1'>Grade</div>
              <div className='fw-bolder text-dark-700 text-xxl-end fs-1'>
                {Math.round(entityData?.['entity score'] * 100)}%
              </div>
            </div>
          </div>
        </div>
      ) : (
        <WarningSpinner spinnerWidth='60px' canReload={true} reloadFunc={callEntInfo} />
      )}
    </div>
  )
}

const initChart = (
  chartSize: number = 70,
  chartLine: number = 11,
  chartRotate: number = 145,
  grade: number
) => {
  const el = document.getElementById('ScoreChart')
  if (!el) {
    return
  }
  el.innerHTML = ''

  const options = {
    size: chartSize,
    lineWidth: chartLine,
    rotate: chartRotate,
    //percent:  el.getAttribute('data-kt-percent') ,
  }

  const canvas = document.createElement('canvas')
  const span = document.createElement('span')

  const ctx = canvas.getContext('2d')
  canvas.width = canvas.height = options.size

  el.appendChild(span)
  el.appendChild(canvas)

  ctx?.translate(options.size / 2, options.size / 2) // change center
  ctx?.rotate((-1 / 2 + options.rotate / 180) * Math.PI) // rotate -90 deg

  const radius = (options.size - options.lineWidth) / 2

  const drawCircle = (color: string, lineWidth: number, percent: number) => {
    percent = Math.min(Math.max(0, percent || 1), 1)
    if (!ctx) {
      return
    }
    ctx.beginPath()
    ctx.arc(0, 0, radius, 0, Math.PI * 2 * percent, false)
    ctx.strokeStyle = color
    ctx.lineCap = 'round' // butt, round or square
    ctx.lineWidth = lineWidth
    ctx.stroke()
  }

  let percentage = grade // Assuming the maximum grade is 100
  let color = '#EDA500' // Default color for beginner

  if (percentage >= 0.75) {
    color = '#68B139' // Proficient (>= 100%)
  } else if (percentage >= 0.5) {
    color = '#40519C' // Experienced (>= 75%)
  } else if (percentage >= 0.25) {
    color = '#EDA500' // Intemediate (>= 50%)
  } else if (percentage <= 0.25) {
    color = '#C42B21' // Beginner (>= 25%)
  }
  if (percentage <= threshold) {
    percentage = threshold // Set percent to a specific value when it is zero
  }
  //#E4E6EF
  drawCircle(getCSSVariableValue('--bs-gray-300'), options.lineWidth, 100 / 100)

  drawCircle(color, options.lineWidth, percentage)
}

export {OverallEntGrade}
