/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useRef, useState} from 'react'
import ApexCharts, {ApexOptions} from 'apexcharts'
import {KTIcon} from '../../../helpers'
import {getCSS, getCSSVariableValue} from '../../../assets/ts/_utils'
import {useThemeMode} from '../../layout/theme-mode/ThemeModeProvider'
import config from '../../../../config.json'
import {useUserContext} from '../../../../UserContext'

type Props = {
  className?: string
  chartData: []
  yaxisname?: string
  id?: string
}

const BIEPchart: React.FC<Props> = ({
  className,
  chartData,
  yaxisname = 'Number of Candidates',
  id = 'biep_bar_chart',
}) => {
  const chartRef = useRef<HTMLDivElement | null>(null)
  const {mode} = useThemeMode()
  const {user} = useUserContext()

  useEffect(() => {
    const chart = refreshChart()

    return () => {
      if (chart) {
        chart.destroy()
      }
    }
  }, [chartRef, mode, chartData])

  const refreshChart = () => {
    if (!chartRef.current) {
      return
    }

    const height = parseInt(getCSS(chartRef.current, 'height'))

    const chart = new ApexCharts(chartRef.current, getChartOptions(height, chartData, yaxisname))
    if (chart) {
      chart.render()
    }

    return chart
  }

  return <div ref={chartRef} id={id} style={{height: '100%'}} />
}

export {BIEPchart}

function getChartOptions(height: number, chartData: [], yaxisName: string): ApexOptions {
  const labelColor = getCSSVariableValue('--bs-dark')
  const borderColor = getCSSVariableValue('--bs-gray-200')
  const baseColor = getCSSVariableValue('--bs-primary')
  const beginner = getCSSVariableValue('--bs-beginner-grade')
  const intermediate = getCSSVariableValue('--bs-intermediate-grade')
  const experience = getCSSVariableValue('--bs-experienced-grade')
  const proficient = getCSSVariableValue('--bs-proficient-grade')
  const secondaryColor = getCSSVariableValue('--bs-gray-300')
  const threshold = 0.5
  //  let chartDataValues:number[]

  let bCount = 0
  let iCount = 0
  let eCount = 0
  let pCount = 0
  let scores = chartData.map((i) => Math.round(i))
  //console.log('Scores', scores)
  scores.forEach((score) => {
    if (score >= 0 && score <= 25) {
      bCount++
    } else if (score >= 26 && score <= 50) {
      iCount++
    } else if (score >= 51 && score <= 75) {
      eCount++
    } else if (score >= 76 && score <= 100) {
      pCount++
    }
  })

  let biepArr = [bCount, iCount, eCount, pCount]

  return {
    series: [
      {
        name: yaxisName,
        data: [
          bCount < threshold ? threshold : bCount,
          iCount < threshold ? threshold : iCount,
          eCount < threshold ? threshold : eCount,
          pCount < threshold ? threshold : pCount,
        ],
      },
    ],

    chart: {
      fontFamily: 'inherit',
      type: 'bar',
      height: height,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        distributed: true,
        horizontal: false,
        columnWidth: '30%',
        borderRadius: 5,
      },
    },
    colors: [beginner, intermediate, experience, proficient],
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ['transparent'],
    },
    xaxis: {
      categories: ['Beginner', 'Intermediate', 'Experienced', 'Proficient'],
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
    },
    yaxis: {
      title: {
        text: yaxisName,
        style:{
          color: labelColor,
        }
      },
      decimalsInFloat: 0,
      labels: {
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
    },
    fill: {
      opacity: 1,
    },
    states: {
      normal: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      hover: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: 'none',
          value: 0,
        },
      },
    },
    tooltip: {
      style: {
        fontSize: '12px',
      },
      y: {
        // formatter: function (val, {seriesIndex, dataPointIndex}) {
        //   return '' + val + ''
        // },
        formatter: function (val, {seriesIndex, dataPointIndex}) {
          if (val == threshold) {
            return '' + 0 + ''
          } else {
            return '' + val + ''
          }
        },
      },
    },
    // colors: [baseColor, secondaryColor],
    grid: {
      borderColor: borderColor,
      strokeDashArray: 4,
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
  }
}
