import {useLocation} from 'react-router-dom'
import {PageLink} from '../../../../_metronic/layout/core'
import {UserListDropDown} from './UserListDropDown_Ent'
import {FC, useState, useEffect} from 'react'
import {UserNumberListDropDown} from './UserNumberListDropDown_Ent'
import ProfilePageTraining from './ProfilePageTraining_Ent'

const usersBreadcrumbs: Array<PageLink> = [
  {
    title: 'User Management',
    path: '/user-management',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: false,
    isActive: false,
  },
]

const EmployeeTraining: FC = () => {
  const location = useLocation()
  const [selectedEmployee, setSelectedEmployee] = useState<string>('')
  const [employeeQueryFromSearch, setEmployeeQueryFromSearch] = useState<string | null>('')
  const [dropdownUsed, setDropdownUsed] = useState(0)

  const handleSelectEmployee = (value: string) => {
    setSelectedEmployee(value)
    setDropdownUsed(dropdownUsed + 1)
  }

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search)
    setEmployeeQueryFromSearch(searchParams.get('employeeQuery'))

    if (employeeQueryFromSearch !== null) {
      if (dropdownUsed < 2) {
        setSelectedEmployee(employeeQueryFromSearch)
        //setDropdownUsed(true)
        window.history.replaceState({}, document.title, '/' + 'candidate-training')
      }
    } else {
      setTimeout(() => {
        if (employeeQueryFromSearch === null) {
          if (dropdownUsed === 0) {
            //setSelectedEmployee('TZSGAADDA00001')
          }
        }
      }, 50)
    }
  }, [employeeQueryFromSearch, selectedEmployee])

  return (
    <div className='row g-5 g-xxl-8 h-100'>
      <div className='d-flex flex-row'>
        <div className='col-xl-6'>
          <UserListDropDown
            selectedEmployee={selectedEmployee}
            setSelectedEmployee={handleSelectEmployee}
          />
        </div>
        <div className='col-xl-6'>
          <UserNumberListDropDown
            selectedEmployee={selectedEmployee}
            setSelectedEmployee={handleSelectEmployee}
          />
        </div>
      </div>

      <div className='row g-5 g-xxl-8 h-100'>
        {selectedEmployee !== '' && (
          <>
            <ProfilePageTraining employeeQuery={selectedEmployee} />
          </>
        )}
      </div>
    </div>
  )
}
export {EmployeeTraining}
