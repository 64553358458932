import React, {useState, useEffect} from 'react'
import {KTIcon} from '../../../helpers'
import Flags from 'country-flag-icons/react/3x2'

import {GradeRatios} from '../../../../dataTypes'
import 'bootstrap/dist/js/bootstrap.bundle'
import {OverlayTrigger, Tooltip} from 'react-bootstrap'

import config from '../../../../config.json'
import {useUserContext} from '../../../../UserContext'
import {Link, useLocation} from 'react-router-dom'

import './multiSelect.css'

import moment from 'moment'
import Select, {ActionMeta, OnChangeValue, StylesConfig} from 'react-select'

interface ProfilePageLocationState {
  employeeQuery?: string
}

interface ProfilePageLocation extends Location {
  state?: ProfilePageLocationState
}

type Employee = {
  name: string
  position: string
  division: string
  department: string
  section: string
  score: number
  technicalScore: number
  appraisalScore: number
  lineManagerScore: number
  potentialSkillsScore: number
  dob: string
  gender: string
  nationality: string
  talentPool: string
  email: string
  sector: string
  yearsOfExp: string

  'employee id': string
  'employee number': string
}

type Props = {
  className: string
}

const UserListCard: React.FC<Props> = ({className}) => {
  //#region VARIABLES - USE STATES
  const [originalData, setOriginalData] = useState<Employee[]>([])
  const [filteredData, setFilteredData] = useState<Employee[]>([])
  const [gradeRatios, setGradeRatios] = useState<GradeRatios>()
  const [filteredGrade, setFilteredGrade] = useState<Employee[]>([])
  const [searchQuery, setSearchQuery] = useState('')
  const [filterType, setFilterType] = useState('')
  const location = useLocation()
  const [filterOptions, setFilterOptions] = useState<{[key: string]: string}>({})

  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('desc')
  const [sortField, setSortField] = useState<string>('score')
  const [isSearching, setIsSearching] = useState(false)
  const [role, setRole] = useState<string | undefined>()
  const [gradeBracket, setGradeBracket] = useState<number | undefined>()
  const [departmentName, setdepartmentName] = useState<string>('')
  const [toggleExtendScore, setToggleExtendScore] = useState(true)

  const [toggleScoreButtonText, setToggleScoreButtonText] = useState<string>('Extended Score View')
  const [toggleColumnArr, setToggleColumnArr] = useState<Boolean[]>([])

  const [departmentQueryFromSearch, setdepartmentQueryFromSearch] = useState<string>('')
  const [talentPoolFromSearch, setTalentPoolFromSearch] = useState<boolean>(false)
  const [dropdownUsed, setDropdownUsed] = useState(0)

  //#endregion

  const {user} = useUserContext()

  const tableColumns = [
    {
      id: 0,
      value: 'candidate id',
      label: 'Candidate ID',
      isFixed: true,
    },
    {
      id: 1,
      value: 'talentPool',
      label: 'Subject Matter Expert',
    },
    {
      id: 2,
      value: 'candidate',
      label: 'Candidate',
      isFixed: true,
    },
    {
      id: 3,
      value: 'gender',
      label: 'Gender',
    },
    {
      id: 4,
      value: 'age',
      label: 'Age',
    },
    {
      id: 5,
      value: 'nationality',
      label: 'Nationality',
    },
    {
      id: 6,
      value: 'email',
      label: 'Email',
    },
    {
      id: 7,
      value: 'position',
      label: 'Position',
    },
    {
      id: 8,
      value: 'division',
      label: 'Division',
    },
    {
      id: 9,
      value: 'section',
      label: 'Section',
    },
    {
      id: 10,
      value: 'department',
      label: 'Department',
    },
    {
      id: 11,
      value: 'sector',
      label: 'Sector',
    },
    {
      id: 12,
      value: 'yearsOfExp',
      label: 'Years of Experience',
    },
    {
      id: 13,
      value: 'score',
      label: 'Score',
      isFixed: true,
    },
    {
      id: 14,
      value: 'potentialSkillsScore',
      label: 'Potential',
    },
    {
      id: 15,
      value: 'technicalScore',
      label: 'Technical',
    },
    {
      id: 16,
      value: 'appraisalScore',
      label: 'Appraisal',
    },
    {
      id: 17,
      value: 'lineManagerScore',
      label: 'Line Manager',
    },
  ]

  // let defaultSelectValues = [tableColumns[0], tableColumns[2], tableColumns[13]]
  let defaultSelectValues: {
    id: number
    value: string
    label: string
  }[] = []

  async function callEmpData() {
    if (user?.['user id']) {
      const allEmpDataSet = await fetch(config.domainName + ':' + config.port + '/api/employee', {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({
          entity_id: user['user id'],
        }),
      })

      const allEmpDataCont = await allEmpDataSet.json()
      //console.log(allEmpDataCont)
      const employeeData = allEmpDataCont.employees.map((emp: any) => {
        if (emp['employee score'] !== '-') {
          const empScorePercentage = Math.round(emp['employee score'] * 100)
          return {...emp, 'employee score': empScorePercentage}
        } else {
          return {...emp, 'employee score': 0}
        }
      })

      const gradeRatios: GradeRatios = {
        'appraisal skill ratio': allEmpDataCont.ratios['appraisal skill ratio'] * 100,
        'line manager skill ratio': allEmpDataCont.ratios['line manager skill ratio'] * 100,
        'potential skill ratio': allEmpDataCont.ratios['aptitude skill ratio'] * 100,
        // 'potential skill ratio': allEmpDataCont.ratios['aptitude skill ratio'] * 100,
        'technical skill ratio': allEmpDataCont.ratios['technical skill ratio'] * 100,
      }

      setGradeRatios(gradeRatios)
      //console.log(gradeRatios)
      const dummyData: Employee[] = employeeData.map((employee: any) => {
        return {
          name: employee.name,
          position: employee['job title'],
          dob: employee.dob,
          division: employee['division'],
          section: employee['section'],
          department: employee['department'],
          score: employee['employee score'],
          technicalScore: employee['performance technical score'],
          potentialSkillsScore: employee['aptitude score'],
          // potentialSkillsScore: employee['aptitude score'],
          appraisalScore: employee['performance appraisal score'],
          lineManagerScore: employee['line manager score'],
          gender: employee.gender,
          nationality: employee.nationality,
          talentPool: employee['talent pool'],
          email: employee.email,
          sector: employee.sector,
          yearsOfExp: employee['years of experience'],
          'employee id': employee['employee id'],
          'employee number': employee['employee number'],
        }
      })

      // console.log(dummyData)

      let toggleColumnFill: Boolean[] = new Array(tableColumns.length)
      for (let index = 0; index < toggleColumnFill.length; index++) {
        if (index === 0 || index === 2 || index === 13) {
          toggleColumnFill[index] = true
        } else {
          toggleColumnFill[index] = false
        }
      }

      setToggleColumnArr(toggleColumnFill)

      setOriginalData(dummyData)
      setFilteredData(dummyData)
    }
  }

  //#region USE EFFECTS

  useEffect(() => {
    callEmpData()
  }, [])

  useEffect(() => {
    if (location.search && dropdownUsed < 3) {
      const searchParams = new URLSearchParams(location.search)

      if (searchParams != null) {
        if (searchParams.has('talent')) {
          toggleColumnArr[1] = true

          defaultSelectValues.push(tableColumns[1])
          setTalentPoolFromSearch(true)
          window.history.replaceState({}, document.title, '/' + 'candidate-overview')
        } else if (searchParams.has('department')) {
          setdepartmentQueryFromSearch(decodeURIComponent(searchParams.get('department') as string))
          toggleColumnArr[10] = true
          defaultSelectValues.push(tableColumns[10])
          window.history.replaceState({}, document.title, '/' + 'candidate-overview')
        } else if (searchParams.has('extendScore')) {
          if (toggleColumnArr.length > 0) {
            setDropdownUsed(dropdownUsed + 1)
            toggleScoreFunc()
          }
          window.history.replaceState({}, document.title, '/' + 'candidate-overview')
        }
      }
    }
  }, [departmentQueryFromSearch, toggleColumnArr, defaultSelectValues])

  useEffect(() => {
    setDropdownUsed(dropdownUsed + 1)
    if (
      departmentQueryFromSearch !== '' &&
      filteredData.length !== 0 &&
      filterOptions.department == undefined &&
      dropdownUsed < 3
    ) {
      handleFilterTypeChange('department', departmentQueryFromSearch)
    }
    if (
      talentPoolFromSearch == true &&
      filteredData.length !== 0 &&
      // filterOptions.talentPool == 'true' &&
      dropdownUsed < 3
    ) {
      handleFilterTypeChange('talentPool', 'true')
    }
  }, [filteredData])

  useEffect(() => {
    const filteredByName = originalData.filter((employee) =>
      employee.name.toLowerCase().includes(searchQuery.toLowerCase())
    )

    const filteredByNumber = originalData.filter((employee) =>
      employee['employee number'].toLowerCase().includes(searchQuery.toLowerCase())
    )

    const mergedEmployees = [...filteredByName, ...filteredByNumber]
    const uniqueEmployees = Array.from(new Set(mergedEmployees))

    setFilteredData(uniqueEmployees)
  }, [searchQuery, originalData])

  //#endregion

  //#region HANDLE FUNCTIONS
  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value)
  }

  const handleSearchSubmit = () => {
    setIsSearching(true)
  }

  const handleFilterTypeChange = (filteringType: string, selectedValue: string) => {
    setFilterOptions((prevFilters) => ({
      ...prevFilters,
      [filteringType]: selectedValue,
    }))
    // Trigger filtering based on the selected filter type
    handleFilter(selectedValue, filteringType)
  }

  const handleFilter = (selectedValue: string, filteringType: string) => {
    if (filteringType === 'grade') {
      let filteredEmployees = [...filteredGrade]
      filteredEmployees = filteredEmployees.filter((employee) => {
        const score = employee.score
        if (selectedValue === '25') {
          return score <= 25
        } else if (selectedValue === '50') {
          return score <= 50
        } else if (selectedValue === '75') {
          return score <= 75
        } else if (selectedValue === '100') {
          return score <= 100
        }
      })
      setFilteredData(filteredEmployees)
    } else {
      let filteredEmployees = [...filteredData]

      filteredEmployees = filteredEmployees.filter((employee) => {
        return employee[filteringType as keyof Employee] === selectedValue
      })

      setTimeout(() => {
        setFilteredData(filteredEmployees)
        setFilteredGrade(filteredEmployees)
      }, 10)
    }
  }

  const handleClearFilter = (filteringType: string) => {
    setFilterOptions((prevFilters) => ({
      ...prevFilters,
      [filteringType]: '',
    }))

    let filteredEmployees = [...originalData]

    // Apply filters based on filter options
    Object.keys(filterOptions).forEach((filterType) => {
      const selectedValue = filterOptions[filterType]
      if (selectedValue !== '' && filterType !== filteringType) {
        filteredEmployees = filteredEmployees.filter((employee) => {
          if (filterType === 'grade') {
            const score = employee.score
            if (selectedValue === '25') {
              return score <= 25
            } else if (selectedValue === 'Grade 50') {
              return score <= 50
            } else if (selectedValue === 'Grade 75') {
              return score <= 75
            } else if (selectedValue === 'Grade 100') {
              return score >= 75
            }
          } else {
            return employee[filterType as keyof Employee] === selectedValue
          }
          return false
        })
      }
    })

    setFilteredData(filteredEmployees)
  }

  const handleSort = (field: string) => {
    if (sortField === field) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc')
    } else {
      setSortField(field)
      setSortOrder('desc')
    }
  }

  //#endregion

  //#region OTHER FUNCTIONS

  const toggleScoreFunc = () => {
    setToggleScoreButtonText(toggleExtendScore ? 'Compact Score View' : 'Extended Score View')
    setToggleExtendScore(!toggleExtendScore)
    setToggleColumnArr(
      toggleColumnArr.map((v, i) => {
        // if (i > 13) return !v
        if (i == 14) {
          if (gradeRatios?.['potential skill ratio'] != 0) {
            return !v
          }
          return v
        } else if (i == 15) {
          if (gradeRatios?.['technical skill ratio'] != 0) {
            return !v
          }
          return v
        } else if (i == 16) {
          if (gradeRatios?.['appraisal skill ratio'] != 0) {
            return !v
          }
          return v
        } else if (i == 17) {
          if (gradeRatios?.['line manager skill ratio'] != 0) {
            return !v
          }
          return v
        } else return v
      })
    )
  }

  const clearFilters = () => {
    setFilterOptions({})
    setRole(undefined)
    setGradeBracket(undefined)
    setFilteredData(originalData) // Set the filtered data to the original data set
    setFilteredGrade(originalData)
  }

  const ageFromDateOfBirthday = (dateOfBirth: any): number => {
    const birthDate = moment(dateOfBirth, 'DD/MM/YYYY')

    if (birthDate.isValid()) {
      const calculatedAge = moment().diff(birthDate, 'years')
      return calculatedAge
    } else {
      return 0
    }
  }

  const handleChange = (selectedList: any, selectedOption: any) => {
    // console.log('selected option', selectedOption)
    if (selectedOption['action'] === 'select-option') {
      setToggleColumnArr(
        toggleColumnArr.map((v, i) => {
          if (i === selectedOption['option'].id) return true
          else return v
        })
      )
      // if (selectedOption['option'].id === 1) {
      //   handleFilterTypeChange('talentPool', 'true')
      // }
    } else if (selectedOption['action'] === 'remove-value') {
      setToggleColumnArr(
        toggleColumnArr.map((v, i) => {
          if (
            i === selectedOption['removedValue'].id &&
            selectedOption['removedValue'].isFixed != true
          )
            return false
          else return v
        })
      )
      if (selectedOption['removedValue'].id === 1) {
        handleClearFilter('talentPool')
      }
    }
  }

  const colourStyles: StylesConfig<any, true> = {
    control: (styles, state) => ({
      ...styles,
      backgroundColor: 'dark',
      borderColor: state.isFocused ? 'var(--bs-primary)' : 'var(--bs-gray-300)',
      '&:hover': {
        borderColor: 'var(--bs-primary)',
      },
      boxShadow: state.isFocused ? '0 0 0 1px var(--bs-primary)' : '',
      // boxShadow: state.isDisabled ? 'var(--bs-primary)' : 'var(--bs-secondary)',
    }),
    option: (styles, {isDisabled, isFocused, isSelected}) => ({
      ...styles,
      borderColor: 'var(--bs-primary)',
      backgroundColor: isDisabled
        ? undefined
        : isSelected
        ? 'var(--bs-primary)' // Set the desired color for selected option
        : isFocused
        ? 'var(--bs-primary)' // Set the desired color for focused option
        : 'var(--bs-body-bg)',
      color: isDisabled
        ? '#ccc'
        : isSelected
        ? 'var(--bs-dark)'
        : isFocused
        ? 'white'
        : 'var(--bs-dark)',
      cursor: isDisabled ? 'not-allowed' : 'default',
      ':active': {
        ...styles[':active'],
        backgroundColor: !isDisabled
          ? isSelected
            ? 'var(--bs-primary)'
            : // : 'hsl(var(--bs-primary) / 0.5)'
              'var(--bs-primary-active)'
          : undefined,
      },
    }),
    multiValue: (styles, {data}) => ({
      ...styles,
      backgroundColor: data.isFixed ? 'var(--bs-primary-active)' : 'var(--bs-primary)', // Set the desired color for multi-value background
      borderRadius: '10px',
      paddingInline: '5px',
    }),
    multiValueLabel: (styles) => ({
      ...styles,
      color: 'var(--bs-light)', // Set the desired color for multi-value label
    }),
    multiValueRemove: (styles, {data}) => ({
      ...styles,
      color: 'var(--bs-light)',
      display: data.isFixed ? 'none' : styles.display,
      ':hover': {
        backgroundColor: 'var(--bs-danger)', // Set the desired color on hover for multi-value remove
        color: 'white',
      },
    }),
  }

  //#endregion

  type FlagProps = {
    countryCode: string
  }

  const Flag = ({countryCode}: FlagProps) => {
    const FlagComponent = Flags[countryCode.toUpperCase() as keyof typeof Flags]
    return <FlagComponent style={{height: '20px', border: '1px solid black'}} />
  }

  let regionNames = new Intl.DisplayNames(['en'], {type: 'region'})

  const skillsTooltip = (score: number, title: string, color: string) => (
    <Tooltip id='skillsToolTip' className={` ${color}`} style={{borderRadius: '10px'}}>
      <span className='text-gray-800' style={{fontSize: '12px'}}>
        {title}: {Math.round(score)}%
      </span>
    </Tooltip>
  )

  return (
    <div className={`card ${className}`}>
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Candidates overview</span>
        </h3>

        <div style={{width: '400px'}} className='mt-4'>
          <Select
            defaultValue={defaultSelectValues}
            options={tableColumns.filter((val) => {
              return val.id <= 13
            })}
            isOptionDisabled={(option) => option.isFixed}
            isMulti
            closeMenuOnSelect={false}
            onChange={handleChange}
            isClearable={false}
            styles={colourStyles}
          />
        </div>

        <div className='card-toolbar flex-column mt-4'>
          <button
            className='btn btn-sm btn-primary'
            type='button'
            onClick={toggleScoreFunc}
            style={{minWidth: '250px'}}
          >
            {toggleScoreButtonText}
          </button>
        </div>
        <div className='card-toolbar'>
          <div className='input-group'>
            <input
              type='text'
              className='form-control form-control-sm'
              placeholder='Search...'
              value={searchQuery}
              onChange={handleSearch}
            />
            <button className='btn btn-sm btn-primary'>Search</button>
          </div>
        </div>

        {isSearching && (
          <div className='mt-3'>
            <span className='text-muted'>Searching...</span>
          </div>
        )}
      </div>
      <div className='card-header border-0 pt-5 flex-row  justify-content-center '>
        {/* Filtering by Gender */}

        {toggleColumnArr[3] === true && (
          <div className='card-toolbar flex-column px-4'>
            <div>Filter by Gender</div>
            <div className='d-flex justify-content-start'>
              <div className='input-group input-group-sm ml-3 w-100px'>
                {filterOptions.gender ? (
                  <div
                    style={{
                      display: 'inline-flex',
                      alignItems: 'center',
                      backgroundColor: '#e4e4e4',
                      padding: '5px 10px',
                      borderRadius: '20px',
                      fontSize: '12px',
                      marginRight: '5px',
                    }}
                  >
                    <button className='btn btn-sm btn' onClick={() => handleClearFilter('gender')}>
                      <span style={{marginRight: 15}}>{filterOptions.gender}</span>
                      <KTIcon iconName='cross' className='solid fs-4' />
                    </button>
                  </div>
                ) : (
                  <select
                    className='form-control form-control-sm'
                    value={filterOptions.gender}
                    onChange={(e) => handleFilterTypeChange('gender', e.target.value)}
                  >
                    <option value=''>All</option>
                    {Array.from(new Set(filteredData.map((data: Employee) => data.gender))).map(
                      (gender) => (
                        <option key={gender} value={gender}>
                          {gender}
                        </option>
                      )
                    )}
                  </select>
                )}
              </div>
            </div>
          </div>
        )}
        {/* Filtering by Nationality */}
        {toggleColumnArr[5] === true && (
          <div className='card-toolbar flex-column px-4'>
            <div>Filter by Nationality</div>
            <div className='d-flex justify-content-start'>
              <div className='input-group input-group-sm ml-3 w-100px'>
                {filterOptions.nationality ? (
                  <div
                    style={{
                      display: 'inline-flex',
                      alignItems: 'center',
                      backgroundColor: '#e4e4e4',
                      padding: '5px 10px',
                      borderRadius: '20px',
                      fontSize: '12px',
                      marginRight: '5px',
                    }}
                  >
                    <button
                      className='btn btn-sm btn'
                      onClick={() => handleClearFilter('nationality')}
                    >
                      <span style={{marginRight: 15}}>{filterOptions.nationality}</span>
                      <KTIcon iconName='cross' className='solid fs-4' />
                    </button>
                  </div>
                ) : (
                  <select
                    className='form-control form-control-sm'
                    value={filterOptions.nationality}
                    onChange={(e) => handleFilterTypeChange('nationality', e.target.value)}
                  >
                    <option value=''>All</option>
                    {Array.from(
                      new Set(filteredData.map((data: Employee) => data.nationality))
                    ).map((nationality) => (
                      <option key={nationality} value={nationality}>
                        {regionNames.of(nationality)}
                      </option>
                    ))}
                  </select>
                )}
              </div>
            </div>
          </div>
        )}
        {/* Filtering by Position */}
        {toggleColumnArr[7] === true && (
          <div className='card-toolbar flex-column px-4'>
            <div>Filter by Position</div>
            <div className='d-flex justify-content-start'>
              <div className='input-group input-group-sm ml-3 w-100px'>
                {filterOptions.position ? (
                  <div
                    style={{
                      display: 'inline-flex',
                      alignItems: 'center',
                      backgroundColor: '#e4e4e4',
                      padding: '5px 10px',
                      borderRadius: '20px',
                      fontSize: '12px',
                      marginRight: '5px',
                    }}
                  >
                    <button
                      className='btn btn-sm btn'
                      onClick={() => handleClearFilter('position')}
                    >
                      <span style={{marginRight: 15}}>{filterOptions.position}</span>
                      <KTIcon iconName='cross' className='solid fs-4' />
                    </button>
                  </div>
                ) : (
                  <select
                    className='form-control form-control-sm'
                    value={filterOptions.position}
                    onChange={(e) => handleFilterTypeChange('position', e.target.value)}
                  >
                    <option value=''>All</option>
                    {Array.from(new Set(filteredData.map((data: Employee) => data.position))).map(
                      (position) => (
                        <option key={position} value={position}>
                          {position}
                        </option>
                      )
                    )}
                  </select>
                )}
              </div>
            </div>
          </div>
        )}
        {/* Filtering by Division */}
        {toggleColumnArr[8] === true && (
          <div className='card-toolbar flex-column px-4'>
            <div>Filter by Division</div>
            <div className='d-flex justify-content-start'>
              <div className='input-group input-group-sm ml-3 w-100px'>
                {filterOptions.division ? (
                  <div
                    style={{
                      display: 'inline-flex',
                      alignItems: 'center',
                      backgroundColor: '#e4e4e4',
                      padding: '5px 10px',
                      borderRadius: '20px',
                      fontSize: '12px',
                      marginRight: '5px',
                    }}
                  >
                    <button
                      className='btn btn-sm btn'
                      onClick={() => handleClearFilter('division')}
                    >
                      <span style={{marginRight: 15}}>{filterOptions.division}</span>
                      <KTIcon iconName='cross' className='solid fs-4' />
                    </button>
                  </div>
                ) : (
                  <select
                    className='form-control form-control-sm'
                    value={filterOptions.division}
                    onChange={(e) => handleFilterTypeChange('division', e.target.value)}
                  >
                    <option value=''>All</option>
                    {Array.from(new Set(filteredData.map((data: Employee) => data.division))).map(
                      (division) => (
                        <option key={division} value={division}>
                          {division}
                        </option>
                      )
                    )}
                  </select>
                )}
              </div>
            </div>
          </div>
        )}
        {/* Filtering by Section */}
        {toggleColumnArr[9] === true && (
          <div className='card-toolbar flex-column px-4'>
            <div>Filter by Section</div>
            <div className='d-flex justify-content-start'>
              <div className='input-group input-group-sm ml-3 w-100px'>
                {filterOptions.section ? (
                  <div
                    style={{
                      display: 'inline-flex',
                      alignItems: 'center',
                      backgroundColor: '#e4e4e4',
                      padding: '5px 10px',
                      borderRadius: '20px',
                      fontSize: '12px',
                      marginRight: '5px',
                    }}
                  >
                    <button className='btn btn-sm btn' onClick={() => handleClearFilter('section')}>
                      <span style={{marginRight: 15}}>{filterOptions.section}</span>
                      <KTIcon iconName='cross' className='solid fs-4' />
                    </button>
                  </div>
                ) : (
                  <select
                    className='form-control form-control-sm'
                    value={filterOptions.section}
                    onChange={(e) => handleFilterTypeChange('section', e.target.value)}
                  >
                    <option value=''>All</option>
                    {Array.from(new Set(filteredData.map((data: Employee) => data.section))).map(
                      (section) => (
                        <option key={section} value={section}>
                          {section}
                        </option>
                      )
                    )}
                  </select>
                )}
              </div>
            </div>
          </div>
        )}
        {/* Filtering by Department */}
        {toggleColumnArr[10] === true && (
          <div className='card-toolbar flex-column px-4'>
            <div>Filter by Department</div>
            <div className='d-flex justify-content-start'>
              <div className='input-group input-group-sm ml-3 w-100px'>
                {filterOptions.department ? (
                  <div
                    style={{
                      display: 'inline-flex',
                      alignItems: 'center',
                      backgroundColor: '#e4e4e4',
                      padding: '5px 10px',
                      borderRadius: '20px',
                      fontSize: '12px',
                      marginRight: '5px',
                    }}
                  >
                    <button
                      className='btn btn-sm btn'
                      onClick={() => handleClearFilter('department')}
                    >
                      <span style={{marginRight: 15}}>{filterOptions.department}</span>
                      <KTIcon iconName='cross' className='solid fs-4' />
                    </button>
                  </div>
                ) : (
                  <select
                    className='form-control form-control-sm'
                    value={filterOptions.department}
                    onChange={(e) => handleFilterTypeChange('department', e.target.value)}
                  >
                    <option value=''>All</option>
                    {Array.from(new Set(filteredData.map((data: Employee) => data.department))).map(
                      (department) => (
                        <option key={department} value={department}>
                          {department}
                        </option>
                      )
                    )}
                  </select>
                )}
              </div>
            </div>
          </div>
        )}
        {/* Filtering by Sector */}
        {toggleColumnArr[11] === true && (
          <div className='card-toolbar flex-column px-4'>
            <div>Filter by Sector</div>
            <div className='d-flex justify-content-start'>
              <div className='input-group input-group-sm ml-3 w-100px'>
                {filterOptions.sector ? (
                  <div
                    style={{
                      display: 'inline-flex',
                      alignItems: 'center',
                      backgroundColor: '#e4e4e4',
                      padding: '5px 10px',
                      borderRadius: '20px',
                      fontSize: '12px',
                      marginRight: '5px',
                    }}
                  >
                    <button className='btn btn-sm btn' onClick={() => handleClearFilter('sector')}>
                      <span style={{marginRight: 15}}>{filterOptions.sector}</span>
                      <KTIcon iconName='cross' className='solid fs-4' />
                    </button>
                  </div>
                ) : (
                  <select
                    className='form-control form-control-sm'
                    value={filterOptions.sector}
                    onChange={(e) => handleFilterTypeChange('sector', e.target.value)}
                  >
                    <option value=''>All</option>
                    {Array.from(new Set(filteredData.map((data: Employee) => data.sector))).map(
                      (sector) => (
                        <option key={sector} value={sector}>
                          {sector}
                        </option>
                      )
                    )}
                  </select>
                )}
              </div>
            </div>
          </div>
        )}
        {/* Filtering by Grade */}
        {/* <div className='card-toolbar flex-column'>
          <div>Filter by Grade</div>
          <div className='d-flex justify-content-end'>
            <div className='input-group input-group-sm ml-3'>
              <Form.Range
                className='form-control form-control-sm'
                value={filterOptions.grade}
                onChange={(e) => handleFilterTypeChange('grade', e.target.value)}
                step={25}
                min={0}
                max={100}
              ></Form.Range>
            </div>
          </div>
        </div> */}
      </div>
      <div className='d-flex w-100 justify-content-center'>
        {Object.values(filterOptions).some((option) => option !== '') && (
          <button
            className='btn btn-sm btn-light'
            type='button'
            onClick={clearFilters}
            style={{minWidth: '250px'}}
          >
            Clear All Filters
          </button>
        )}
      </div>
      <div className='card-body py-3'>
        <div className='table-responsive'>
          <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4 table-fixed'>
            <thead>
              <tr className='fw-bold text-muted'>
                {toggleColumnArr.map((bool, index) => {
                  if (bool == true) {
                    return (
                      <th
                        key={'overallTable' + index}
                        className='fixed-column'
                        style={{minWidth: '150px'}}
                        onClick={() => handleSort(tableColumns[index].value)}
                      >
                        {index === 14 ? 'Aptitude' : tableColumns[index].label}
                        {/* {tableColumns[index].label}{' '} */}
                        {tableColumns[index].isFixed ? <i className={`fas fa-lock`} /> : ''}{' '}
                        {sortField === tableColumns[index].value && (
                          <i className={`fas fa-sort-${sortOrder}`} />
                        )}
                      </th>
                    )
                  }
                })}
              </tr>
            </thead>
            <tbody>
              {filteredData
                .sort((a, b) => {
                  if (sortField === 'candidate') {
                    return sortOrder === 'asc'
                      ? a.name.localeCompare(b.name)
                      : b.name.localeCompare(a.name)
                  } else if (sortField === 'yearsOfExp') {
                    return sortOrder === 'asc'
                      ? (a.yearsOfExp as unknown as number) - (b.yearsOfExp as unknown as number)
                      : (b.yearsOfExp as unknown as number) - (a.yearsOfExp as unknown as number)
                  } else if (sortField === 'age') {
                    const [dayA, monthA, yearA] = a.dob.split('/')
                    const [dayB, monthB, yearB] = b.dob.split('/')
                    const dobA = new Date(`${monthA}/${dayA}/${yearA}`).getTime()
                    const dobB = new Date(`${monthB}/${dayB}/${yearB}`).getTime()
                    return sortOrder === 'asc' ? dobA - dobB : dobB - dobA
                  } else if (sortField === 'candidate id') {
                    const employeeNumA = parseInt(a['employee number'].match(/\d+/)?.[0] || '0', 10)
                    const employeeNumB = parseInt(b['employee number'].match(/\d+/)?.[0] || '0', 10)
                    return sortOrder === 'asc'
                      ? employeeNumA - employeeNumB
                      : employeeNumB - employeeNumA
                  } else {
                    const aSortFieldValue = a[sortField as keyof Employee]
                    const bSortFieldValue = b[sortField as keyof Employee]

                    if (
                      typeof aSortFieldValue === 'string' &&
                      typeof bSortFieldValue === 'string'
                    ) {
                      return sortOrder === 'asc'
                        ? aSortFieldValue.localeCompare(bSortFieldValue)
                        : bSortFieldValue.localeCompare(aSortFieldValue)
                    } else if (
                      typeof aSortFieldValue === 'number' &&
                      typeof bSortFieldValue === 'number'
                    ) {
                      return sortOrder === 'asc'
                        ? aSortFieldValue - bSortFieldValue
                        : bSortFieldValue - aSortFieldValue
                    } else return 0
                  }
                })
                .map((employee, index) => (
                  <tr key={index}>
                    {toggleColumnArr.map((bool, index) => {
                      if (bool == true) {
                        if (tableColumns[index].value == 'candidate') {
                          var talentPoolSet = employee.talentPool === 'true'
                          return (
                            <td key={'overallTable data' + index}>
                              <div className='d-flex align-items-center'>
                                <div className='d-flex justify-content-start flex-column'>
                                  {/* <a href='#' className='text-dark fw-bold text-hover-primary fs-6'> */}
                                  <Link
                                    className={
                                      `nav-link text-dark fw-bold text-hover-primary fs-6 me-6 ` +
                                      (location.pathname === '/candidate-profile' && 'active')
                                    }
                                    to={`/candidate-profile?employeeQuery=${employee['employee id']}`}
                                  >
                                    {employee.name}
                                    {talentPoolSet === true && (
                                      <i className=' px-2 fa-regular fa-circle-check text-primary'></i>
                                    )}
                                  </Link>
                                  {/* </a> */}
                                </div>
                              </div>
                            </td>
                          )
                        } else if (tableColumns[index].value == 'talentPool') {
                          return (
                            <td key={'overallTable data' + index}>
                              <span className='text-muted fw-semibold text-muted d-block fs-7'>
                                {employee[tableColumns[index].value as keyof Employee] == 'true'
                                  ? 'Yes'
                                  : '-'}
                              </span>
                            </td>
                          )
                        } else if (tableColumns[index].value == 'age') {
                          return (
                            <td key={'overallTable data' + index}>
                              <span className='text-muted fw-semibold text-muted d-block fs-7'>
                                {ageFromDateOfBirthday(employee.dob)}
                              </span>
                            </td>
                          )
                        } else if (tableColumns[index].value == 'score' && gradeRatios) {
                          return toggleExtendScore === true ? (
                            <td className='text-end' key={'overallTable data' + index}>
                              <div className='d-flex flex-column w-100 me-2'>
                                <div className='d-flex flex-stack mb-2'>
                                  <span className='text-muted me-2 fs-7 fw-semibold'>
                                    Overall Score: {employee.score}%
                                  </span>
                                </div>

                                <div
                                  className='progress bg-light h-6px w-100'
                                  style={{minWidth: '250px'}}
                                >
                                  {gradeRatios['potential skill ratio'] != 0 && (
                                    <OverlayTrigger
                                      placement='top'
                                      overlay={skillsTooltip(
                                        employee.potentialSkillsScore *
                                          gradeRatios['potential skill ratio'],
                                        'Aptitude Score',
                                        'bg-potential'
                                      )}
                                    >
                                      <div
                                        className='progress-bar bg-potential'
                                        role='progressbar'
                                        style={{
                                          width: `${
                                            employee.potentialSkillsScore *
                                              gradeRatios['potential skill ratio'] <=
                                            1
                                              ? 1
                                              : employee.potentialSkillsScore *
                                                gradeRatios['potential skill ratio']
                                          }%`,
                                        }}
                                      ></div>
                                    </OverlayTrigger>
                                  )}
                                  {gradeRatios['technical skill ratio'] != 0 && (
                                    <OverlayTrigger
                                      placement='top'
                                      overlay={skillsTooltip(
                                        employee.technicalScore *
                                          gradeRatios['technical skill ratio'],
                                        'Techincal Score',
                                        'bg-technical'
                                      )}
                                    >
                                      <div
                                        className='progress-bar bg-technical'
                                        role='progressbar'
                                        style={{
                                          width: `${
                                            employee.technicalScore *
                                              gradeRatios['technical skill ratio'] <=
                                            1
                                              ? 1
                                              : employee.technicalScore *
                                                gradeRatios['technical skill ratio']
                                          }%`,
                                        }}
                                      ></div>
                                    </OverlayTrigger>
                                  )}
                                  {gradeRatios['appraisal skill ratio'] != 0 && (
                                    <OverlayTrigger
                                      placement='top'
                                      overlay={skillsTooltip(
                                        employee.appraisalScore *
                                          gradeRatios['appraisal skill ratio'],
                                        'Appraisal Score',
                                        'bg-appraisal'
                                      )}
                                    >
                                      <div
                                        className='progress-bar bg-appraisal'
                                        role='progressbar'
                                        style={{
                                          width: `${
                                            employee.appraisalScore *
                                              gradeRatios['appraisal skill ratio'] <=
                                            1
                                              ? 1
                                              : employee.appraisalScore *
                                                gradeRatios['appraisal skill ratio']
                                          }%`,
                                        }}
                                      ></div>
                                    </OverlayTrigger>
                                  )}
                                  {gradeRatios['line manager skill ratio'] != 0 && (
                                    <OverlayTrigger
                                      placement='top'
                                      overlay={skillsTooltip(
                                        employee.lineManagerScore *
                                          gradeRatios['line manager skill ratio'],
                                        'Line Manager Score',
                                        'bg-line-manager'
                                      )}
                                    >
                                      <div
                                        className='progress-bar bg-line-manager'
                                        role='progressbar'
                                        style={{
                                          width: `${
                                            employee.lineManagerScore *
                                              gradeRatios['line manager skill ratio'] <=
                                            1
                                              ? 1
                                              : employee.lineManagerScore *
                                                gradeRatios['line manager skill ratio']
                                          }%`,
                                        }}
                                      ></div>
                                    </OverlayTrigger>
                                  )}
                                </div>
                              </div>
                            </td>
                          ) : (
                            <td className='text-end' key={'overallTable data' + index}>
                              <div className='d-flex flex-column w-100 me-2'>
                                <div className='d-flex flex-stack mb-2'>
                                  <span className='text-muted me-2 fs-7 fw-semibold'>
                                    Overall Score: {employee.score}%
                                  </span>
                                </div>

                                <div
                                  className='progress bg-light h-6px w-100'
                                  style={{minWidth: '250px'}}
                                >
                                  <OverlayTrigger
                                    placement='top'
                                    overlay={skillsTooltip(
                                      employee.score,
                                      'Overall Score',
                                      'bg-primary'
                                    )}
                                  >
                                    <div
                                      className='progress-bar bg-primary'
                                      role='progressbar'
                                      style={{
                                        width: `${employee.score <= 1 ? 1 : employee.score}%`,
                                      }}
                                    ></div>
                                  </OverlayTrigger>
                                </div>
                              </div>
                            </td>
                          )
                        } else if (index > 13) {
                          let progressBarClassName =
                            'progres-bar bg-' +
                            tableColumns[index].label.toLowerCase().replace(/ /g, '-')

                          return (
                            <td className='text-end' key={'overallTable data' + index}>
                              <div className='d-flex flex-column w-100 me-2'>
                                <div className='d-flex flex-stack mb-2'>
                                  <span className='text-muted me-2 fs-7 fw-semibold'>
                                    Score:{' '}
                                    {Math.round(
                                      (employee[
                                        tableColumns[index].value as keyof Employee
                                      ] as number) * 100
                                    )}
                                    %
                                  </span>
                                </div>

                                <div
                                  className='progress bg-light h-6px w-100'
                                  style={{minWidth: '250px'}}
                                >
                                  <OverlayTrigger
                                    placement='top'
                                    overlay={skillsTooltip(
                                      (employee[
                                        tableColumns[index].value as keyof Employee
                                      ] as number) * 100,
                                      index === 14 ? 'Aptitude' : tableColumns[index].label,
                                      `bg-${tableColumns[index].label
                                        .toLowerCase()
                                        .replace(/ /g, '-')}`
                                    )}
                                  >
                                    <div
                                      className={progressBarClassName}
                                      role='progressbar'
                                      style={{
                                        width: `${
                                          (employee[
                                            tableColumns[index].value as keyof Employee
                                          ] as number) *
                                            100 <=
                                          1
                                            ? 1
                                            : (employee[
                                                tableColumns[index].value as keyof Employee
                                              ] as number) * 100
                                        }%`,
                                      }}
                                    ></div>
                                  </OverlayTrigger>
                                </div>
                              </div>
                            </td>
                          )
                        } else if (tableColumns[index].value == 'candidate id') {
                          return (
                            <td key={'overallTable data' + index}>
                              <span className='text-muted fw-semibold text-muted d-block fs-7'>
                                {employee['employee number']}
                              </span>
                            </td>
                          )
                        } else if (tableColumns[index].value == 'nationality') {
                          return (
                            <td key={'overallTable data' + index}>
                              <div
                                style={{height: '15px'}}
                                title={regionNames.of(
                                  employee[tableColumns[index].value as keyof Employee] as string
                                )}
                              >
                                <Flag
                                  countryCode={
                                    employee[tableColumns[index].value as keyof Employee] as string
                                  }
                                />
                              </div>
                            </td>
                          )
                        } else {
                          return (
                            <td key={'overallTable data' + index}>
                              <span className='text-muted fw-semibold text-muted d-block fs-7'>
                                {employee[tableColumns[index].value as keyof Employee]}
                              </span>
                            </td>
                          )
                        }
                      }
                    })}

                    <td>
                      <div className='d-flex justify-content-end flex-shrink-0'>
                        <div>
                          <Link
                            className={
                              `nav-link text-active-primary me-6 ` +
                              (location.pathname === '/candidate-profile' && 'active')
                            }
                            to={`/candidate-profile?employeeQuery=${employee['employee id']}`}
                          >
                            <KTIcon iconName='arrow-right' className='fs-3' />
                          </Link>
                        </div>
                      </div>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

export {UserListCard}
