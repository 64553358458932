import React, {createContext, useState, FC} from 'react'

interface AuthContextType {
  loggedIn: boolean
  userName: string
  userId: string
  userType: number
  login: (name: string, id: string, type: number) => void
  logout: () => void
}

const AuthContext = createContext<AuthContextType>({
  loggedIn: false,
  userName: '',
  userId: '',
  userType: 0,
  login: () => {},
  logout: () => {},
})

interface AuthProviderProps {
  children: React.ReactNode
}

const AuthProvider: FC<AuthProviderProps> = ({children}) => {
  const [loggedIn, setLoggedIn] = useState(false)
  const [userName, setUserName] = useState('')
  const [userId, setUserId] = useState('')
  const [userType, setUserType] = useState(-1)

  const login = (name: string, id: string, type: number) => {
    setLoggedIn(true)
    setUserName(name)
    setUserId(id)
    setUserType(type)
  }

  const logout = () => {
    setLoggedIn(false)
    setUserName('')
    setUserId('')
    setUserType(-1)
  }

  const authContextValue: AuthContextType = {
    loggedIn,
    userName,
    userId,
    userType,
    login,
    logout,
  }

  return <AuthContext.Provider value={authContextValue}>{children}</AuthContext.Provider>
}

export {AuthContext, AuthProvider}
