import {useLocation} from 'react-router-dom'
import {PageLink} from '../../../../_metronic/layout/core'
import {UserListDropDown} from './UserListDropDown_Ent'
import {FC, useState, useEffect} from 'react'
import {UserNumberListDropDown} from './UserNumberListDropDown_Ent'
import ProfilePageTraining from './ProfilePageTraining_Ent'
import {Nav, Tab} from 'react-bootstrap'
import config from '../../../../config.json'
import {useUserContext} from '../../../../UserContext'

type TrainingNeedsStruct = {
  compID: string
  compName: string
  courseName: string
  courseLevel: string
  courseType: string
}

const usersBreadcrumbs: Array<PageLink> = [
  {
    title: 'User Management',
    path: '/user-management',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: false,
    isActive: false,
  },
]

const TrainingNeeds: FC = () => {
  const location = useLocation()
  // const [selectedEmployee, setSelectedEmployee] = useState<string>('')
  // const [employeeQueryFromSearch, setEmployeeQueryFromSearch] = useState<string | null>('')
  // const [dropdownUsed, setDropdownUsed] = useState(0)
  const [getTrainingNeedsData, setGetTrainingNeedsData] = useState<TrainingNeedsStruct[]>([])
  const [showAlert, setShowAlert] = useState<boolean>(false)
  const [alertMessage, setAlertMessage] = useState<string>('')
  const [alertClassName, setAlertClassName] = useState<string>('alert-success')

  // const [getTrainingNeedsData, setGetTrainingNeedsData] = useState<string[]>([])
  const [trainingNeedsData, setTrainingNeedsData] = useState<string[]>([])
  
  // }
  const [rows, setRows] = useState<TrainingNeedsStruct[]>([
    {compID: '', compName: '', courseName: '', courseLevel: '', courseType: ''},
  ])


  async function getTrainingNeeds() {
    // if (user?.['user id'] && user?.['user type'] == 'employee') {

    const getNeeds = await fetch(config.domainName + ':' + config.port + '/api/trainings', {
      method: 'GET',
      headers: {'Content-Type': 'application/json'},
      // body: JSON.stringify({
      //   // function: 'GetEmployeePicture',
      //   employee_id: "hpe-superadmin",
      //   // employee_id: employeeQuery,
      // }),
    })
    if (getNeeds.ok) {
      const getNeedsCont = await getNeeds.json()
      const dummydata: TrainingNeedsStruct[] = getNeedsCont.map((train: any) => {
        return {
          compID: train['competency id'],
          compName: train['competency name'],
          courseName: train['course name'],
          courseLevel: train['course level'],
          courseType: train['course type'],
        }
      })
      // console.log('dummydata', dummydata)
      setGetTrainingNeedsData(dummydata)
      // console.log('getImgConst', getNeedsCont)
    }

    // }
  }
  async function sendTrainingNeeds(arr: any) {
    // const data = new FormData()
    // data.append('something', arr)

    const sendTrain = await fetch(config.domainName + ':' + config.port + '/api/trainings', {
      method: 'POST',
      headers: {'Content-Type': 'application/json'},
      body: arr,
    })
    const sendImgCont = await sendTrain.json()
    // console.log(sendTrain.status)
    if (sendTrain.status === 200) {
      // console.log('saved : ', rows)
      setRows([{compID: '', compName: '', courseName: '', courseLevel: '', courseType: ''}])
      getTrainingNeeds()
      // console.log('Table Saved')
      setAlertMessage('Table Saved')
      setAlertClassName('alert-success')
      setShowAlert(true)
      setTimeout(() => {
        setShowAlert(false)
      }, 3000)
    } else {
      // console.log('Table Saved')
      setAlertMessage('Some competencies probably exists or were entered incorrectly')
      setAlertClassName('alert-danger')
      setShowAlert(true)
      setTimeout(() => {
        setShowAlert(false)
      }, 3000)
    }
    return sendTrain.status
  }

  async function deleteTrainingNeeds(arr: string[]) {
    // console.log('arr', arr)
    // console.log('json arr', JSON.stringify(arr))
    const jsonArr = JSON.stringify(arr)
    // const jsonArr = "["+arr+"]"
    try {
      const deleteTrain = await fetch(config.domainName + ':' + config.port + '/api/trainings', {
        method: 'DELETE',
        headers: {'Content-Type': 'application/json'},
        body: jsonArr,
        // body: JSON.stringify(arr),
      })
      const sendImgCont = await deleteTrain.json()

      // console.log(sendImgCont)
      // if (deleteTrain.status === 500) {
      // console.log('saved : ', rows)
      // setRows([{compID: '', compName: '', courseName: '', courseLevel: '', courseType: ''}])
      // console.log('array refreshed')
      getTrainingNeeds()
      // setAlertMessage('Row Deleted')
      // setAlertClassName('alert-success')
      // setShowAlert(true)
      // setTimeout(() => {
      //   setShowAlert(false)
      //   getTrainingNeeds()
      // }, 2000)
      
      // }
      return deleteTrain.status
    } catch (error) {
      console.error('Error something here:', error)
      
      getTrainingNeeds()
     
      // getTrainingNeeds()
      return -1
    }
  }
  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
    index: number
  ) => {
    // setDisable(false);
    const {name, value} = e.target
    // console.log(e.target.name)
    const list = [...rows]
    list[index][name as keyof TrainingNeedsStruct] = value
    setRows(list)
  }
  const handleRemoveClick = (i: number) => {
    const list = [...rows]
    list.splice(i, 1)
    setRows(list)
    // const arr = [i]
    // deleteTrainingNeeds(arr)
    // console.log('i',i)
    // setShowConfirm(false);
  }
  const handleDeleteClick = (i: string) => {
    const arr = [i]
    deleteTrainingNeeds(arr)
    // console.log('i', i)
  }
  const handleAdd = () => {
    setRows([...rows, {compID: '', compName: '', courseName: '', courseLevel: '', courseType: ''}])
    // setEdit(true);
  }
  const handleSave = () => {
    // setEdit(!isEdit);
    setRows(rows)
    const hasEmptyFields = rows.some(
      (item) =>
        !item.compID || !item.compName || !item.courseName || !item.courseType || !item.courseLevel
    )

    if (hasEmptyFields) {
      // Display an error message
      // console.log('Please fill in all fields before saving.')
      setAlertMessage('Please fill in all fields before saving.')
      setAlertClassName('alert-danger')
      setShowAlert(true)
      setTimeout(() => {
        setShowAlert(false)
      }, 3000)
    } else {
      // All fields are filled, proceed with the save
      const formattedJSON = JSON.stringify(
        rows.map((item) => ({
          'competency id': item.compID,
          'competency name': item.compName,
          'course name': item.courseName,
          'course type': item.courseType,
          'course level': item.courseLevel,
        }))
      )

      // console.log('formatted array', formattedJSON)
      const result = sendTrainingNeeds(formattedJSON)
      // console.log('Table Saved')
      // setAlertMessage('Table Saved')
      // setAlertClassName('alert-success')
      // setShowAlert(true)
      // setTimeout(() => {
      //   setShowAlert(false)
      // }, 3000)
    }
    // setRows(rows)
    // const formattedJSON = JSON.stringify(
    //   rows.map((item) => ({
    //     'competency id': item.compID,
    //     'competency name': item.compName,
    //     'course name': item.courseName,
    //     'course type': item.courseType,
    //     'course level': item.courseLevel,
    //   }))
    // )
    // console.log('formatted array', formattedJSON)
    // const result = sendTrainingNeeds(formattedJSON)

    // console.log("result",result)
    // if(result === 200){
    //   console.log("saved : ", rows);
    //   setRows([{compID: '', compName: '', courseName: '', courseLevel: '', courseType: ''}]);
    // }
    // console.log("saved : ", rows);
    // setRows([{compID: '', compName: '', courseName: '', courseLevel: '', courseType: ''}]);
    // setDisable(true);
    // setOpen(true);
  }
  useEffect(() => {
    getTrainingNeeds()
  }, [])

  return (
    <div className='card card-xxl-stretch mb-5 mb-xl-8 p-5'>
      {/* <div className='row g-5 g-xxl-8 h-100'> */}
      {/* <div className='d-flex flex-row'> */}
      {/* <span>this is training needs</span> */}
      <Tab.Container defaultActiveKey='Training Edit'>
        <div className='d-flex justify-content-center'>
          <Nav variant='pills' className='flex-row'>
            <Nav.Item key={'trainingEdit'}>
              <Nav.Link eventKey='Training Edit'>Training Edit</Nav.Link>
            </Nav.Item>
            <Nav.Item key={'trainingView'}>
              <Nav.Link eventKey='Training View'>Training View</Nav.Link>
            </Nav.Item>
          </Nav>
        </div>

        <div className='d-flex overflow-scroll h-100' style={{minHeight: '500px'}}>
          <Tab.Content>
            <Tab.Pane key={'trainingEdit'} eventKey='Training Edit'>
              <div className='card-body py-3'>
                {/* <span>this is training needs edit</span> */}
                <button className='btn btn-primary me-5' onClick={handleAdd}>
                  Add Row
                </button>
                <button className='btn btn-primary' onClick={handleSave}>
                  Save Table
                </button>
                {showAlert && <div className={`alert ${alertClassName} mt-3`}>{alertMessage}</div>}
                <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4 table-fixed'>
                  <thead>
                    <tr className='fw-bold text-muted'>
                      <th className='fixed-column' style={{minWidth: '150px'}}>
                        Competency ID
                      </th>
                      <th className='fixed-column' style={{minWidth: '150px'}}>
                        Competency Name
                      </th>
                      <th className='fixed-column' style={{minWidth: '150px'}}>
                        Course Name
                      </th>
                      <th className='fixed-column' style={{minWidth: '150px'}}>
                        Course Level
                      </th>
                      <th className='fixed-column' style={{minWidth: '150px'}}>
                        Course Type
                      </th>
                      <th className='fixed-column' style={{minWidth: '150px'}}></th>
                    </tr>
                  </thead>
                  <tbody>
                    {rows.map((val, i) => {
                      return (
                        <tr key={i}>
                          <td>
                            <input
                              className='form form-control'
                              value={val.compID}
                              name='compID'
                              onChange={(e) => handleInputChange(e, i)}
                            />
                          </td>
                          <td>
                            <input
                              className='form form-control'
                              value={val.compName}
                              name='compName'
                              onChange={(e) => handleInputChange(e, i)}
                            />
                          </td>
                          <td>
                            <input
                              className='form form-control'
                              value={val.courseName}
                              name='courseName'
                              onChange={(e) => handleInputChange(e, i)}
                            />
                          </td>
                          <td>
                            {/* <input
                          value={val.courseLevel}
                          name='courseLevel'
                          // onChange={(e) => handleInputChange(e, i)}
                        /> */}
                            <select
                              // style={{ width: "100px" }}
                              className='form form-select'
                              name='courseLevel'
                              value={val.courseLevel}
                              onChange={(e) => handleInputChange(e, i)}
                            >
                              <option value=''></option>
                              <option value='Advanced'>Advanced</option>
                              <option value='Core'>Core</option>
                              <option value='Foundation'>Foundation</option>
                            </select>
                          </td>
                          <td>
                            {/* <input
                          value={val.courseType}
                          name='courseType'
                          // onChange={(e) => handleInputChange(e, i)}
                        /> */}
                            <select
                              // style={{ width: "100px" }}
                              className='form form-select'
                              name='courseType'
                              value={val.courseType}
                              onChange={(e) => handleInputChange(e, i)}
                            >
                              <option value=''></option>
                              <option value='Training'>Training</option>
                              <option value='Official Certifiable Course'>
                                Official Certiable Course
                              </option>
                              <option value='Hands-On Virtual Lab'>Hands-On Virtual Lab</option>
                            </select>
                          </td>
                          <td>
                            <button className='btn btn-danger' onClick={() => handleRemoveClick(i)}>
                              X
                            </button>
                          </td>
                        </tr>
                      )
                    })}
                    {/* <tr>
                      <td>
                        <input
                          // value={row.firstname}
                          name='compID'
                          // onChange={(e) => handleInputChange(e, i)}
                        />
                      </td>
                    </tr> */}
                  </tbody>
                </table>
              </div>
            </Tab.Pane>
            <Tab.Pane key={'trainingView'} eventKey='Training View'>
              <div className='card-body py-3'>
                {/* <span>this is training needs view</span> */}
                <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4 table-fixed'>
                  <thead>
                    <tr className='fw-bold text-muted'>
                      <th className='fixed-column' style={{minWidth: '150px'}}>
                        Competency ID
                      </th>
                      <th className='fixed-column' style={{minWidth: '150px'}}>
                        Competency Name
                      </th>
                      <th className='fixed-column' style={{minWidth: '150px'}}>
                        Course Name
                      </th>
                      <th className='fixed-column' style={{minWidth: '150px'}}>
                        Course Level
                      </th>
                      <th className='fixed-column' style={{minWidth: '150px'}}>
                        Course Type
                      </th>
                      <th className='fixed-column' style={{minWidth: '150px'}}></th>
                    </tr>
                  </thead>
                  <tbody>
                    <>
                      {getTrainingNeedsData != null &&
                        getTrainingNeedsData.map((row, index) => {
                          return (
                            <>
                              <tr key={index}>
                                <td>{row?.compID}</td>
                                <td>{row?.compName}</td>
                                <td ><p style={{wordWrap:"break-word"}}>{row?.courseName}</p></td>
                                <td>{row?.courseLevel}</td>
                                <td>{row?.courseType}</td>
                                <td>
                                  <button
                                    className='btn btn-danger'
                                    onClick={() => handleDeleteClick(row?.compID)}
                                  >
                                    Delete Row
                                  </button>
                                  {showAlert && (
                                    <div className={`alert ${alertClassName} mt-3`}>
                                      {alertMessage}
                                    </div>
                                  )}
                                </td>
                              </tr>
                            </>
                          )
                        })}
                      {/* <tr>
                      <td>
                        
                      </td>
                    </tr> */}
                    </>
                  </tbody>
                </table>
              </div>
            </Tab.Pane>
          </Tab.Content>
        </div>
      </Tab.Container>
    </div>
    // </div>
    // </div>
  )
}
export {TrainingNeeds}
