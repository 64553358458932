/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import {KTIcon} from '../../../../helpers'

type Props = {
  className: string
  color?: string
  svgIcon?: string
  iconColor?: string
  title: string | number
  titleColor?: string
  titleType?: string
  description: string
  descriptionColor?: string
}

const DashCard: React.FC<Props> = ({
  className,
  color,
  svgIcon,
  iconColor,
  title,
  titleColor,
  titleType,
  description,
  descriptionColor,
}) => {
  return (
    <a href='#' className={`card d-flex bg-${color} hoverable ${className}`}>
      <div className='d-flex flex-column h-100'>
        <div className='p-10' style={{position: 'absolute', right: '0'}}>
          {svgIcon && (
            <KTIcon
              iconName={svgIcon}
              iconType='duotone'
              className={`text-${iconColor} fs-3x ms-n1`}
            />
          )}
        </div>

        <div className='card-header border-0 pt-5'>
          {titleType == 'num' ? (
            <div className={`text-${titleColor} fw-bold fs-3x mb-0`}>
              {Math.round((title as number) * 100)}%
            </div>
          ) : (
            <div className={`text-${titleColor} fw-bold fs-3x mb-0`}>{title}</div>
          )}
        </div>

        <div className='card-body d-flex flex-grow-1 align-items-end'>
          <div className={`fw-semibold text-${descriptionColor}`}>{description}</div>
        </div>
      </div>
    </a>
  )
}

export {DashCard}
