/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState} from 'react'
import {Button, CardImg, Modal} from 'react-bootstrap'
import {EmployeeCertificates} from '../../../../dataTypes'

type Props = {
  className: string
  employeeCerts: EmployeeCertificates[]
  setShowAddModal: any
}

const CertificatesTable: React.FC<Props> = ({className, employeeCerts, setShowAddModal}) => {
  const [showImageModal, setShowImageModal] = useState(false)
  const [selectedCertificate, setSelectedCertificate] = useState('')
  const [imageFromServer, setImageFromServer] = useState<ArrayBuffer | string>('')

  const openCertificateImage = (imagePath: string | undefined) => {
    if (imagePath !== undefined) {
      setSelectedCertificate(imagePath)
      setShowImageModal(true)
    }
  }

  return (
    <div className={`card ${className}`}>
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Certificates</span>
        </h3>
        <div className='card-toolbar'>
          {/* Add a button to toggle the form visibility */}
          <Button
            onClick={() => setShowAddModal(true)}
            className='btn btn-primary'
            data-target='Form'
          >
            Add Certificate
          </Button>
        </div>
      </div>
      <div className='card-body py-3'>
        <div className='table-responsive'>
          <table className='table align-middle gs-0 gy-5'>
            <thead>
              <tr className='fw-bolder fs-6 text-gray-800'>
                <th className='min-w-1px'>Image</th>
                <th className='min-w-100px'>Certificates</th>
                <th className='min-w-100px'>Certificate Type</th>
                <th className='min-w-140px'>License Number</th>
                <th className='min-w-140px'>Issue Date</th>
                <th className='min-w-140px'>Expiry Date</th>
              </tr>
            </thead>
            <tbody>
              {employeeCerts.map((certificate) => (
                <tr key={certificate['certificate id']}>
                  <th>
                    <img
                      src={certificate['img path']}
                      onClick={() => openCertificateImage(certificate['img path'])}
                      alt='Certificate'
                      style={{cursor: 'pointer', height: '30px', width: '30px'}}
                    />
                  </th>
                  <td>
                    <a
                      className='text-dark fw-bold text-hover-primary mb-1 fs-6'
                      //onClick={() => openCertificateImage(certificate['img path'])}
                      style={{cursor: 'pointer'}}
                    >
                      {certificate['certificate name']}
                    </a>
                  </td>
                  <td>{certificate['certificate type']}</td>
                  <td>{certificate['license']}</td>
                  <td>{certificate['issue date']}</td>
                  <td>{certificate['expiry date']}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      <Modal show={showImageModal} onHide={() => setShowImageModal(false)} size='lg' centered>
        <Modal.Body className='p-0'>
          <div className='d-flex justify-content-end mt-2 mr-2'>
            <Button variant='light' onClick={() => setShowImageModal(false)} aria-label='Close'>
              <span aria-hidden='true'>&times;</span>
            </Button>
          </div>
          <CardImg src={selectedCertificate} alt='Certificate' className='img-fluid' />
        </Modal.Body>
      </Modal>
    </div>
  )
}

export {CertificatesTable}
