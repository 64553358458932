/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useRef, useState} from 'react'
import ApexCharts, {ApexOptions} from 'apexcharts'
import {getCSS, getCSSVariableValue} from '../../../assets/ts/_utils'
import {useThemeMode} from '../../layout/theme-mode/ThemeModeProvider'

type Props = {
  className: string
  yaxisText?: string
  xaxisText?: string
  
  chartData?: []
  colNumber: number
}

const LineChart: React.FC<Props> = ({
  className,
  yaxisText = 'yaxis',
  xaxisText = 'xaxis',

  chartData,
  colNumber,
}) => {
  const chartRef = useRef<HTMLDivElement | null>(null)
  const {mode} = useThemeMode()

  // const colNumber = 8 //add column number here //actually ignore this

  const refreshMode = () => {
    if (!chartRef.current) {
      return
    }

    const height = parseInt(getCSS(chartRef.current, 'height'))

    const chart =
      chartData !== undefined &&
      new ApexCharts(
        chartRef.current,
        getChartOptions(height, yaxisText, xaxisText, chartData, colNumber)
      )
    if (chart) {
      chart.render()
    }

    return chart
  }
  //#region USE EFFECTS

  useEffect(() => {
    const chart = refreshMode()

    return () => {
      if (chart) {
        chart.destroy()
      }
    }
  }, [chartRef, mode, colNumber, chartData, yaxisText, xaxisText])

  return (
    <>
      <div ref={chartRef} id='kt_line_chart' style={{height: '100%'}}></div>
    </>
  )
}

export {LineChart}

function getChartOptions(
  height: number,
  yaxisText: string,
  xaxisText: string,
  chartData: [],

  col: number
): ApexOptions {
  const labelColor = getCSSVariableValue('--bs-dark')
  const borderColor = getCSSVariableValue('--bs-gray-200')
  const baseColor = getCSSVariableValue('--bs-success')
  const lightColor = getCSSVariableValue('--bs-success-light')
  let numArray = chartData.map((i) => parseInt(i))

  for (let i = 0; i < numArray.length; i++) {
    if (isNaN(numArray[i])) {
      numArray[i] = 0
    }
  }
  let sortedNumArray = numArray.sort((a, b) => a - b)

  //left this for tests
  // let sortedNumArray = [3, 3, 3, 3, 3, 3, 3, 13, 15, 15, 20, 22, 22, 23, 33, 33, 38, 38, 38, 38]

  let colGraph = col

  if (colGraph % 2 != 0) {
    colGraph = colGraph + 1
  }

  let largestYrExp = Math.ceil(Math.max(...sortedNumArray) / 10) * 10

  let range = Math.ceil(largestYrExp / colGraph)

  let rangesArr: number[] = []
  for (let index = 0; index <= colGraph; index++) {
    rangesArr.push(range * index)
  }

  let seriesData: number[] = []

  let i,
    j = 0
  for (i = 0; i < rangesArr.length - 1; i++) {
    let count = 0
    for (j = 0; j < sortedNumArray.length; j++) {
      if (sortedNumArray[j] >= rangesArr[i] && sortedNumArray[j] < rangesArr[i + 1]) {
        count = count + 1
      }
    }

    seriesData.push(count)
  }
  seriesData.unshift(0)

  return {
    series: [
      {
        name: 'No Of candidates',
        data: seriesData,
      },
    ],
    chart: {
      fontFamily: 'inherit',
      type: 'area',
      height: '100%',
      toolbar: {
        show: false,
      },
    },
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    fill: {
      type: 'solid',
      opacity: 1,
    },
    stroke: {
      curve: 'smooth',
      show: true,
      width: 3,
      colors: [baseColor],
    },
    xaxis: {
      title: {
        text: xaxisText,
        style: {
          color: labelColor,
          fontSize: '12px',
          // fontWeight:200,
        },
      },
      categories: rangesArr,
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
      crosshairs: {
        position: 'front',
        stroke: {
          color: baseColor,
          width: 1,
          dashArray: 3,
        },
      },
      tooltip: {
        enabled: true,
        formatter: undefined,
        offsetY: 0,
        style: {
          fontSize: '12px',
        },
      },
    },
    yaxis: {
      title: {
        text: yaxisText,
        style:{
          color: labelColor,
        }
        


      },
      labels: {
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
    },

    states: {
      normal: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      hover: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: 'none',
          value: 0,
        },
      },
    },
    tooltip: {
      style: {
        fontSize: '12px',
      },
      y: {
        formatter: function (val) {
          return '' + val + '  '
        },
      },
      x: {
        formatter: function (val) {
          if (rangesArr[val - 1] === 0) {
            return '' + rangesArr[val - 1] + ' '
          } else {
            return '' + rangesArr[val - 2] + ' to ' + rangesArr[val - 1] + ' years '
          }
        },
      },
    },
    colors: [lightColor],
    grid: {
      borderColor: borderColor,
      strokeDashArray: 4,
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
    markers: {
      strokeColors: baseColor,
      strokeWidth: 3,
    },
  }
}
