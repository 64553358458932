import React, {createContext, useContext, useEffect, useState, ReactNode} from 'react'
import {UserModel} from './dataTypes' // Update the import path for UserModel
import config from './config.json'

interface UserContextProps {
  user: UserModel | null
  setUser: React.Dispatch<React.SetStateAction<UserModel>>
  setUserUpdated: React.Dispatch<React.SetStateAction<boolean>>
}

const UserContext = createContext<UserContextProps | undefined>(undefined)

export const useUserContext = (): UserContextProps => {
  const context = useContext(UserContext)
  if (!context) {
    throw new Error('useUserContext must be used within a UserContextProvider')
  }
  return context
}

interface UserContextProviderProps {
  children: ReactNode // Define the children prop as ReactNode
}

export const UserContextProvider: React.FC<UserContextProviderProps> = ({children}) => {
  const [user, setUser] = useState<UserModel>({
    'user id': '',
    'user name': '',
    'user type': '',
  })
  const [userUpdated, setUserUpdated] = useState(false)

  useEffect(() => {
    async function checkUserInfo() {
      const checkUser = await fetch(config.domainName + ':' + config.port + '/api/user', {
        method: 'GET',
        headers: {'Content-Type': 'application/json'},
        credentials: 'include',
      })

      const content = await checkUser.json()

      if (content['message'] === 'Success') {
        const userData: UserModel = {
          'user id': content['userID'],
          'user name': content['user'],
          'user type': content['type'],
        }
        setUser(userData)
      }
    }

    checkUserInfo()
  }, [userUpdated])

  return (
    <UserContext.Provider value={{user, setUser, setUserUpdated}}>{children}</UserContext.Provider>
  )
}
