/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import {KTIcon} from '../../../../helpers'
import {EntityDetails, LeadershipDetails} from '../../../../../dataTypes'
import {useUserContext} from '../../../../../UserContext'
import config from '../../../../../config.json'
import WarningSpinner from '../../../../../app/modules/profile/components/WarningSpinner'
import {DashCard} from './DashCard'

type Props = {
  className: string
  color?: string
  svgIcon?: string
  iconColor?: string
  title: string
  titleColor?: string
  titleType?: string
  description: string
  descriptionColor?: string
}

const LeadershipBaseDashCard: React.FC<Props> = ({
  className,
  color,
  svgIcon,
  iconColor,
  title,
  titleColor,
  titleType,
  description,
  descriptionColor,
}) => {
  const {user} = useUserContext()
  const [leadershipData, setLeadershipData] = useState<LeadershipDetails>()

  async function callLeadershipInfo() {
    if (user) {
      const leadershipData = await fetch(
        config.domainName + ':' + config.port + '/api/allentitiesdata',
        {
          method: 'GET',
          headers: {'Content-Type': 'application/json'},
        }
      )
      const leadershipDataCont = await leadershipData.json()
      if (leadershipDataCont) {
        const leadershipData: LeadershipDetails = {
          'average grade': leadershipDataCont['entities grade'],
          'average score': leadershipDataCont['avg entity score'],
          'number of employees': leadershipDataCont['total employees'],
          'number of entities': leadershipDataCont['entities data'].length,
          color: leadershipDataCont['entities color'],
        }

        setLeadershipData(leadershipData)
      }
    }
  }

  useEffect(() => {
    callLeadershipInfo()
  }, [])

  return (
    <div className={`card ${className}`}>
      {leadershipData ? (
        <DashCard
          className='h-100'
          svgIcon={svgIcon}
          color={titleType == 'num' ? `${leadershipData['average grade']}-grade` : color}
          iconColor={iconColor}
          titleColor={titleColor}
          title={leadershipData[title as keyof LeadershipDetails]}
          titleType={titleType}
          description={description}
          descriptionColor={descriptionColor}
        />
      ) : (
        <WarningSpinner spinnerWidth='60px' canReload={true} reloadFunc={callLeadershipInfo} />
      )}
    </div>
  )
}

export {LeadershipBaseDashCard}
