/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {toAbsoluteUrl} from '../../../helpers'

type Props = {
  className: string
  title?: string
}

// Copy of List Widget 06
const GradesBreakdown: React.FC<Props> = ({className, title}) => {
  const itemsList = [
    {
      title: 'Beginner',
      subtitle: 'Beginner Grade',
      range: '0%-24%',
      img: '/media/Images/B-red.png',
      color: 'bg-beginner-grade',
    },
    {
      title: 'Intermediate',
      subtitle: 'Intermediate Grade',
      range: '25%-49%',
      img: '/media/Images/I-yellow.png',
      color: 'bg-intermediate-grade',
    },
    {
      title: 'Experienced',
      subtitle: 'Experienced Grade',
      range: '50%-74%',
      img: '/media/Images/E-blue.png',
      color: 'bg-experienced-grade',
    },
    {
      title: 'Proficient',
      subtitle: 'Proficient Grade',
      range: '75%-100%',
      img: '/media/Images/P-green.png',
      color: 'bg-proficient-grade',
    },
  ]

  return (
    <div className={`card ${className}`}>
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>{title}</span>
        </h3>
      </div>

      <div className='card-body' style={{overflow: 'scroll'}}>
        {itemsList?.map((item, index) => (
          <div key={index} className={`d-flex align-items-center rounded p-3 mb-3 ${item.color}`}>
            <img
              src={toAbsoluteUrl(item.img)}
              style={{
                width: '30%',
                height: '1.2',
                position: 'relative',
                left: '0',
                top: '0',
              }}
            ></img>

            <div className='flex-grow-1 me-5'>
              <a
                href='#'
                className='fw-bold text-800 text-hover-primary fs-6'
                style={{color: 'white'}}
              >
                {item.title}
              </a>
              <span className='text fw-semibold d-block fs-8' style={{color: 'white'}}>
                {item.subtitle}
              </span>
            </div>

            <span
              className='fw-bold text py-1'
              style={{color: 'white', marginLeft: '10px', marginRight: '10px'}} // Increase the margin left
            >
              {item.range}
            </span>
          </div>
        ))}
      </div>
    </div>
  )
}

export {GradesBreakdown}
