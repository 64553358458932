import {FC, useEffect, useState} from 'react'
import {OverlayTrigger, Tooltip} from 'react-bootstrap'
import config from '../../../../../config.json'
import {EntityAssessmentInfo} from '../../../../../dataTypes'
import {useUserContext} from '../../../../../UserContext'
import WarningSpinner from '../../../../../app/modules/profile/components/WarningSpinner'
import {Link} from 'react-router-dom'
import {useCallInterval} from '../../../../../CallTimeIntervalContext'

type Props = {
  className: string
  title: string
}

const threshold = 2

const OverallAssessments: FC<Props> = ({className, title}) => {
  const [entityAssessmentInfo, setEntityAssessmentInfo] = useState<EntityAssessmentInfo>()
  const {user} = useUserContext()
  const {callInterval} = useCallInterval()

  async function callEntAssessmentInfo() {
    if (user?.['user id']) {
      const entAssessment = await fetch(
        config.domainName + ':' + config.port + '/api/entityquery',
        {
          method: 'POST',
          headers: {'Content-Type': 'application/json'},
          body: JSON.stringify({
            function: 'EntityAssessmentInfo',
            entity_id: user['user id'],
          }),
        }
      )

      const entAssessmentCont = await entAssessment.json()
      if (entAssessment) {
        const entityAssessment: EntityAssessmentInfo = {
          'Assessments completed': entAssessmentCont['assessments completed'],
          'Assessments pending': entAssessmentCont['assessments pending'],
          'Total assessments': entAssessmentCont['total assessments'],
        }

        if (
          entityAssessment['Assessments completed'] !== undefined &&
          entityAssessment['Assessments pending'] !== undefined &&
          entityAssessment['Total assessments'] !== undefined
        )
          setEntityAssessmentInfo(entityAssessment)
      }
    }
  }

  useEffect(() => {
    callEntAssessmentInfo()
  }, [])

  useEffect(() => {
    const interval = setInterval(() => {
      callEntAssessmentInfo()
    }, callInterval)

    return () => clearInterval(interval)
  }, [])

  const skillsTooltip = (score: number, title: string, color: string) => (
    <Tooltip
      id='skillsToolTip'
      className={` ${color}`}
      style={{borderRadius: '10px', position: 'absolute'}}
    >
      <span className='text-gray-800' style={{fontSize: '12px'}}>
        {title}: {Math.round(score)}
      </span>
    </Tooltip>
  )

  return (
    <div
      className={`card card-flush bgi-no-repeat bgi-size-contain bgi-position-x-end ${className}`}
    >
      <div className='card-header border-0 pt-5'>
        <div className='card-title d-flex flex-column'>
          <h3 className='card-title align-items-start flex-column'>
            <Link to={'/candidate-overview'}>
              <span className='card-label fw-bold fs-3 mb-1'>{title}</span>
            </Link>
          </h3>
        </div>
      </div>

      {entityAssessmentInfo ? (
        <div className='card-body pt-2 pb-4 d-flex flex-column align-items-center w-100'>
          <div className='d-flex flex-column content-justify-center flex-row-fluid'>
            <div className='d-flex fw-semibold align-items-center'>
              <div className='text-dark-500 flex-grow-1 me-4 fs-5'>Total Assessments</div>
              <div className='fw-bolder text-dark-700 text-xxl-end fs-4'>
                {entityAssessmentInfo['Total assessments']}
              </div>
            </div>
          </div>
          <div className='d-flex flex-center me-5 pt-2 w-100 h-100'>
            <div className='progress bg-light h-25px w-100'>
              <OverlayTrigger
                placement='top'
                overlay={skillsTooltip(
                  entityAssessmentInfo['Assessments completed'],
                  'Completed Assessments',
                  'bg-primary'
                )}
              >
                <div
                  className='progress-bar bg-primary'
                  role='progressbar'
                  style={{
                    width: `${
                      (entityAssessmentInfo['Assessments completed'] /
                        entityAssessmentInfo['Total assessments']) *
                        100 <=
                      threshold
                        ? threshold
                        : (entityAssessmentInfo['Assessments completed'] /
                            entityAssessmentInfo['Total assessments']) *
                          100
                    }%`,
                  }}
                ></div>
              </OverlayTrigger>
              <OverlayTrigger
                placement='top'
                overlay={skillsTooltip(
                  entityAssessmentInfo['Assessments pending'],
                  'Pending Assessments',
                  'bg-info'
                )}
              >
                <div
                  className='progress-bar bg-secondary'
                  role='progressbar'
                  style={{
                    width: `${
                      (entityAssessmentInfo['Assessments pending'] /
                        entityAssessmentInfo['Total assessments']) *
                        100 <=
                      threshold
                        ? threshold
                        : (entityAssessmentInfo['Assessments pending'] /
                            entityAssessmentInfo['Total assessments']) *
                          100
                    }%`,
                  }}
                ></div>
              </OverlayTrigger>
            </div>
          </div>
        </div>
      ) : (
        <WarningSpinner spinnerWidth='60px' canReload={true} reloadFunc={callEntAssessmentInfo} />
      )}
    </div>
  )
}

export {OverallAssessments}
