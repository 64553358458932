import {useEffect, useState, FC} from 'react'
import {SkillsDescription} from '../../../../../_metronic/partials/widgets'
import config from '../../../../../config.json'
import {Skill} from '../../../../../dataTypes'
import {useUserContext} from '../../../../../UserContext'
import {SkillsTable} from '../../../../../_metronic/partials/widgets/tables/SkillsTable'
import {EmployeeDetails} from '../../../../../dataTypes'
import OverlayTrigger from 'react-bootstrap/esm/OverlayTrigger'
import {Tooltip} from 'react-bootstrap'

export const PerformanceTechnical: FC<{
  isCompact?: boolean
  employeeQuery?: string
  className?: string
}> = ({isCompact = false, employeeQuery, className}) => {
  const [employeeKSA, setEmployeeKSA] = useState<Skill[]>([])
  const [selectedIndex, setSelectedIndex] = useState(-1)
  const [empdata, setEmpData] = useState<EmployeeDetails | undefined | any>()
  const {user} = useUserContext()

  const [showLoading, setShowLoading] = useState(false) // State to control showing the warning message

  const warningTime = 5000

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowLoading(true)
    }, warningTime)
    return () => clearTimeout(timer)
  }, [])

  let OverallColor = '#ba403e'

  const threshold = 2

  const skillsTooltip = (score: number, title: string, color: string) => (
    <Tooltip id='skillsToolTip' className={` ${color}`} style={{borderRadius: '10px'}}>
      <span className='text-gray-800' style={{fontSize: '12px'}}>
        {title}: {Math.round(score)}%
      </span>
    </Tooltip>
  )
  async function callKSAData(employeeQuery: string | undefined) {
    if (employeeQuery) {
      const empKSA = await fetch(config.domainName + ':' + config.port + '/api/employeequery', {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({
          function: 'EmployeeKSA',
          employee_id: employeeQuery,
        }),
      })

      const empKSACont = await empKSA.json()

      const ksaArray: Skill[] = empKSACont['user ksa'].map((skills: any) => ({
        title: skills['title'],
        score: skills['score'],
        grade: skills['grade'],
        color: skills['color'],
        description: skills['description'],
      }))
      setEmployeeKSA(ksaArray)
    }
  }

  async function callEmpData(employeeQuery: string | undefined) {
    if (employeeQuery) {
      const allEmpData = await fetch(config.domainName + ':' + config.port + '/api/employeequery', {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({
          function: 'AllEmployeeData',
          employee_id: employeeQuery,
        }),
      })
      const allEmpDataCont = await allEmpData.json()
      const employeeData: EmployeeDetails = {
        'employee id': allEmpDataCont.employee['employee id'],
        'employee number': allEmpDataCont.employee['employee number'],
        name: allEmpDataCont.employee.name,
        entity: allEmpDataCont.employee.entity,
        email: allEmpDataCont.employee.email,
        nationality: allEmpDataCont.employee.nationality,
        gender: allEmpDataCont.employee.gender,
        dob: allEmpDataCont.employee.dob,
        'job title': allEmpDataCont.employee['job title'],
        department: allEmpDataCont.employee.department,
        section: allEmpDataCont.employee.section,
        division: allEmpDataCont.employee.division,
        sector: allEmpDataCont.employee.sector,
        'date of joining': allEmpDataCont.employee['date of joining'],
        'years of experience': allEmpDataCont.employee['years of experience'],
        'role reporting to': allEmpDataCont.employee['role reporting to'],
        'reporting to you': allEmpDataCont.employee['reporting to you'],
        'potential score': allEmpDataCont.employee['potential score'],
        'performance technical score': allEmpDataCont.employee['performance technical score'],
        'performance appraisal score': allEmpDataCont.employee['performance appraisal score'],
        'line manager score': allEmpDataCont.employee['line manager score'],
        'employee score': allEmpDataCont.employee['employee score'],
        'talent pool': allEmpDataCont.employee['talent pool'],
        'last login': allEmpDataCont.employee['last login'],
        'function category': allEmpDataCont.employee['function category'],
        'test category': allEmpDataCont.employee['test category'],
        'assessment category': allEmpDataCont.employee['assessment category'],
        'job category': allEmpDataCont.employee['job category'],
        'job level': allEmpDataCont.employee['job level'],
      }

      setEmpData(employeeData)
    }
  }
  useEffect(() => {
    if (employeeQuery) {
      callEmpData(employeeQuery)
      callKSAData(employeeQuery)
    } else {
      callKSAData(user?.['user id'])
      callEmpData(user?.['user id'])
    }
  }, [employeeQuery])

  return (
    <div className={className}>
      {employeeKSA.length !== 0 ? (
        <div className='h-100 d-flex flex-row'>
          <div className={isCompact ? 'col-12 mt-0 h-100' : 'col-8 mb-5 h-100'}>
            <SkillsTable
              className='mb-5 mb-xxl-8 h-100'
              skills={employeeKSA}
              isCompact={isCompact}
              setSelectedIndex={setSelectedIndex}
              chartIndex='TS-Overall-Score-Chart'
              empFunc={callKSAData}
              reloadFuncProps={employeeQuery ? employeeQuery : user?.['user id']}
            />
          </div>
          {!isCompact && (
            <div className='col-4 mb-5 ms-2'>
              <div style={{position: 'sticky', top: '100px'}}>
                <SkillsDescription
                  className='mb-5 mb-xxl-1'
                  skill={employeeKSA[selectedIndex]}
                  selectedIndex={selectedIndex}
                />
              </div>
            </div>
          )}
        </div>
      ) : showLoading ? (
        <div className='d-flex flex-row h-100'>
          <div style={{height: '100%', width: '100%'}}>
            <div className={`card  mb-4 mb-xxl-8 h-100`}>
              <div className='card-header border-0 pt-5 '>
                <h3 className='card-title align-items-start flex-column'>
                  <span className='card-label fw-bold fs-3 mb-1'> Technical</span>
                </h3>
              </div>

              <div className='card-body py-3'>
                <div className='table-responsive'>
                  <div className='d-flex justify-content-center m-5 fw-bold fs-4 flex-column-fluid'>
                    There are no Technical available. Please go to the Assessments tab to see if you
                    have any pending assessments!
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className='d-flex flex-row h-100'>
          <div style={{height: '100%', width: '100%'}}>
            <div className={`card mb-4 mb-xxl-8 h-100`}>
              <div className='card-header border-0 pt-5 '>
                <h3 className='card-title align-items-start flex-column'>
                  <span className='card-label fw-bold fs-3 mb-1'>Technical</span>
                </h3>
              </div>
              <div className='card-body h-100 pt-2 pb-4 d-flex justify-content-center flex-column-fluid '>
                <span
                  className='spinner-border spinner-border-lg spinner-info'
                  style={{
                    width: '80px',
                    height: '80px',
                    border: ' var(--bs-spinner-border-width) solid #0550F0',
                    borderRightColor: 'var(--bs-light)',
                    borderWidth: '10px',
                  }}
                ></span>
              </div>
            </div>
          </div>
        </div>
      )}
      {!isCompact && (
        <div className='d-flex flex-row'>
          {empdata?.['performance appraisal score'] != null ? (
            <div style={{height: '100%', width: '66.5%'}}>
              <div className={`card  mb-4 mb-xxl-8 h-100`}>
                <div className='card-header border-0 pt-5 '>
                  <h3 className='card-title align-items-start flex-column'>
                    <span className='card-label fw-bold fs-3 mb-1'>Appraisal</span>
                  </h3>
                </div>
                <div className='d-flex justify-content-center align-content-center'>
                  <div className='card-body py-3 justify-content-center align-content-center'>
                    <div className='d-flex flex-row justify-content-center align-items-center w-75 me-2'>
                      <span className='fw-bold  w-25 fs-4'>Overall score</span>
                      <OverlayTrigger
                        placement='top'
                        overlay={skillsTooltip(
                          Math.round(empdata['performance appraisal score'] * 100),
                          'Overall',
                          OverallColor
                        )}
                      >
                        <div className=' progress-bar w-100 bg-light' role='progressbar'>
                          <div
                            className=' progress bg-appraisal'
                            style={{
                              width: `${
                                empdata['performance appraisal score'] * 100 <= threshold
                                  ? threshold
                                  : empdata['performance appraisal score'] * 100
                              }%`,
                            }}
                          ></div>
                        </div>
                      </OverlayTrigger>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : showLoading ? (
            <div className='d-flex flex-row h-100'>
              <div style={{height: '100%', width: '100%'}}>
                <div className={`card  mb-4 mb-xxl-8 h-100`}>
                  <div className='card-header border-0 pt-5 '>
                    <h3 className='card-title align-items-start flex-column'>
                      <span className='card-label fw-bold fs-3 mb-1'>Appraisal</span>
                    </h3>
                  </div>

                  <div className='card-body py-3'>
                    <div className='table-responsive'>
                      <div className='d-flex justify-content-center m-5 fw-bold fs-4 flex-column-fluid'>
                        There are no Appraisal available. Please go to the Assessments tab to see if
                        you have any pending assessments!
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className='d-flex flex-row h-100 w-100'>
              <div style={{height: '100%', width: '100%'}}>
                <div className={`card mb-4 mb-xxl-8 h-100`}>
                  <div className='card-header border-0 pt-5 '>
                    <h3 className='card-title align-items-start flex-column'>
                      <span className='card-label fw-bold fs-3 mb-1'>Appraisal</span>
                    </h3>
                  </div>
                  <div className='card-body h-100 pt-2 pb-4 d-flex justify-content-center flex-column-fluid '>
                    <span
                      className='spinner-border spinner-border-lg spinner-info'
                      style={{
                        width: '80px',
                        height: '80px',
                        border: ' var(--bs-spinner-border-width) solid #0550F0',
                        borderRightColor: 'var(--bs-light)',
                        borderWidth: '10px',
                      }}
                    ></span>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  )
}
