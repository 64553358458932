import {FC, useState, useEffect, useRef, useLayoutEffect} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../_metronic/layout/core'
import {
  DepartmentsTable,
  DynamicSlider,
  GradesBreakdownTable,
} from '../../_metronic/partials/widgets'
import {OverallEntGrade} from '../../_metronic/partials/widgets/_new/cards/OverallEntGrade'
import {EntityPendingInfoDonut} from '../../_metronic/partials/widgets/_new/cards/EntityPendingInfo'
import {OverallAssessments} from '../../_metronic/partials/widgets/_new/cards/OverallAssessments'
import {EntityTalentInfo} from '../../_metronic/partials/widgets/_new/cards/EntityTalentInfo'
import {TalentInfoTable} from '../../_metronic/partials/widgets/tables/TalentInfoTable'
import {EntAssessmentInfoTable} from '../../_metronic/partials/widgets/tables/EntAssessmentsInfoTable'
import {GradesBreakdown} from '../../_metronic/partials/widgets/lists/GradesBreakdown'
import {DashCard} from '../../_metronic/partials/widgets/_new/cards/DashCard'
import {PieChart} from '../../_metronic/partials/widgets/charts/PieChart'
import {LineChart} from '../../_metronic/partials/widgets/charts/LineChart'
import {BIEPchart} from '../../_metronic/partials/widgets/charts/BIEPchart'
import {Button, Col, Nav, TabContainer} from 'react-bootstrap'

import {EntBaseDashCard} from '../../_metronic/partials/widgets/_new/cards/EntBaseDashCard'
import {EntBIEPChart} from '../../_metronic/partials/widgets/charts/EntBIEPChart'
import {EntDeptDashCard} from '../../_metronic/partials/widgets/_new/cards/EntDeptDashCard'
import {YearOfExpChart} from '../../_metronic/partials/widgets/charts/YearOfExpChart'
import {DepartmentBreakdownChart} from '../../_metronic/partials/widgets/charts/DepartmentBreakdownChart'
import {EntMapCard} from '../../_metronic/partials/widgets/_new/cards/EntMapCard'

interface SliderItem {
  id: number
  value: number
  heading: string
}

const DashboardPage: FC = (props) => {
  const [dashboard1, setDashboard1] = useState(false)
  const [dashboard2, setDashboard2] = useState(true)
  const [dashboard3, setDashboard3] = useState(false)
  const [dashboard4, setDashboard4] = useState(false)
  const [refreshFlip, setRefreshFlip] = useState(false)

  const handleButtonClick = (buttonName: string) => {
    // console.log('Button Number ' + buttonName + ' clicked')

    switch (buttonName) {
      case '1':
        setDashboard1(true)
        setDashboard2(false)
        setDashboard3(false)
        setDashboard4(false)
        break
      case '2':
        setDashboard1(false)
        setDashboard2(true)
        setDashboard3(false)
        setDashboard4(false)
        break
      case '3':
        setDashboard1(false)
        setDashboard2(false)
        setDashboard3(true)
        setDashboard4(false)
        break
      case '4':
        setDashboard1(false)
        setDashboard2(false)
        setDashboard3(false)
        setDashboard4(true)
        break
    }
  }
  const setRefreshTrue = () => {
    setRefreshFlip(true)
    // console.log('refresh should be true now??')
  }

  useEffect(() => {
    if (refreshFlip == true) {
      // console.log('refresh then', refreshFlip)
      const timeoutId = setTimeout(() => {
        setRefreshFlip(false)
      }, 3500) //3.5 sec

      return () => clearTimeout(timeoutId)
    }

    // console.log('refresh now', refreshFlip)
  }, [refreshFlip])
  return (
    <div>
      {/* <div style={{height: '70px'}}>
        <Button className='mx-1' onClick={() => handleButtonClick('1')}>
          Original Dashboard
        </Button>
        <Button className='mx-1' onClick={() => handleButtonClick('2')}>
          New Dashboard
        </Button>
      </div> */}

      {dashboard1 && (
        <div>
          <div className='row g-2 mb-5' style={{height: '560px'}}>
            <div className='col-9 h-100'>
              <div className='row g-2 mb-5' style={{height: '150px'}}>
                <div className='col-4 h-100'>
                  <OverallEntGrade
                    className='h-md-3 h-100'
                    title='Average Grade'
                    chartSize={50}
                    chartLine={8}
                    chartRotate={0}
                  />
                </div>
                <div className='col-4 h-100'>
                  <EntityPendingInfoDonut
                    className='h-md-3 h-100'
                    subtitle=''
                    chartSize={50}
                    chartLine={8}
                    chartRotate={0}
                    color='white'
                  />
                </div>
                <div className='col-4 h-100'>
                  <OverallAssessments className='h-md-3 h-100' title='Overall Assessments' />
                </div>
              </div>
              <div className='row g-2 mb-5' style={{height: '400px'}}>
                <div className='col-8 h-100'>
                  <GradesBreakdownTable className='h-md-3 h-100 ' title='Score Weight Breakdown' />
                </div>
                <div className='col-4 g-2 h-100'>
                  <EntityTalentInfo className='h-md-3 h-100' subtitle='' color='white' />
                </div>
              </div>
            </div>
            <div className='col-3 h-100'>
              <GradesBreakdown title='Grades Breakdown' className='h-100' />
            </div>
          </div>
          <div className='row g-2 mb-5' style={{height: '400px'}}>
            <div className='col-4 h-100'>
              <DepartmentsTable className='h-md-3 h-100' title='Department Breakdown' />
            </div>
            <div className='col-4 h-100'>
              <EntAssessmentInfoTable
                className='card card-xl-stretch '
                title='Assessment Breakdown'
              />
            </div>
            <div className='col-4 h-100'>
              <TalentInfoTable className='card card-xl-stretch ' title='Talent Pool' />
            </div>
          </div>
        </div>
      )}

      {dashboard2 && (
        <div>
          <div className='row g-2 mb-5' style={{height: '560px'}}>
            <div className='col-8 g-2 h-100'>
              <div className='row g-2 mb-5' style={{height: '150px'}}>
                <div className='col-3 h-100'>
                  <EntBaseDashCard
                    className='h-100 m-0'
                    svgIcon='briefcase'
                    color=''
                    iconColor='dark'
                    title='entity alias'
                    titleColor='dark'
                    description='Entity Name'
                    descriptionColor='dark'
                    refreshBool={refreshFlip}
                  />
                </div>
                <div className='col-3 h-100'>
                  <EntBaseDashCard
                    className='h-100 m-0'
                    svgIcon='chart'
                    title='entity score'
                    titleColor='white'
                    titleType='num'
                    iconColor='white'
                    description='Average Score'
                    descriptionColor='white'
                    refreshBool={refreshFlip}
                  />
                </div>
                <div className='col-3 h-100'>
                  <EntBaseDashCard
                    className='h-100 m-0'
                    svgIcon='profile-user'
                    color=''
                    iconColor='dark'
                    title='number of employees'
                    titleColor='dark'
                    description='Total Users'
                    descriptionColor='dark'
                    refreshBool={refreshFlip}
                  />
                </div>

                <div className='col-3 h-100'>
                  <EntDeptDashCard
                    className='h-100 m-0'
                    svgIcon='data'
                    color=''
                    iconColor='dark'
                    title='number of departments'
                    titleColor='dark'
                    description='Number of Departments'
                    descriptionColor='dark'
                    refreshBool={refreshFlip}
                  />
                </div>
                {/* <div className='col-2 h-100'>
              <DashCard
                className='h-100 p-2 m-0'
                svgIcon='book'
                color=''
                iconColor='dark'
                title='40'
                titleColor='dark'
                description='Number of Assessments'
                descriptionColor='dark'
              />
            </div> */}
              </div>
              <div className='row g-2 mb-5' style={{height: '400px'}}>
                <div className='col-6 h-100'>
                  <EntBIEPChart
                    className='h-md-3 h-100'
                    title='Grades Scored'
                    description='Number of Candidates who have scored these grades'
                    refreshBool={refreshFlip}
                  />
                  {/* <BIEPchart  /> */}
                </div>
                <div className='col-6 h-100'>
                  <GradesBreakdownTable
                    className='h-md-3 h-100 '
                    title='Score Weight Breakdown'
                    setRefreshFlip={setRefreshTrue}
                    refreshBool={refreshFlip}
                  />
                  {/*  */}
                </div>
              </div>
            </div>
            <div className='col-4 g-2 h-100'>
              <EntMapCard title={'Employee Nationality Distribution'} />
            </div>
          </div>
          <div className='row g-2 mb-5' style={{height: '450px'}}>
            <div className='col-3 g-2 h-100'>
              <DepartmentBreakdownChart className='h-md-3 h-100' title='Department Breakdown' />
            </div>
            <div className='col-4 h-100'>
              <DepartmentsTable
                className='h-md-3 h-100'
                title='Department Score Breakdown'
                refreshBool={refreshFlip}
              />
            </div>
            <div className='col-5 h-100'>
              <YearOfExpChart className='h-md-3 h-100' title='Years of Experience' />
            </div>
          </div>
          <div className='row g-2 mb-5' style={{height: '400px'}}>
            <div className='col-5 h-100'>
              {/* <YearOfExpChart className='h-md-3 h-100' title='Years of Experience'/> */}
              <EntAssessmentInfoTable className='h-md-3 h-100 ' title='Assessment Breakdown' refreshBool={refreshFlip}/>
            </div>

            <div className='col-3 h-100'>
              <EntityTalentInfo
                className='h-md-3 h-100'
                subtitle=''
                color='white'
                setRefreshFlip={setRefreshTrue}
                refreshBool={refreshFlip}
              />
            </div>
            <div className='col-4 h-100'>
              <TalentInfoTable
                className='h-md-3 h-100 '
                title='Subject Matter Expert Pool'
                refreshBool={refreshFlip}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

const EntManagerDashboard: FC = () => {
  const intl = useIntl()

  return (
    <div>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'ENTITY.MANAGER.DASHBOARD'})}</PageTitle>
      <DashboardPage />
    </div>
  )
}

export {EntManagerDashboard}
