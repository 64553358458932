import {FC, useState, useEffect, useRef, useLayoutEffect} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../_metronic/layout/core'
import {
  DepartmentsTable,
  DynamicSlider,
  GradesBreakdownTable,
} from '../../_metronic/partials/widgets'
import {OverallEntGrade} from '../../_metronic/partials/widgets/_new/cards/OverallEntGrade'
import {EntityPendingInfoDonut} from '../../_metronic/partials/widgets/_new/cards/EntityPendingInfo'
import {OverallAssessments} from '../../_metronic/partials/widgets/_new/cards/OverallAssessments'
import {EntityTalentInfo} from '../../_metronic/partials/widgets/_new/cards/EntityTalentInfo'
import {TalentInfoTable} from '../../_metronic/partials/widgets/tables/TalentInfoTable'
import {EntAssessmentInfoTable} from '../../_metronic/partials/widgets/tables/EntAssessmentsInfoTable'
import {GradesBreakdown} from '../../_metronic/partials/widgets/lists/GradesBreakdown'
import {DashCard} from '../../_metronic/partials/widgets/_new/cards/DashCard'
import {PieChart} from '../../_metronic/partials/widgets/charts/PieChart'
import {LineChart} from '../../_metronic/partials/widgets/charts/LineChart'
import {BIEPchart} from '../../_metronic/partials/widgets/charts/BIEPchart'
import {Button, Col, Nav, TabContainer} from 'react-bootstrap'

import * as am5 from '@amcharts/amcharts5'
import * as am5map from '@amcharts/amcharts5/map'
import * as am5xy from '@amcharts/amcharts5/xy'
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated'
import am5geodata_worldLow from '@amcharts/amcharts5-geodata/worldLow'
import {EntBaseDashCard} from '../../_metronic/partials/widgets/_new/cards/EntBaseDashCard'
import {LeadershipBaseDashCard} from '../../_metronic/partials/widgets/_new/cards/LeadershipBaseDashCard'
import {EntitesListTable} from '../../_metronic/partials/widgets/tables/EntitesListTable'
import {LeadershipBIEPChart} from '../../_metronic/partials/widgets/charts/LeadershipBIEPChart'

interface SliderItem {
  id: number
  value: number
  heading: string
}

const DashboardPage: FC = (props) => {
  // useLayoutEffect(() => {
  //   var root = am5.Root.new('mapchartdiv')
  //   var chart = root.container.children.push(
  //     am5map.MapChart.new(root, {
  //       projection: am5map.geoMercator(),
  //       panX: 'rotateX',
  //       wheelY: 'zoom',
  //       minZoomLevel: 0.5,
  //       maxZoomLevel: 16,
  //     })
  //   )

  //   var polygonSeries = chart.series.push(
  //     am5map.MapPolygonSeries.new(root, {
  //       geoJSON: am5geodata_worldLow,
  //       exclude: ['AQ'],
  //     })
  //   )
  //   polygonSeries.mapPolygons.template.setAll({
  //     stroke: am5.color(0xffffff),
  //     templateField: 'polygonSettings',
  //     fillOpacity: 1,
  //     tooltipText: '{name}',
  //     interactive: true,
  //   })
  //   polygonSeries.mapPolygons.template.states.create('hover', {
  //     fill: am5.color('#abc123'),
  //   })

  //   polygonSeries.data.setAll([
  //     {
  //       id: 'US',
  //       polygonSettings: {
  //         fill: am5.color(0xff3c38),
  //       },
  //     },
  //     {
  //       id: 'CA',
  //       polygonSettings: {
  //         fill: am5.color(0xa23e48),
  //       },
  //     },
  //     {
  //       id: 'MX',
  //       polygonSettings: {
  //         fill: am5.color(0xff8c42),
  //       },
  //     },
  //   ])
  // }, [])

  return (
    <div>
      <div className='row g-2 mb-5' style={{height: '150px'}}>
        <div className='col-3 h-100'>
          <DashCard
            className='h-100 p-2 m-0'
            svgIcon='emoji-happy'
            color=''
            iconColor='dark'
            title='Welcome'
            titleColor='dark'
            description='To the Leadership Dashboard'
            descriptionColor='dark'
          />
        </div>
        <div className='col-3 h-100'>
          <LeadershipBaseDashCard
            className='h-100'
            svgIcon='chart'
            color=''
            iconColor='dark'
            title='average score'
            titleType='num'
            titleColor='dark'
            description='Average Score'
            descriptionColor='dark'
          />
        </div>
        <div className='col-3 h-100'>
          <LeadershipBaseDashCard
            className='h-100 p-2 m-0'
            svgIcon='briefcase'
            color=''
            iconColor='dark'
            title='number of entities'
            titleColor='dark'
            description='Number of Entities'
            descriptionColor='dark'
          />
        </div>
        <div className='col-3 h-100'>
          <LeadershipBaseDashCard
            className='h-100 p-2 m-0'
            svgIcon='profile-user'
            color=''
            iconColor='dark'
            title='number of employees'
            titleColor='dark'
            description='Number of Employees'
            descriptionColor='dark'
          />
        </div>

        {/* <div className='col-3 h-100'>
          <DashCard
            className='h-100 p-2 m-0'
            svgIcon='book'
            color=''
            iconColor='dark'
            title='40'
            titleColor='dark'
            description='Number of Assessments'
            descriptionColor='dark'
          />
        </div> */}
      </div>
      <div className='row g-2 mb-0' style={{height: '350px'}}>
        <div className='col-5 h-100'>
          <LeadershipBIEPChart
            className='h-md-3 h-100'
            title='Average Grades Across Entities'
            description='Number of Entities who fall within these grade categories'
          />
        </div>

        <div className='col-7 g-2 h-100'>
          <EntitesListTable className='h-md-3 h-100' title='Entities List' />
        </div>
      </div>
    </div>
  )
}

const LeadershipDashboard: FC = () => {
  const intl = useIntl()

  return (
    <div>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'ENTITY.MANAGER.DASHBOARD'})}</PageTitle>
      <DashboardPage />
    </div>
  )
}

export {LeadershipDashboard}
