/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useState} from 'react'
import {Link} from 'react-router-dom'

import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import config from '../../../../config.json'
import {UserModel} from '../../../../dataTypes'
import {Buffer} from 'buffer'

type Props = {
  setLoggedIn?: any
  user: UserModel
  imageProps?: string
}
const HeaderUserMenu: FC<Props> = ({setLoggedIn, user, imageProps}) => {
  // const { currentUser, logout } = useAuth();
  const currentUser = null // Set currentUser to null since useAuth is removed
  const logout = () => {}

  const logoutUser = async (e: any) => {
    const response = await fetch(config.domainName + ':' + config.port + '/api/logout', {
      method: 'GET',
      headers: {'Content-Type': 'application/json'},
      credentials: 'include',
    })

    const content = await response.json()
    //console.log(content)
    setLoggedIn(false)
  }
  let username: string[] = []
  if (user['user name'].includes('@')) {
    username = user['user name'].split('@')
  }

  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px'
      data-kt-menu='true'
    >
      <div className='menu-item px-3'>
        <div className='menu-content d-flex align-items-center px-3'>
          <div className='symbol symbol-50px me-5'>
            {/* <img alt='Logo' src={toAbsoluteUrl('/media/avatars/blank.png')} /> */}
            <img
              src={
                imageProps !== ''
                  ? toAbsoluteUrl(imageProps as string)
                  : toAbsoluteUrl('/media/avatars/blank.png')
              }
              // style={{cursor: 'pointer'}}
              alt='Logo'
            />
          </div>

          <div className='d-flex flex-column'>
            <div
              className='fw-bolder d-flex align-items-center fs-5'
              // style={{wordBreak: 'break-word'}}
            >
              {/* {currentUser?.first_name} {currentUser?.first_name} */}
              {/* {user['user name']} */}
              {user['user name'].includes('@')
                ? username[0] + '\n@' + username[1]
                : user['user name']}
            </div>
          </div>
        </div>
      </div>

      <div className='menu-item px-5'>
        <a onClick={logoutUser} className='menu-link px-5'>
          Sign Out
        </a>
      </div>
      {/* {user['user type'] == 'employee' && (
        <div className='menu-item px-5'>
          <a onClick={() => console.log('cahnge picture')} className='menu-link px-5'>
            Edit Profile Picture
          </a>
        </div>
      )} */}
    </div>
  )
}

export {HeaderUserMenu}
