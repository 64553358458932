import {useEffect, useState, FC} from 'react'
import {SkillsDescription} from '../../../../../_metronic/partials/widgets'
import config from '../../../../../config.json'
import {Skill} from '../../../../../dataTypes'
import {useUserContext} from '../../../../../UserContext'
import {SkillsTable} from '../../../../../_metronic/partials/widgets/tables/SkillsTable'
import {EmployeeDetails} from '../../../../../dataTypes'
import OverlayTrigger from 'react-bootstrap/esm/OverlayTrigger'
import {Tooltip} from 'react-bootstrap'

export const Aptitude: FC<{
  isCompact?: boolean
  employeeQuery?: string
  className?: string
}> = ({isCompact = false, employeeQuery, className}) => {
  const [employeeAptitudeArr, setEmployeeAptitudeArr] = useState<Skill[]>([])
  // const [employeeAptitude, setEmployeeAptitude] = useState<Skill[]>([])
  const [selectedIndex, setSelectedIndex] = useState(-1)
  const {user} = useUserContext()

  const [showLoading, setShowLoading] = useState(false) // State to control showing the warning message

  const warningTime = 5000

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowLoading(true)
    }, warningTime)
    return () => clearTimeout(timer)
  }, [])

  let OverallColor = '#ba403e'

  const threshold = 2

  const skillsTooltip = (score: number, title: string, color: string) => (
    <Tooltip id='skillsToolTip' className={` ${color}`} style={{borderRadius: '10px'}}>
      <span className='text-gray-800' style={{fontSize: '12px'}}>
        {title}: {Math.round(score)}%
      </span>
    </Tooltip>
  )
  async function callKSAData(employeeQuery: string | undefined) {
    if (employeeQuery) {
      // console.log("emp query yaar",employeeQuery)
      const empAptitude = await fetch(
        config.domainName + ':' + config.port + '/api/employeequery',
        {
          method: 'POST',
          headers: {'Content-Type': 'application/json'},
          body: JSON.stringify({
            function: 'GetAptitude',
            employee_id: employeeQuery,
          }),
        }
      )

      const empAptitudeCont = await empAptitude.json()
      // console.log('new aptitude', empAptitudeCont)
      const aptitudeArray: Skill[] = empAptitudeCont['aptitude skills'].map((skills: any) => ({
        title: skills['title'],
        score: skills['score'],
        grade: skills['grade'],
        color: skills['color'],
        description: skills['description'],
      }))
      setEmployeeAptitudeArr(aptitudeArray)
    }
  }

  useEffect(() => {
    if (employeeQuery) {
      callKSAData(employeeQuery)
    } else {
      callKSAData(user?.['user id'])
    }
  }, [employeeQuery])

  // useEffect(() => {
  //   const aptitudeArray: Skill[] = [
  //     {
  //       title: 'Data interpretation',
  //       score: 0.96,
  //       grade: 'proficient',
  //       color: '#66B32E',
  //       description:
  //         '• You can apply a modern standards approach throug…reate, test and document new or amended software.',
  //     },
  //     {
  //       title: 'Data interpretation',
  //       score: 0.69,
  //       grade: 'proficient',
  //       color: '#40519C',
  //       description:
  //         '• You can apply a modern standards approach throug…reate, test and document new or amended software.',
  //     },
  //     {
  //       title: 'Data interpretation',
  //       score: 0.42,
  //       grade: 'proficient',
  //       color: '#EDA500',
  //       description:
  //         '• You can apply a modern standards approach throug…reate, test and document new or amended software.',
  //     },
  //   ]
  //   setEmployeeAptitude(aptitudeArray)
  // }, [])

  return (
    <div className={className}>
      {employeeAptitudeArr.length !== 0 ? (
        <div className='h-100 d-flex flex-row'>
          <div className={isCompact ? 'col-12 mt-0 h-100' : 'col-8 mb-5 h-100'}>
            <SkillsTable
              className={isCompact ? 'my-b mb-xxl-8 h-100' : 'my-10 my-xxl-10 h-100'}
              skills={employeeAptitudeArr}
              isCompact={isCompact}
              title='Aptitude'
              setSelectedIndex={setSelectedIndex}
              chartIndex='TS-Aptitude-Score-Chart'
              // empFunc={callKSAData}
              reloadFuncProps={employeeQuery ? employeeQuery : user?.['user id']}
            />
          </div>
          {!isCompact && (
            <div className='col-4 mb-5 ms-2'>
              <div style={{position: 'sticky', top: '100px'}}>
                <SkillsDescription
                  className='mb-5 mb-xxl-1'
                  skill={employeeAptitudeArr[selectedIndex]}
                  selectedIndex={selectedIndex}
                />
              </div>
            </div>
          )}
        </div>
      ) : showLoading ? (
        <div className='d-flex flex-row h-100'>
          <div style={{height: '100%', width: '100%'}}>
            <div className={`card  mb-4 mb-xxl-8 h-100`}>
              <div className='card-header border-0 pt-5 '>
                <h3 className='card-title align-items-start flex-column'>
                  <span className='card-label fw-bold fs-3 mb-1'>Aptitude</span>
                </h3>
              </div>

              <div className='card-body py-3'>
                <div className='table-responsive'>
                  <div className='d-flex justify-content-center m-5 fw-bold fs-4 flex-column-fluid'>
                    There are no Aptitude available. Please go to the Assessments tab to see if you
                    have any pending assessments!
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className='d-flex flex-row h-100'>
          <div style={{height: '100%', width: '100%'}}>
            <div className={`card mb-4 mb-xxl-8 h-100`}>
              <div className='card-header border-0 pt-5 '>
                <h3 className='card-title align-items-start flex-column'>
                  <span className='card-label fw-bold fs-3 mb-1'>Aptitude</span>
                </h3>
              </div>
              <div className='card-body h-100 pt-2 pb-4 d-flex justify-content-center flex-column-fluid '>
                <span
                  className='spinner-border spinner-border-lg spinner-info'
                  style={{
                    width: '80px',
                    height: '80px',
                    border: ' var(--bs-spinner-border-width) solid #0550F0',
                    borderRightColor: 'var(--bs-light)',
                    borderWidth: '10px',
                  }}
                ></span>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
