/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState} from 'react'
import {Button, Form, InputGroup} from 'react-bootstrap'
import * as XLSX from 'xlsx'

type Props = {
  className?: string
  title?: string
  subtitle?: string
}
const ImportExcelButtonCard: React.FC<Props> = ({
  className,
  title = 'Import Excel Button Card',
  subtitle = 'sample text for import excel button card',
}) => {
  const handleSubmit = (event: any) => {
    const form = event.currentTarget
    event.preventDefault()
    if (form.checkValidity() === false) {
      event.preventDefault()
      event.stopPropagation()
    }

    setValidated(true)
    convertExcelToJson()
  }

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    // Update the selected file when the input changes
    if (e.target.files && e.target.files.length > 0) {
      setSelectedFile(e.target.files[0])
    }
  }

  const [selectTableValue, setSelectTableValue] = useState('')
  const [selectedFile, setSelectedFile] = useState<File | ''>('')
  const [validated, setValidated] = useState(false)

  function convertExcelToJson() {
    if (selectedFile) {
      let reader = new FileReader()

      reader.onload = function (e) {
        let data = new Uint8Array(e.target?.result as ArrayBuffer)
        let workbook = XLSX.read(data, {type: 'array'})
        // find the name of your sheet in the workbook first
        let worksheet = workbook.Sheets[selectTableValue] //sheet name

        // convert to json format
        const jsonData = XLSX.utils.sheet_to_json(worksheet)
        console.log(jsonData)
      }
      reader.readAsArrayBuffer(selectedFile)
    }
  }

  return (
    <div className={`card ${className} overflow-scroll`}>
      <div className='card-header border-0 pt-4'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-0'>{title}</span>
        </h3>
      </div>
      <div className='card-body py-2'>
        <div className='pb-5'>{subtitle}</div>
        <br />
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
          <Form.Group controlId='formFile' className='mb-3'>
            <InputGroup hasValidation>
              <Form.Control
                type='file'
                defaultValue=''
                accept='.xlsx'
                onChange={handleFileChange}
                required
              />
              <Form.Control.Feedback type='invalid'>
                Please select a xslx file.
              </Form.Control.Feedback>
            </InputGroup>
          </Form.Group>
          <Form.Group>
            <InputGroup hasValidation>
              <Form.Select
                aria-label='Table select'
                aria-describedby='tableHelpBlock'
                onChange={(e) => setSelectTableValue(e.target.value)}
                required
              >
                <option value=''>Select table</option>
                <option value='training_needs'>training_needs</option>
                <option value='employees'>employees</option>
                <option value='competencies'>competencies</option>
              </Form.Select>
              <Form.Text id='tableHelpBlock' muted>
                This is the name of the sheet in the excel file.
              </Form.Text>
              <Form.Control.Feedback type='invalid'>Please select an option.</Form.Control.Feedback>
            </InputGroup>
          </Form.Group>
          <br />
          <Button variant='primary' type='submit'>
            Import
          </Button>
        </Form>
      </div>
    </div>
  )
}

export {ImportExcelButtonCard}
