import {useIntl} from 'react-intl'
import {MenuItem} from './MenuItem'
import {MenuInnerWithSub} from './MenuInnerWithSub'
import {MegaMenu} from './MegaMenu'

export function MenuInnerUser() {
  const intl = useIntl()
  return (
    <>
      <MenuItem to='/profile/overview' title='Dashboard' />
    </>
  )
}
