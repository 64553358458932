import {useIntl} from 'react-intl'
import {MenuItem} from './MenuItem'
import {MenuInnerWithSub} from './MenuInnerWithSub'
import {MegaMenu} from './MegaMenu'
let index = 2 //0 is Admin , 1 is Leadership Report , 2 is entity , 3 is user
export function MenuInnerEntityManager() {
  const intl = useIntl()
  return (
    <>
      <MenuItem title='Dashboard' to='/dashboard' />
      <MenuItem title='Candidate Overview' to='candidate-overview' />
      <MenuItem title='Candidate Profile' to='candidate-profile' />
      {/* <MenuItem title='Candidate Training' to='candidate-training' /> */}
      <MenuItem title='Candidate Competencies' to='candidate-competencies' />
      {/* <MenuItem title='Training Needs' to='training-needs' /> */}
      {/* <MenuItem title='Competencies' to='/user-management/employee-competencies' /> */}
    </>
  )
}
