/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useRef, useState} from 'react'

import config from '../../../../config.json'
import {useUserContext} from '../../../../UserContext'
import {LineChart} from './LineChart'

type Props = {
  className?: string
  title?: string
  description?: string
}

const YearOfExpChart: React.FC<Props> = ({className, title, description}) => {
  const {user} = useUserContext()
  const [chartData, setChartData] = useState<[]>([])

  async function callEmpData() {
    if (user?.['user id']) {
      const allEmpDataSet = await fetch(config.domainName + ':' + config.port + '/api/employee', {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({
          entity_id: user['user id'],
        }),
      })

      const allEmpDataCont = await allEmpDataSet.json()

      const employeeData = allEmpDataCont.employees.map((emp: any) => {
        if (emp['employee score'] !== '-') {
          const empScorePercentage = Math.round(emp['employee score'] * 100)
          return {...emp, 'employee score': empScorePercentage}
        } else {
          return {...emp, 'employee score': 0}
        }
      })
      const dummyData = employeeData.map((employee: any) => employee['years of experience'])
      setChartData(dummyData)
    }
  }

  useEffect(() => {
    callEmpData()
  }, [])

  const handleRefresh = () => {
    callEmpData()
  }
  return (
    <div className={`card ${className}`}>
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>{title}</span>

          <span className='text-muted fw-semibold fs-7'>{description}</span>
        </h3>
        <button className=' btn btn-primary btn-icon ' onClick={handleRefresh}>
          <i className='fa-solid fa-arrows-rotate'></i>
        </button>
      </div>
      <div className='card-body'>
        <>
          <LineChart
            className='h-md-3 h-100'
            yaxisText='Number of Candidates'
            xaxisText='Years of Experience'
            chartData={chartData}
            colNumber={10}
          />
        </>
      </div>
    </div>
  )
}

export {YearOfExpChart}
