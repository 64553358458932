import {Navigate, Routes, Route, Outlet, useLocation} from 'react-router-dom'
import {useParams} from 'react-router'
import {PageLink, PageTitle} from '../../../../_metronic/layout/core'
import {Overview} from '../components/Overview'
import {ProfileHeader} from '../client_profile/ProfileHeader'
import {useState, FC, useEffect} from 'react'
import {PotentialSkills} from '../components/employee_components/Potenital'
import {TrainingPaths} from '../components/employee_components/TrainingPaths'

interface ProfilePageLocationState {
  employeeQuery?: string
}

interface ProfilePageLocation {
  state?: ProfilePageLocationState
}

const profileBreadCrumbs: Array<PageLink> = [
  {
    title: 'Training',
    path: '/user-management/overall-employee/candidate-training',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const ProfilePageTraining_Ent: FC<{
  employeeQuery?: string
  onSelectSkillNames?: (selectedNames: string[]) => void
  onPrevSelected?: (prevSelected: string[]) => void
  onPotentialEmpty?: (potEmpty: boolean) => void
  selectedSkillNames?: string[]
}> = ({
  employeeQuery,
  onSelectSkillNames,
  onPrevSelected,
  selectedSkillNames: propSelectedSkillNames,
  onPotentialEmpty,
}) => {
  const location = useLocation()
  const [selectedSkillNames, setSelectedSkillNames] = useState<string[]>([])
  const [prevSelectedSkillNames, setPrevSelectedSkillNames] = useState<string[]>([])
  const [potentialEmpty, setPotentialEmpty] = useState(false)

  const handleSelectSkillNames = (selectedNames: string[]) => {
    setSelectedSkillNames(selectedNames)
    if (onSelectSkillNames) {
      onSelectSkillNames(selectedNames)
    }
  }

  const handlePotentialEmpty = (potEmpty: boolean) => {
    setPotentialEmpty(potEmpty)
    if (onPotentialEmpty) {
      onPotentialEmpty(potEmpty)
    }
  }
  const handlePrevSelected = (prevSelected: string[]) => {
    setPrevSelectedSkillNames(prevSelected)
    if (onPrevSelected) {
      onPrevSelected(prevSelected)
    }
    // console.log('prev selected in profile page training', prevSelected)
  }

  useEffect(() => {}, [selectedSkillNames])
  useEffect(() => {}, [prevSelectedSkillNames])
  useEffect(() => {}, [potentialEmpty])
  // console.log('selectedemp', employeeQuery)

  return (
    <Routes>
      <Route
        path='/'
        element={
          <>
            <ProfileHeader
              employeeQuery={employeeQuery}
              basePath={profileBreadCrumbs[0].path + location.search}
            />
            <Outlet />
          </>
        }
      >
        <Route
          path='/'
          element={
            <>
              <PageTitle breadcrumbs={profileBreadCrumbs}>Candidate Aptitude</PageTitle>
              <div className='row g-2 g-xxl-2' style={{height: '500px', marginBottom: '5rem'}}>
                <div className='col-xl-12 h-100'>
                  {employeeQuery && (
                    <PotentialSkills
                      isCompact={true}
                      canselect={true}
                      employeeQuery={employeeQuery}
                      selectedSkillNames={selectedSkillNames}
                      onSelectSkillNames={handleSelectSkillNames}
                      potentialEmpty={potentialEmpty}
                      onPotentialEmpty={handlePotentialEmpty}
                      prevSelectedSkillNames={prevSelectedSkillNames}
                    />
                  )}
                </div>
              </div>
              <Outlet />
            </>
          }
        >
          <Route
            path='/'
            element={
              <>
                <PageTitle breadcrumbs={profileBreadCrumbs}>Candidate Training Path</PageTitle>
                {employeeQuery && (
                  <TrainingPaths
                    isCompact={true}
                    employeeQuery={employeeQuery}
                    selectedSkillNames={selectedSkillNames}
                    onSelectSkillNames={handleSelectSkillNames}
                    minus={true}
                    callFunc='CalculateEmployeesTraining'
                    potentialEmpty={potentialEmpty}
                    prevSelectedSkillNamesCont={prevSelectedSkillNames}
                    onPrevSelectSkillNames={handlePrevSelected}
                  />
                )}
              </>
            }
          />
        </Route>
      </Route>
    </Routes>
  )
}

export default ProfilePageTraining_Ent
