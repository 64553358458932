/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect, useState, FC} from 'react'
import config from '../../../../config.json'
import {useUserContext} from '../../../../UserContext'
import WarningSpinner from './WarningSpinner'
import {useCallInterval} from '../../../../CallTimeIntervalContext'

type Employee = {
  name: string
  totalScore: number
  technicalScore: number
  potentialScore: number
  appraisalScore: number
  lmScore: number
  potentialRatio: number
  technicalRatio: number
  appraisalRatio: number
  lmRatio: number
  'employee id': string
}

export const ScoreBreakdownTable: FC<{
  className?: string
  isCompact?: boolean
  employeeQuery?: string
}> = ({className = '', employeeQuery, isCompact = false}) => {
  const [empData, setEmpData] = useState<Employee>()

  const {user} = useUserContext()
  const {callInterval} = useCallInterval()

  async function callEmpData(employeeQuery: string | undefined) {
    if (employeeQuery) {
      const allEmpData = await fetch(config.domainName + ':' + config.port + '/api/employeequery', {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({
          function: 'AllEmployeeData',
          employee_id: employeeQuery,
        }),
      })

      const allEmpDataCont = await allEmpData.json()
      const dummyData: Employee = {
        name: allEmpDataCont.employee.name,

        totalScore: allEmpDataCont.employee['employee score'],
        technicalScore: allEmpDataCont.employee['performance technical score'],
        potentialScore: allEmpDataCont.employee['aptitude score'],
        appraisalScore: allEmpDataCont.employee['performance appraisal score'],
        lmScore: allEmpDataCont.employee['line manager score'],
        'employee id': allEmpDataCont.employee['employee id'],
        potentialRatio: allEmpDataCont['employee ratios']['aptitude skill ratio'] * 100,
        appraisalRatio: allEmpDataCont['employee ratios']['appraisal skill ratio'] * 100,
        lmRatio: allEmpDataCont['employee ratios']['line manager skill ratio'] * 100,
        technicalRatio: allEmpDataCont['employee ratios']['technical skill ratio'] * 100,
      }

      const totalScoreChartData = [
        {
          name: 'Aptitude',
          data: [Math.round(dummyData.potentialScore * dummyData.potentialRatio)],
          color: '#0eb48d',
        },
        {
          name: 'Technical',
          data: [Math.round(dummyData.technicalScore * dummyData.technicalRatio)],
          color: '#2196F3',
        },
        {
          name: 'Appraisal',
          data: [Math.round(dummyData.appraisalScore * dummyData.appraisalRatio)],
          color: '#0eb48d',
        },
        {
          name: 'Line Manager',
          data: [Math.round(dummyData.lmScore * dummyData.lmScore)],
          color: '#2196F3',
        },
      ]

      setEmpData(dummyData)
    }
  }

  useEffect(() => {
    if (employeeQuery) {
      callEmpData(employeeQuery)
    } else {
      callEmpData(user?.['user id'])
    }
  }, [employeeQuery])

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     if (employeeQuery) {
  //       callEmpData(employeeQuery)
  //     } else {
  //       callEmpData(user?.['user id'])
  //     }
  //   }, callInterval)

  //   return () => clearInterval(interval)
  // }, [employeeQuery])

  return (
    <div className={`card ${className} `}>
      <div className='d-flex flex-column mt-3'>
        <div className='card-header border-0 pt-0 '>
          <h3 className='card-title d-flex  flex-row'>
            <span className='card-label fw-bold fs-3 mb-1'>Score Breakdown</span>
            {/* <span className='fw-bolder fs-6 flex-end'>{completionPercentage.toFixed(0)}%</span> */}
          </h3>
        </div>
        {empData ? (
          <div className='card-body py-2'>
            <div className='table-responsive'>
              <table className='table table-row-dashed table-row-gray-300 align-middle  gs-4 gy-3'>
                <tbody>
                  {empData.potentialRatio != 0 && (
                    <tr>
                      <td className='text-gray-700  fw-bold text-hover-primary mb-0 fs-6'>
                        Aptitude
                      </td>
                      <td className='text-gray-700  text-hover-primary mb-0 fs-6'>
                        {Math.round(empData.potentialScore * empData.potentialRatio)}
                        {' / '}
                        {Math.round(empData.potentialRatio)}
                      </td>
                    </tr>
                  )}
                  {empData.technicalRatio != 0 && (
                    <tr>
                      <td className='text-gray-700 fw-bold text-hover-primary mb-0 fs-6'>
                        Technical
                      </td>
                      <td className='text-gray-700  text-hover-primary mb-0 fs-6'>
                        {Math.round(empData.technicalScore * empData.technicalRatio)}
                        {' / '}
                        {Math.round(empData.technicalRatio)}
                      </td>
                    </tr>
                  )}
                  {empData.appraisalRatio != 0 && (
                    <tr>
                      <td className='text-gray-700 fw-bold text-hover-primary mb-0 fs-6'>
                        Appraisal
                      </td>
                      <td className='text-gray-700  text-hover-primary mb-0 fs-6'>
                        {Math.round(empData.appraisalScore * empData.appraisalRatio)}
                        {' / '}
                        {Math.round(empData.appraisalRatio)}
                      </td>
                    </tr>
                  )}
                  {empData.lmRatio != 0 && (
                    <tr>
                      <td className='text-gray-700 fw-bold text-hover-primary mb-0 fs-6'>
                        Line Manager
                      </td>
                      <td className='text-gray-700  text-hover-primary mb-0 fs-6'>
                        {Math.round(empData.lmScore * empData.lmRatio)}
                        {' / '}
                        {Math.round(empData.lmRatio)}
                      </td>
                    </tr>
                  )}

                  <tr className='align-bottom'>
                    <td className='text-dark fw-bold text-hover-primary mb-0 fs-4'>Total Score</td>
                    <td className='text-dark fw-bold text-hover-primary mb-0 fs-4'>
                      {Math.round(empData.totalScore * 100)}%
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        ) : (
          <WarningSpinner
            spinnerWidth='100px'
            canReload={true}
            reloadFunc={callEmpData}
            reloadFuncProps={employeeQuery ? employeeQuery : user?.['user id']}
          />
        )}
      </div>
    </div>
  )
}
