/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useRef, useState} from 'react'

import config from '../../../../config.json'
import {useUserContext} from '../../../../UserContext'
import {PieChart} from './PieChart'

type Props = {
  className?: string
  title?: string
  description?: string
}

const DepartmentBreakdownChart: React.FC<Props> = ({className, title, description}) => {
  const {user} = useUserContext()
  const [seriesData, setSeriesData] = useState<[]>([])
  const [labelData, setLabelData] = useState<[]>([])
  const [entityDepartmentInfo, setEntityDepartmentInfo] = useState<string[][]>()

  async function callEntDepartments() {
    if (user?.['user id']) {
      const entDepartment = await fetch(
        config.domainName + ':' + config.port + '/api/entityquery',
        {
          method: 'POST',
          headers: {'Content-Type': 'application/json'},
          body: JSON.stringify({
            function: 'EntityDepartmentInformation',
            entity_id: user['user id'],
          }),
        }
      )
      const entDepartmentCont = await entDepartment.json()

      // Assuming you have a string representation of this array
      const stringifiedData = JSON.stringify(entDepartmentCont['department information'])

      // Parse the stringified data into an array of objects
      const parsedData = JSON.parse(stringifiedData)

      // Extract the 'candidates' property as an array of numbers
      const candidates = parsedData.map((obj: {candidates: number}) => obj.candidates)
      const departments = parsedData.map((obj: {department: string}) => obj.department)

      setEntityDepartmentInfo(entDepartmentCont['department information'])
      setSeriesData(candidates)
      setLabelData(departments)
    }
  }

  useEffect(() => {
    callEntDepartments()
  }, [])

  const handleRefresh = () => {
    callEntDepartments()
  }
  return (
    <div className={`card ${className}`}>
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>{title}</span>

          <span className='text-muted fw-semibold fs-7'>{description}</span>
        </h3>
        {/* for refresh  */}
        {/* <button className=' btn btn-primary btn-icon ' onClick={handleRefresh}>
          <i className='fa-solid fa-arrows-rotate'></i>
        </button> */}
      </div>
      <div className='card-body'>
        <PieChart
          className='h-100 p-2 m-0'
          title='Department Breakdown'
          series={seriesData}
          labels={labelData}
          isCompact={true}
        />
      </div>
    </div>
  )
}

export {DepartmentBreakdownChart}
