/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useRef, useState} from 'react'
import ApexCharts, {ApexOptions, getChartByID} from 'apexcharts'
import {getCSSVariableValue} from '../../../assets/ts/_utils'
import {useThemeMode} from '../../layout/theme-mode/ThemeModeProvider'

type Props = {
  className: string
  // employeeQuery?: string
  isCompact?: boolean
  title?: string
  setSelectedIndex?: any
  series?: []
  labels?: []
  reloadFunc?: (params: any) => any
}

const PieChart: React.FC<Props> = ({className, isCompact = false, title, series, labels}) => {
  const [barHeight, setBarHeight] = useState(600)
  const chartRef = useRef<HTMLDivElement | null>(null)

  const {mode} = useThemeMode()
  const [chartBase64, setChartBase64] = useState('')

  // Helper function to clean up color values kept it cuz might be useful
  function cleanColor(color: any) {
    // remove any leading or trailing whitespace
    color = color.trim()
    // remove any leading # characters
    color = color.replace(/^#+/, '')
    // ensure the color string is exactly 6 characters long
    if (color.length < 6) {
      color = color.padStart(6, '0')
    } else if (color.length > 6) {
      color = color.slice(0, 6)
    }
    // add a leading # character
    color = '#' + color
    return color
  }

  useEffect(() => {
    const chart = refreshChart()
    //idk what barCheck or barHeight is doing, but i just know that something about it is making the chart work so i am just keeping it for now
    var barCheck
    if (document.getElementById('chart-container')) {
      barCheck = document.getElementById('chart-container')?.clientHeight
      barCheck = barCheck ? barCheck - 50 : barCheck
    }

    if (barCheck == undefined || barCheck == null || barCheck < 120) {
      barCheck = 120
    }

    setBarHeight(barCheck)
    getbase64chart(chart)

    return () => {
      if (chart) {
        chart.destroy()
      }
    }
  }, [chartRef, mode, barHeight, series, labels])

  async function getbase64chart(chart: any) {
    const base64 = await chart?.dataURI()
    setChartBase64(base64)
  }

  const refreshChart = () => {
    if (!chartRef.current) {
      return
    }

    const chart =
      series !== undefined &&
      labels !== undefined &&
      new ApexCharts(chartRef.current, getChartOptions(barHeight, series, labels))

    if (chart) {
      chart.render()
    }
    return chart

    const NO_VALUE_MIN_WIDTH = 1 // to display something even when value is falsy
    const MIN_WIDTH = 50
  }

  return (
    <div className={`card ${className}`}>
      <div
        className='card-body d-flex justify-content-center align-items-center align-content-center h-100 p-0 m-0'
        id='chart-container'
      >
        <div style={{height: '100% ', width: '95%'}} ref={chartRef} id='total-score-chart' />
      </div>
    </div>
  )
}

export {PieChart}

function getChartOptions(
  height: number,

  series: [],
  labels: []
): ApexOptions {
  const borderColor = getCSSVariableValue('--bs-gray-200')

  return {
    series: series,
    labels: labels,
    //custom colors
    // colors:["#c42b21","#c42b21","#6610f2","#c42b21","#0dcaf0",],
    chart: {
      fontFamily: 'inherit',
      type: 'donut',
      height: height,
      width: '100%',
      // stacked: true,
      // toolbar: {
      //   show: false,
      // },
    },
    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: true,
            name: {
              show: true,
            },
            total: {
              show: true,
              showAlways: true,
              label: 'Total Users',
              fontSize: '1rem',
            },
          },
        },
      },
    },

    // dataLabels: {
    //     enabled:true,
    //       formatter: function (value, {seriesIndex, dataPointIndex}) {
    //     value = Math.round(scores[seriesIndex] *100)
    //     return 'Score: ' + value + '%'
    //   },
    //   },
    dataLabels: {
      enabled: true,
      formatter: function (value, {seriesIndex, dataPointIndex}) {
        value = series[seriesIndex]
        return ' ' + value + ' '
      },
    },
    legend: {
      show: true,
      position: 'bottom',
      horizontalAlign: 'center',
    },
  }
}
