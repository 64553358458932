import React, {useState, useEffect, useRef} from 'react'
import {EmpSkillsInfo, EmployeeDetails} from '../../../../dataTypes'
import 'bootstrap/dist/js/bootstrap.bundle'
import {Form, Nav, OverlayTrigger, Tab, Tooltip} from 'react-bootstrap'

import config from '../../../../config.json'
import {useUserContext} from '../../../../UserContext'
import {Link, Route, Routes, useLocation} from 'react-router-dom'
import Select, {ActionMeta, OnChangeValue, StylesConfig} from 'react-select'

import {
  Skill,
  PotentialSkillGroup,
  EmpPotentialSkillsInfo,
  EmpTechSkillsInfo,
} from '../../../../dataTypes'

interface ProfilePageLocationState {
  employeeQuery?: string
}

interface ProfilePageLocation extends Location {
  state?: ProfilePageLocationState
}

type Employee = {
  name: string
  position: string
  division: string
  department: string
  section: string
  score: number
  technicalScore: number
  PotentialSkillsScore: number
  PotentialSkillsRatio: number
  techSkillsRatio: number
  'employee id': string
}
type Technical = {
  Category: string
  Weight: number
  Score: number
}
type Props = {
  className: string
}

const UserListCompetencies: React.FC<Props> = ({className}) => {
  //#region Variables
  const [originalData, setOriginalData] = useState<Employee[]>([])
  const [filteredData, setFilteredData] = useState<Employee[]>([])
  const [searchQuery, setSearchQuery] = useState('')
  const [isSearching, setIsSearching] = useState(false)

  const [filterOptions, setFilterOptions] = useState<{[key: string]: string}>({
    position: '',
    section: '',
    department: '',
    division: '',
    grade: '',
    techComp: '',
  })

  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('desc')
  const [sortField, setSortField] = useState<string>('avgscore')

  const [role, setRole] = useState<string | undefined>()
  const [gradeBracket, setGradeBracket] = useState<number | undefined>()
  const [employeeData, setEmployeeData] = useState<EmployeeDetails[]>([])

  const [employeeSkills, setEmployeeSkills] = useState<PotentialSkillGroup[]>([])

  const [allSkillRows, setAllSkillRows] = useState<string[]>([])
  const [filteredAllSkillRows, setFilteredAllSkillRows] = useState<string[]>([]) //contains all skills

  const [techRows, setTechRows] = useState([])
  const [filteredTechRows, setFilteredTechRows] = useState([]) //contains all technical skills
  const [aptitudeRows, setAptitudeRows] = useState([])
  const [filteredAptitudeRows, setFilteredAptitudeRows] = useState([]) //contains all technical skills

  const [potentialRows, setPotentialRows] = useState([])
  const [filteredPotentialRows, setFilteredPotentialRows] = useState([]) //contains all 3 Achievements , future outlook , Leadership spirit
  const [techMultiSelect, setTechMultiSelect] = useState<{label: string; value: number}[]>([])
  const [allMultiSelect, setAllMultiSelect] = useState<{label: string; value: number}[]>([])
  const [potentialMultiSelect, setPotentialMultiSelect] = useState<
    {label: string; value: number}[]
  >([])

  const [allSelectedList, setAllSelectedList] = useState<{name: string; id: number}[]>([])
  const [allSkillsEmp, setAllSkillsEmp] = useState<EmpSkillsInfo[]>([])
  const [filteredallSkillsEmp, setFilteredAllSkillsEmp] = useState<EmpSkillsInfo[]>([]) //contains all skills for employees

  const [techSelectedList, setTechSelectedList] = useState<{name: string; id: number}[]>([])
  const [techSkillsEmp, setTechSkillsEmp] = useState<EmpTechSkillsInfo[]>([])
  const [filteredTechSkillsEmp, setFilteredTechSkillsEmp] = useState<EmpTechSkillsInfo[]>([])

  const [potentialSkillsEmp, setPotentialSkillsEmp] = useState<EmpPotentialSkillsInfo[]>([])
  const [filteredPotentialSkillsEmp, setFilteredPotentialSkillsEmp] = useState<
    EmpPotentialSkillsInfo[]
  >([])

  const [techRangeSliders, setTechRangeSliders] = useState<number[]>([])
  const [testCount, setTestCount] = useState<number>(5)
  const [allRangeSliders, setAllRangeSliders] = useState<{name: string; value: string}[]>([])

  const [allSkillsRangeInput, setAllSkillsRangeInput] = useState<number>(0)
  const [techSkillsRangeInput, setTechSkillsRangeInput] = useState<number>(0)
  const [potentialSkillsRangeInput, setPotentialSkillsRangeInput] = useState<number[]>([])

  const [entityGradeInfo, setEntityGradeInfo] = useState<string[][]>()
  const [getTechnical, setGetTechnical] = useState<Technical>()
  const [entityGradeInfoContainer, setEntityGradeInfoContainer] = useState<string[][]>()

  const [rangeSliders, setRangeSliders] = useState<number[]>([])

  const location = useLocation()

  var filteredTechGradeList = document.getElementById('filteredTechGradeList')
  var filteredAllGradeList = document.getElementById('filteredAllGradeList')

  const {user} = useUserContext()
  //#endregion

  async function callEmpData() {
    if (user?.['user id']) {
      const empKSAs = await fetch(config.domainName + ':' + config.port + '/api/entityquery', {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({
          function: 'EntityEmployeesTechnicalKSA',
          entity_id: user['user id'],
        }),
      })
      const empKSANames = await fetch(config.domainName + ':' + config.port + '/api/entityquery', {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({
          function: 'EntityEmployeesTechnicalKSANames',
          entity_id: user['user id'],
        }),
      })

      const empKSACont = await empKSAs.json()
      const empKSANamesCont = await empKSANames.json()
      console.log('empKSA', empKSACont)
      console.log('empKSANames', empKSANamesCont)
      console.log('empKSA', empKSAs)
      console.log('empKSANames', empKSANames)

      const empAptitudeKSA = await fetch(
        config.domainName + ':' + config.port + '/api/entityquery',
        {
          method: 'POST',
          headers: {'Content-Type': 'application/json'},
          body: JSON.stringify({
            function: 'EntityEmployeesAptitudeKSA',
            entity_id: user['user id'],
          }),
        }
      )
      console.log('user', user['user id'])
      const empAptitudeKSANames = await fetch(
        config.domainName + ':' + config.port + '/api/entityquery',
        {
          method: 'POST',
          headers: {'Content-Type': 'application/json'},
          body: JSON.stringify({
            function: 'EntityEmployeesAptitudeKSANames',
            entity_id: user['user id'],
          }),
        }
      )

      const empAptitudeKSACont = await empAptitudeKSA.json()
      const empAptitudeKSANamesCont = await empAptitudeKSANames.json()
      console.log('empAptitudeKSA', empAptitudeKSACont)
      console.log('empAptitudeKSANames', empAptitudeKSANamesCont)
      console.log('empAptitudeKSA eh', empAptitudeKSA)
      console.log('empAptitudeKSANames eh', empAptitudeKSANames)

      // const empPotentialSkills = await fetch(
      //   config.domainName + ':' + config.port + '/api/entityquery',
      //   {
      //     method: 'POST',
      //     headers: {'Content-Type': 'application/json'},
      //     body: JSON.stringify({
      //       function: 'AllEntityPotentialSkills',
      //       entity_id: user['user id'],
      //     }),
      //   }
      // )
      // const empPotentialSkillNames = await fetch(
      //   config.domainName + ':' + config.port + '/api/entityquery',
      //   {
      //     method: 'POST',
      //     headers: {'Content-Type': 'application/json'},
      //     body: JSON.stringify({
      //       function: 'EntityEmployeesPotentialSkillKSANames',
      //       entity_id: user['user id'],
      //     }),
      //   }
      // )

      // console.log('empPotentialSkillNames', empPotentialSkillNames)

      const gradeInfo = await fetch(config.domainName + ':' + config.port + '/api/entityquery', {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({
          function: 'EntityGradeRatio',
          entity_id: user['user id'],
        }),
      })

      const gradeInfoCont = await gradeInfo.json()
      setEntityGradeInfo(gradeInfoCont['entity score'])
      const entityGradeInfoCont = gradeInfoCont['entity score'].filter((v: any) => {
        return v['Ratio'] !== 0
      })
      setEntityGradeInfoContainer(entityGradeInfoCont)
      const techRatioData: Technical = {
        Category: (gradeInfoCont['entity score'] as any)?.[1]['Category'],
        Weight: (gradeInfoCont['entity score'] as any)?.[1]['Weight'],
        Score: (gradeInfoCont['entity score'] as any)?.[1]['Score'],
      }
      setGetTechnical(techRatioData)

      // const empPotentialSkillsCont = await empPotentialSkills.json()
      // const empPotentialSkillNamesCont = await empPotentialSkillNames.json()
      // console.log('empPotentialskill', empPotentialSkillsCont)
      // console.log('empPotentialskillNames', empPotentialSkillNamesCont)

      setTechRows(empKSANamesCont['technical names'])
      setFilteredTechRows(empKSANamesCont['technical names'])

      setAptitudeRows(empAptitudeKSANamesCont['aptitude names'])
      setFilteredAptitudeRows(empAptitudeKSANamesCont['aptitude names'])

      // setPotentialRows(empPotentialSkillNamesCont['potentialskill names'])
      // setFilteredPotentialRows(empPotentialSkillNamesCont['potentialskill names'])

      const flatCompilation = [
        ...empKSANamesCont['technical names'],
        ...empAptitudeKSANamesCont['aptitude names'],
        // .concat(
        //   empPotentialSkillNamesCont['potentialskill names'].flatMap(
        //     (item: any) => item.potentialskills
        //   )
        // ),
      ]
      setAllSkillRows(flatCompilation)
      setFilteredAllSkillRows(flatCompilation)

      if (empKSACont) {
        const entTechSkills: EmpSkillsInfo[] = empKSACont['employees technical ksa'].map(
          (employee: any) => ({
            name: employee['name'],
            id: employee['employee_id'],

            skills:
              employee['technical ksa']?.map((skills: any) => ({
                title: skills['title'],
                score: skills['score'],
                grade: skills['grade'],
                color: skills['color'],
                description: skills['description'],
              })) || [],
          })
        )
        const entAptitudeSkills: EmpSkillsInfo[] = empAptitudeKSACont['employees aptitude ksa'].map(
          (employee: any) => ({
            name: employee['name'],
            id: employee['employee_id'],

            skills:
              employee['aptitude ksa']?.map((skills: any) => ({
                title: skills['title'],
                score: skills['score'],
                grade: skills['grade'],
                color: skills['color'],
                description: skills['description'],
              })) || [],
          })
        )
        console.log('aptitude skills', entAptitudeSkills)
        // const entPotentialSkills: EmpSkillsInfo[] = empPotentialSkillsCont['potential skill'].map(
        //   (employee: any) => ({
        //     name: employee['name'],
        //     id: employee['employee_id'],
        //     skills:
        //       employee['potentialskills ksa']?.flatMap((group: any) =>
        //         group.potentialskills.map((potentialSkill: any) => ({
        //           title: potentialSkill['title'],
        //           score: potentialSkill['score'],
        //           grade: potentialSkill['grade'],
        //           color: potentialSkill['color'],
        //           description: potentialSkill['description'],
        //         }))
        //       ) || [], // Add a default value of an empty array to handle cases where 'potentialskills ksa' is undefined or empty
        //   })
        // )

        const mergedSkills: EmpSkillsInfo[] = entTechSkills.map((techSkill: EmpSkillsInfo) => {
          // const potentialSkill = entPotentialSkills.find(
          //   (potentialSkill: EmpSkillsInfo) => potentialSkill.id === techSkill.id
          // )

          // if (potentialSkill) {
          //   return {
          //     name: techSkill.name,
          //     id: techSkill.id,
          //     skills: techSkill.skills.concat(potentialSkill.skills || []),
          //   }
          // }
          const aptitudeSkill = entAptitudeSkills.find(
            (aptitudeSkill: EmpSkillsInfo) => aptitudeSkill.id === techSkill.id
          )
          // console.log('app', aptitudeSkill)

          if (aptitudeSkill) {
            return {
              name: techSkill.name,
              id: techSkill.id,
              skills: techSkill.skills.concat(aptitudeSkill.skills || []),
            }
          }

          return techSkill
        })

        const mergedArray = [...entTechSkills, ...entAptitudeSkills]
        // setAllSkillsEmp(mergedArray)
        // setFilteredAllSkillsEmp(mergedArray)
        setAllSkillsEmp(mergedSkills)
        setFilteredAllSkillsEmp(mergedSkills)
      }

      if (empKSACont) {
        const entTechSkills: EmpTechSkillsInfo[] = empKSACont['employees technical ksa'].map(
          (employee: any) => ({
            name: employee['name'],
            id: employee['employee_id'],

            techSkills:
              employee['technical ksa']?.map((skills: any) => ({
                title: skills['title'],
                score: skills['score'],
                grade: skills['grade'],
                color: skills['color'],
                description: skills['description'],
              })) || [],
          })
        )
        setTechSkillsEmp(entTechSkills)
        setFilteredTechSkillsEmp(entTechSkills)
      }

      // if (empPotentialSkillsCont) {
      //   const entPotentialSkills: EmpPotentialSkillsInfo[] = empPotentialSkillsCont[
      //     'potential skill'
      //   ].map((employee: any) => ({
      //     name: employee['name'],
      //     id: employee['employee_id'],
      //     PotentialSkillGroup:
      //       employee['potentialskills ksa']?.map((potential: any) => ({
      //         group: potential['group'],
      //         potentialskills:
      //           potential['potentialskills'].map((skills: any) => ({
      //             title: skills['title'],
      //             score: skills['score'],
      //             grade: skills['grade'],
      //             color: skills['color'],
      //             description: skills['description'],
      //           })) || [],
      //       })) || [],
      //   }))

      //   setPotentialSkillsEmp(entPotentialSkills)
      //   setFilteredPotentialSkillsEmp(entPotentialSkills)
      // }
    }
  } //the api call

  // Function to generate an array of zeros
  const generateZerosArray = (length: number) => Array.from({length}, () => 0)

  //#region Use Effects
  useEffect(() => {
    callEmpData()
  }, [])

  // Initialize the potentialSkillsRangeInput array with zeros when potentialRows changes
  useEffect(() => {
    if (potentialRows === null || potentialRows === undefined || potentialRows.length < 1) return
    setPotentialSkillsRangeInput(generateZerosArray(potentialRows.length))
  }, [potentialRows])

  useEffect(() => {}, [allRangeSliders])

  useEffect(() => {
    setTechMultiSelect(
      techRows?.map((item, index) => ({label: item ? item : '', value: index + 1}))
    )
    setAllMultiSelect(
      allSkillRows?.map((item, index) => ({label: item ? item : '', value: index + 1}))
    )

    //i am not using this, theres a different method being used which i feel could fall apart?
    if (potentialRows === null) return
    const potentialTest: string[] = potentialRows[0]?.['potentialskills']
    if (potentialTest != undefined) {
      setPotentialMultiSelect(
        potentialTest?.map((item, index) => ({
          label: item ? item : '',
          value: index + 1,
        })) || []
      )
    }
  }, [techRows, potentialRows, allSkillRows]) // for the potential Skills

  useEffect(() => {
    const filteredEmployees = filteredData.filter((employee) =>
      employee.name.toLowerCase().includes(searchQuery.toLowerCase())
    )

    setFilteredData(filteredEmployees)
  }, [searchQuery, originalData]) //for the search Query

  useEffect(() => {
    const filteredAllSkillsEmp = allSkillsEmp
      .filter((emp) =>
        emp.skills.some((skill) =>
          allSelectedList.some((selected) => selected.name === skill.title)
        )
      )
      .filter((emp) =>
        emp.skills.some((skill) =>
          allRangeSliders.some(
            (slider) =>
              slider.name === skill.title && skill.score >= (slider.value as unknown as number)
          )
        )
      )

    setFilteredAllSkillsEmp(filteredAllSkillsEmp)
  }, [allRangeSliders]) //for FilteredAllSkillsEmp
  //#endregion

  //#region Handle Functions
  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value)
  }

  const handleSearchSubmit = () => {
    setIsSearching(true)
  }

  const handleFilterTypeChange = (filteringType: string, selectedValue: string) => {
    setFilterOptions((prevFilters) => ({
      ...prevFilters,
      [filteringType]: selectedValue,
    }))

    // Trigger filtering based on the selected filter type
    handleFilter(selectedValue, filteringType)
  }

  const handleFilter = (selectedValue: string, filteringType: string) => {
    let filteredEmployees = [...filteredData]

    // Apply filters based on filter options
    filteredEmployees = filteredEmployees.filter((employee) => {
      if (filteringType === 'grade') {
        const score = employee.score
        if (selectedValue === 'Grade 25') {
          return score <= 25
        } else if (selectedValue === 'Grade 50') {
          return score <= 50
        } else if (selectedValue === 'Grade 75') {
          return score <= 75
        } else if (selectedValue === 'Grade 100') {
          return score >= 75
        }
      } else {
        return employee[filteringType as keyof Employee] === selectedValue
      }
      return false
    })
    setFilteredData(filteredEmployees)
  }

  const handleClearFilter = (filteringType: string) => {
    setFilterOptions((prevFilters) => ({
      ...prevFilters,
      [filteringType]: '',
    }))
    setFilteredData(filteredData)
  }

  const handleRangeSliderChange = (index: number, value: string) => {
    // Check if the index is within the rangeSliders array
    if (index < rangeSliders.length) {
      // Update the value at the specified index
      const updatedSliders = [...rangeSliders]
      updatedSliders[index] = Number(value)
      setRangeSliders(updatedSliders)
    } else {
      // Increase the size of the array with 0's until the index
      const updatedSliders = [...rangeSliders]
      for (let i = rangeSliders.length; i < index; i++) {
        updatedSliders.push(0)
      }
      updatedSliders[index] = Number(value)
      setRangeSliders(updatedSliders)
    }
  }

  const handleSort = (field: string) => {
    if (sortField === field) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc')
    } else {
      setSortField(field)
      setSortOrder('desc')
    }
  }

  const handleSliderChange = (name: string, value: string) => {
    setAllRangeSliders((prevSliders) => {
      // Check if the item with the given name already exists
      const existingSlider = prevSliders.find((slider) => slider.name === name)

      // If an existing slider is found, update its value
      if (existingSlider) {
        return prevSliders.map((slider) =>
          slider.name === name ? {...slider, value: value} : slider
        )
      }
      // If no existing slider is found, add a new item to the array
      return [...prevSliders, {name: name, value: value}]
    })
  }

  //#endregion

  const multiSelectUpdate = (selectedList: any, updatedItem: any, tab: string) => {
    if (tab == 'all') {
      setAllSelectedList(selectedList)
      if (selectedList.length !== 0) {
        setFilteredAllSkillRows(selectedList.map((item: any) => item.label))

        const filteredAllSkillsEmp = allSkillsEmp
          .map((emp) => ({
            ...emp,
            skills: emp.skills.filter((skill) =>
              selectedList.some((item: any) => item.label === skill.title)
            ),
          }))
          .filter((emp) => emp.skills.length > 0)

        setFilteredAllSkillsEmp(filteredAllSkillsEmp)
      } else {
        setFilteredAllSkillRows(allSkillRows)
        setFilteredAllSkillsEmp(allSkillsEmp)
      }
    } else if (tab == 'tech') {
      setTechSelectedList(selectedList)

      if (selectedList.length !== 0) {
        setFilteredTechRows(selectedList.map((item: any) => item.label))

        const filteredTechSkillsEmp = techSkillsEmp
          .map((emp) => ({
            ...emp,
            techSkills: emp.techSkills.filter((skill) =>
              selectedList.some((item: any) => item.label === skill.title)
            ),
          }))
          .filter((emp) => emp.techSkills.length > 0)

        setFilteredTechSkillsEmp(filteredTechSkillsEmp)
      } else {
        setFilteredTechRows(techRows)
        setFilteredTechSkillsEmp(techSkillsEmp)
      }
    } else if (tab.includes('potential')) {
      const indexString = tab.split('potential')[1] // Get the part after "potential"
      let selectedIndex = 0 // Default value

      const parsedIndex = parseInt(indexString, 10) // Convert the string to a number
      if (!isNaN(parsedIndex)) {
        selectedIndex = parsedIndex // Update the index only if it's a valid number
      }

      const filteredRows = potentialRows.map((skillGroup: any, index) => {
        if (index == selectedIndex) {
          return {
            group: skillGroup.group,
            potentialskills: selectedList.map((item: any) => item.label),
          }
        } else {
          return skillGroup
        }
      })

      setFilteredPotentialRows(filteredRows as [])

      if (selectedList.length !== 0) {
        const filteredPotentialSkillsEmp = potentialSkillsEmp
          .map((emp) => ({
            ...emp,
            PotentialSkillGroup: emp.PotentialSkillGroup.map((skillGroup, index) => {
              if (skillGroup.potentialskills) {
                if (index == selectedIndex) {
                  return {
                    ...skillGroup,
                    potentialskills: skillGroup.potentialskills.filter((skill) =>
                      selectedList.some((item: any) => item.label === skill.title)
                    ),
                  }
                } else return skillGroup
              } else return skillGroup
            }),
          }))
          .filter((emp) => emp.PotentialSkillGroup.length > 0)

        setFilteredPotentialSkillsEmp(filteredPotentialSkillsEmp)
      } else {
        setFilteredPotentialRows(potentialRows)
        setFilteredPotentialSkillsEmp(potentialSkillsEmp)
      }
    }
  }

  const colourStyles: StylesConfig<any, true> = {
    control: (styles, state) => ({
      ...styles,
      backgroundColor: 'dark',
      borderColor: state.isFocused ? 'var(--bs-primary)' : 'var(--bs-gray-300)',
      '&:hover': {
        borderColor: 'var(--bs-primary)',
      },
      boxShadow: state.isFocused ? '0 0 0 1px var(--bs-primary)' : '',
    }),
    option: (styles, {isDisabled, isFocused, isSelected}) => ({
      ...styles,
      // borderColor: 'var(--bs-gray-300)',
      backgroundColor: isDisabled
        ? undefined
        : isSelected
        ? 'var(--bs-primary)' // Set the desired color for selected option
        : isFocused
        ? 'var(--bs-primary)' // Set the desired color for focused option
        : 'var(--bs-body-bg)',
      color: isDisabled
        ? '#ccc'
        : isSelected
        ? 'var(--bs-dark)'
        : isFocused
        ? 'white'
        : 'var(--bs-dark)',
      cursor: isDisabled ? 'not-allowed' : 'default',
      ':active': {
        ...styles[':active'],
        backgroundColor: !isDisabled
          ? isSelected
            ? 'var(--bs-primary)'
            : // : 'hsl(var(--bs-primary) / 0.5)'
              'var(--bs-primary-active)'
          : undefined,
      },
    }),
    multiValue: (styles, {data}) => ({
      ...styles,
      backgroundColor: 'var(--bs-primary)', // Set the desired color for multi-value background
      borderRadius: '10px',
      paddingInline: '5px',
    }),
    multiValueLabel: (styles) => ({
      ...styles,
      color: 'var(--bs-light)', // Set the desired color for multi-value label
    }),
    multiValueRemove: (styles, {data}) => ({
      ...styles,
      color: 'var(--bs-light)',
      // display: data.isFixed ? 'none' : styles.display,
      ':hover': {
        backgroundColor: 'var(--bs-danger)', // Set the desired color on hover for multi-value remove
        color: 'white',
      },
    }),
  }

  function calculateAverageScore(skills: any, tab: string = 'tech'): number {
    const scores = skills?.map((skill: any) => skill.score) // Extract all scores

    //const averageScore = sum / validScores.length
    if (scores?.length !== 0 && scores) {
      if (tab == 'all') {
        if (allSelectedList.length != 0) {
          const averageScore =
            scores.reduce((sum: number, score: number) => sum + score, 0) / allSelectedList.length
          return averageScore
        }
      } else if (tab == 'tech') {
        if (techSelectedList.length != 0) {
          const averageScore =
            scores.reduce((sum: number, score: number) => sum + score, 0) / techSelectedList.length
          return averageScore
        }
      }
      const averageScore =
        scores.reduce((sum: number, score: number) => sum + score, 0) / scores.length
      return averageScore
    }

    return 0
  }

  const barToolTip = (score: number, title: string, color: string) => (
    <Tooltip
      id='skillsToolTip'
      style={{borderRadius: '10px', backgroundColor: color, position: 'absolute'}}
    >
      <span className='text-gray-800' style={{fontSize: '12px'}}>
        {title}: {Math.round(score)}%
      </span>
    </Tooltip>
  )

  const calculateColor = (score: number) => {
    if (score < 0.25) {
      return 'bg-beginner-grade'
    } else if (score < 0.5) {
      return 'bg-intermediate-grade'
    } else if (score < 0.75) {
      return 'bg-experienced-grade'
    } else {
      return 'bg-proficient-grade'
    }
  }

  // useEffect(() => {
  //   console.log('Search Query Changed:', searchQuery)
  // }, [searchQuery])

  return (
    <div className={`card ${className}`}>
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Candidate Competencies</span>
        </h3>
      </div>
      {/* ALL THE FILTERATIONS */}
      <div className='card-header border-0 pt-5 flex-row'>
        {/* Filtering by Grade */}
        <div className='card-toolbar flex-column'></div>
      </div>
      <div className='d-flex overflow-scroll h-100' style={{minHeight: '500px'}}>
        <div className='card-body py-3'>
          <div
            // className='my-10'
            style={{
              width: '600px',
              zIndex: 999,
              // top: '100px',
              // height: '400px',
            }}
          >
            <div>Filter Competencies</div>

            <Select
              options={allMultiSelect}
              isMulti
              closeMenuOnSelect={false}
              onChange={(selectedList: any, selectedItem: any) =>
                multiSelectUpdate(selectedList, selectedItem, 'all')
              }
              isClearable={true}
              styles={colourStyles}
            />
          </div>
          <div className='table-responsive ' style={{paddingTop: testCount}}>
            <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4 table-fixed'>
              <thead>
                <tr className='fw-bold text-muted'>
                  <th
                    className='fixed-column'
                    style={{minWidth: '200px'}}
                    onClick={() => handleSort('name')}
                  >
                    Candidate {sortField === 'name' && <i className={`fas fa-sort-${sortOrder}`} />}
                  </th>
                  <th
                    className='fixed-column'
                    style={{
                      minWidth: '200px',
                      // paddingLeft:"200px"
                    }}
                    onClick={() => handleSort('avgscore')}
                  >
                    Avg Score{' '}
                    {sortField === 'avgscore' && <i className={`fas fa-sort-${sortOrder}`} />}
                    <input
                      type='number'
                      className='form-control form-control-sm'
                      onInput={(e) => {
                        const value = Number(e.currentTarget.value)
                        if (value < 0) {
                          e.currentTarget.value = '0'
                        } else if (value > 100) {
                          e.currentTarget.value = '100'
                        }
                        setAllSkillsRangeInput(value)
                      }}
                    />
                  </th>
                  {filteredAllSkillRows?.map((row, index) => (
                    <>
                      <th key={'all-th' + index} className='fixed-column' style={{width: '200px'}}>
                        <span onClick={() => handleSort(row)}>{row} </span>
                        {sortField === row && <i className={`fas fa-sort-${sortOrder}`} />}
                        {/* <Form.Range
                                  step={0.1}
                                  min={0}
                                  max={1}
                                  // value={}
                                  onChange={(e) => handleSliderChange(row, e.target.value)}
                                /> */}
                      </th>
                    </>
                  ))}
                </tr>
              </thead>
              <tbody>
                {filteredAllSkillRows && filteredAllSkillRows.length > 0 ? (
                  filteredallSkillsEmp
                    ?.sort((a, b) => {
                      if (sortField == 'name') {
                        return sortOrder === 'asc'
                          ? a.name.localeCompare(b.name)
                          : b.name.localeCompare(a.name)
                      } else if (sortField === 'avgscore') {
                        const avgScoreA = calculateAverageScore(a.skills, 'all')
                        const avgScoreB = calculateAverageScore(b.skills, 'all')
                        return sortOrder === 'asc' ? avgScoreA - avgScoreB : avgScoreB - avgScoreA
                      } else if (sortField !== '') {
                        const skillA = a.skills?.find((skill) => skill.title === sortField)
                        const skillB = b.skills?.find((skill) => skill.title === sortField)

                        const scoreA = skillA ? skillA.score : 0
                        const scoreB = skillB ? skillB.score : 0
                        return sortOrder === 'asc' ? scoreA - scoreB : scoreB - scoreA
                      } else {
                        return 0
                      }
                    })
                    .map((emp, index) => {
                      const averageScore = calculateAverageScore(emp.skills, 'all')

                      if (averageScore * 100 >= allSkillsRangeInput) {
                        return (
                          <tr key={'all' + index}>
                            <td
                              className='table-row-dashed'
                              style={{
                                //comment out
                                // position: 'absolute',
                                // width: '250px',
                                // left: '0',
                                // marginRight: '50px',
                              }}
                            >
                              <div
                                className='d-flex align-items-center'
                                style={{
                                  minWidth: '250px',
                                  //comment rest out
                                  // backgroundColor: 'white',
                                  // left: 0,
                                  // paddingLeft: '30px',
                                  // marginRight: '50px',
                                }}
                              >
                                <div className='d-flex justify-content-start flex-column'>
                                  <Link
                                    className={
                                      `nav-link text-dark fw-bold text-hover-primary fs-6 ` +
                                      (location.pathname === '/candidate-profile' && 'active')
                                    }
                                    to={`/candidate-profile?employeeQuery=${emp.id}`}
                                  >
                                    {emp.name}
                                  </Link>
                                </div>
                              </div>
                            </td>
                            <td
                              style={{
                                //comment out
                                // paddingLeft: '30px',
                              }}
                            >
                              <div
                                className='d-flex align-items-center'
                                style={{minWidth: '150px'}}
                              >
                                <div className='d-flex justify-content-start flex-column'>
                                  <div
                                    className='d-flex flex-row w-100 me-2'
                                    style={{minWidth: '150px'}}
                                  >
                                    <OverlayTrigger
                                      placement='top'
                                      overlay={barToolTip(
                                        averageScore * 100,
                                        'Average Score',
                                        'bg-primary'
                                      )}
                                    >
                                      <div
                                        className='progress bg-light h-20px w-100'
                                        style={{minWidth: '100px'}}
                                      >
                                        <div
                                          className={`progress-bar h-20px  ${calculateColor(
                                            averageScore
                                          )}`}
                                          role='progressbar'
                                          style={{
                                            width: `${averageScore * 100}%`,
                                          }}
                                        ></div>
                                      </div>
                                    </OverlayTrigger>
                                    <div className='ps-2 text-muted'>
                                      {Math.round(averageScore * 100)}%
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </td>
                            {filteredAllSkillRows?.map((row, rowIndex) => {
                              const matchingSkill = emp.skills?.find((skill) => skill.title === row)
                              const score = matchingSkill ? matchingSkill.score : 'x'

                              if (
                                matchingSkill
                                // &&
                                // Number(
                                //   allRangeSliders.find(
                                //     (slider) => slider.name === matchingSkill.title
                                //   )?.value
                                // ) <= Number(score)
                              ) {
                                // if (
                                //   matchingSkill
                                //   //&& matchingSkill.score >= rangeSliders[rowIndex]
                                // ) {
                                return (
                                  <td className='text-end' key={rowIndex}>
                                    {score !== 'x' ? (
                                      <div
                                        className='d-flex flex-row w-100 me-2'
                                        style={{minWidth: '150px'}}
                                      >
                                        <OverlayTrigger
                                          placement='top'
                                          overlay={barToolTip(
                                            score * 100,
                                            matchingSkill ? matchingSkill.title : 'Title',
                                            matchingSkill ? matchingSkill.color : 'bg-warning'
                                          )}
                                        >
                                          <div
                                            className='progress bg-light h-20px w-100'
                                            style={{width: '100px'}}
                                          >
                                            <div
                                              className='progress-bar h-20px'
                                              role='progressbar'
                                              style={{
                                                width: `${score * 100}%`,
                                                backgroundColor: matchingSkill?.color,
                                              }}
                                            ></div>
                                          </div>
                                        </OverlayTrigger>
                                        <div className='ps-2 text-muted'>
                                          {Math.round(score * 100)}%
                                        </div>
                                      </div>
                                    ) : (
                                      <div className='d-flex w-100 justify-content-center text-muted'>
                                        {score}
                                      </div>
                                    )}
                                  </td>
                                )
                              } else {
                                return (
                                  <td className='text-end' key={rowIndex}>
                                    <div className='d-flex w-100 justify-content-center text-muted'>
                                      x
                                    </div>
                                  </td>
                                )
                              }
                            })}
                          </tr>
                        )
                      }
                    })
                ) : (
                  <p>No data found</p>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  )
}

export {UserListCompetencies}
