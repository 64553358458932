import React, {useState, useEffect} from 'react'
import {Link} from 'react-router-dom'

type Props = {
  className?: string
  tableData: any[]
  handleSort?: any
  hasScore?: boolean
  canRedirect?: boolean
  link?: string
  hiddenColumnIndex?: number
  redirectColumnName?: string
  sortingColumn?: number
}

const threshold = 2

const TableWithScore: React.FC<Props> = ({
  className,
  tableData,
  hiddenColumnIndex,
  hasScore = true,
  link = '#',
  canRedirect = false,
  redirectColumnName = '',
  sortingColumn,
}) => {
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('desc')
  const [sortField, setSortField] = useState<number>(
    sortingColumn || Object.keys(tableData[0] || {}).length - 1 || 0
  )
  const [fullTableData, setFullTableData] = useState<any[]>([])
  const columnNames = Object.keys(tableData[0] || {})

  const handleSort = (field: number) => {
    if (sortField === field) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc')
    } else {
      setSortField(field)
      setSortOrder('asc')
    }
  }

  useEffect(() => {
    if (sortingColumn !== undefined) {
      setSortField(sortingColumn)
    }
  }, [sortingColumn])

  useEffect(() => {
    // Sort the initial data based on the default field and order

    const sortedData = tableData.slice().sort((a, b) => {
      const index = sortField
      const valueA = a[columnNames[index]]
      const valueB = b[columnNames[index]]

      if (sortOrder === 'asc') {
        return valueA > valueB ? 1 : -1
      } else {
        return valueA < valueB ? 1 : -1
      }
    })

    setFullTableData(sortedData)
  }, [tableData, sortField, sortOrder])

  const convertToProperCase = (text: string) => {
    return text.replace(/\w\S*/g, (word) => {
      return word.charAt(0).toUpperCase() + word.substr(1).toLowerCase()
    })
  }

  return (
    <div className='table-responsive'>
      <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
        <thead>
          <tr className='fw-bold text-muted'>
            {columnNames.map((col, index) => {
              if (hiddenColumnIndex != index) {
                return (
                  <th key={index} onClick={() => handleSort(index)} style={{width: '40%'}}>
                    {convertToProperCase(col)}{' '}
                    {sortField === index && <i className={`fas fa-sort-${sortOrder}`} />}
                  </th>
                )
              }
            })}
          </tr>
        </thead>

        <tbody>
          {fullTableData.map((row, rowIndex) => (
            <tr key={rowIndex}>
              {columnNames.map((col, colIndex) => {
                if (hiddenColumnIndex != colIndex) {
                  return (
                    <td key={rowIndex + '-' + colIndex}>
                      {colIndex === columnNames.length - 1 && hasScore ? (
                        <div className='d-flex flex-column w-100 me-2' style={{minWidth: '150px'}}>
                          <div className='d-flex flex-stack mb-2'>
                            <span className='text-muted me-2 fs-7 fw-semibold'>
                              {Math.round((row[col] as number) * 100)}%
                            </span>
                          </div>
                          <div className='progress h-6px w-100'>
                            <div
                              className={`progress-bar ${
                                (row[col] as number) * 100 <= 25
                                  ? 'bg-beginner-grade'
                                  : (row[col] as number) * 100 <= 50
                                  ? 'bg-intermediate-grade'
                                  : (row[col] as number) * 100 <= 75
                                  ? 'bg-experienced-grade'
                                  : 'bg-proficient-grade'
                              }`}
                              role='progressbar'
                              style={{
                                width: `${
                                  (row[col] as number) * 100 <= threshold
                                    ? threshold
                                    : (row[col] as number) * 100
                                }%`,
                              }}
                            ></div>
                          </div>
                        </div>
                      ) : colIndex === 0 && canRedirect ? (
                        <Link
                          to={`${link}${row[redirectColumnName !== '' ? redirectColumnName : col]}`}
                          className='text-dark fw-bold text-hover-primary fs-6'
                        >
                          {row[col]}
                        </Link>
                      ) : (
                        <div className='text-dark fw-bold text-hover-primary fs-6'>
                          {typeof row[col] === 'number' ? Math.round(row[col]) : row[col]}
                        </div>
                      )}
                    </td>
                  )
                }
              })}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

export {TableWithScore}
