/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState, useEffect} from 'react'
import config from '../../../../config.json'
import {useUserContext} from '../../../../UserContext'
import WarningSpinner from '../../../../app/modules/profile/components/WarningSpinner'
import {TableWithScore} from './TableWithScore'
import {Link} from 'react-router-dom'
import {useCallInterval} from '../../../../CallTimeIntervalContext'

type Props = {
  className: string
  title?: string
}

const EntitesListTable: React.FC<Props> = ({className, title}) => {
  const [entitiesList, setEntitiesList] = useState<string[][]>()

  const {user} = useUserContext()
  const {callInterval} = useCallInterval()

  async function callEntitiesList() {
    if (user) {
      const leadershipData = await fetch(
        config.domainName + ':' + config.port + '/api/allentitiesdata',
        {
          method: 'GET',
          headers: {'Content-Type': 'application/json'},
        }
      )
      const leadershipDataCont = await leadershipData.json()
      if (leadershipDataCont) {
        setEntitiesList(leadershipDataCont['entities data'])
      }
    }
  }

  useEffect(() => {
    callEntitiesList()
  }, [])

  useEffect(() => {
    const interval = setInterval(() => {
      callEntitiesList()
    }, callInterval)

    return () => clearInterval(interval)
  }, [])

  return (
    <div className={`card ${className}`}>
      <div className='card-header border-0 pt-5 flex-row'>
        <h3 className='card-title align-items-start me-auto '>
          <Link to={'/candidate-overview?department'}>
            <span className='card-label fw-bold fs-3 mb-1'>{title}</span>
          </Link>
        </h3>
      </div>

      <div className='card-body py-3' style={{maxHeight: '500px', overflow: 'auto'}}>
        {entitiesList ? (
          <TableWithScore
            tableData={entitiesList}
            link='/candidate-overview?department='
            canRedirect={true}
          />
        ) : (
          <WarningSpinner canReload={true} reloadFunc={callEntitiesList} spinnerWidth='60px' />
        )}
      </div>
    </div>
  )
}

export {EntitesListTable}
