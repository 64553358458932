import React, {useEffect, useRef, useState} from 'react'
import {useThemeMode} from '../../layout/theme-mode/ThemeModeProvider'
import {Skill} from '../../../../dataTypes'
import {OverlayTrigger, Tooltip} from 'react-bootstrap'
import {getCSSVariableValue} from '../../../assets/ts/_utils'

type Props = {
  className: string
  skills: Skill[]
  allActualSkills?: Skill[]
  isCompact?: boolean
  title?: string
  setSelectedIndex?: any
  chartIndex: string
  empFunc?: (params: any) => any
  reloadFuncProps?: any
  canselect?: boolean // Add canselect prop
  onSelectSkills?: any // Add onSelectSkills prop
  prevSelectedSkillNames?: string[]
  noTick?: boolean
  noApprovePerc?: boolean
  noScore?: boolean
  indexDash?: number
}

const threshold = 0.02

const CompetenciesTable: React.FC<Props> = ({
  className,
  skills,
  allActualSkills,
  isCompact = false,
  title = 'Technical',
  setSelectedIndex = -1,
  chartIndex,
  empFunc,
  reloadFuncProps,
  prevSelectedSkillNames,
  canselect = false, // Set canselect default value
  onSelectSkills = () => {}, // Initialize onSelectSkills with an empty function
  noTick = true,
  noApprovePerc = true,
  noScore = true,
  indexDash,
}) => {
  const chartRef = useRef<HTMLDivElement>(null)
  const {mode} = useThemeMode()
  const [selectedSkills, setSelectedSkills] = useState<number[]>([])
  const [selectedSkillNames, setSelectedSkillNames] = useState<string[]>([])
  const [prevSelect, setPrevSelect] = useState<string[] | undefined>([])
  const [allSelected, setAllSelected] = useState<number[][]>([]) // New state for all selected skills
  const [skillCheckEnd, setSkillCheckEnd] = useState(false)

  const sortedSkills = skills.sort((a, b) => b.score - a.score)
  // console.log('prev seelcted something', prevSelectedSkillNames)
  // console.log('sortedskills', sortedSkills)
  let pieTest = {
    'total skills': sortedSkills.length,
    'total score': sortedSkills.reduce((accumulator, object) => {
      return accumulator + object.score
    }, 0),
  }
  var ColorPercentage = pieTest['total score'] / pieTest['total skills'] // Assuming the maximum grade is 100
  let OverallColor = '#EDA500' // Default color for beginner

  if (ColorPercentage >= 0.75) {
    OverallColor = '#68B139' // Proficient (>= 100%)
  } else if (ColorPercentage >= 0.5) {
    OverallColor = '#40519C' // Experienced (>= 75%)
  } else if (ColorPercentage >= 0.25) {
    OverallColor = '#EDA500' // Intermediate (>= 50%)
  } else if (ColorPercentage <= 0.25) {
    OverallColor = '#C42B21' // Beginner (>= 25%)
  }
  const chartSize = 100
  const chartLine = 20
  const chartRotate = 0
  const approvedPerc = 70
  useEffect(() => {
    refreshChart()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mode, pieTest])

  const refreshChart = () => {
    if (!chartRef.current || !pieTest) {
      return
    }

    setTimeout(() => {
      initChart(
        chartSize,
        chartLine,
        chartRotate,
        pieTest['total skills'],
        pieTest['total score'],
        chartIndex,
        isCompact
      )

      const selectedSkillsNames = allSelected.map((skillsArray) =>
        skillsArray.map((index) => sortedSkills[index].title)
      )

      // Pass selectedSkillNames to the parent component
      //onSelectSkills(selectedSkillsNames.flat())
    }, 10)
  }
  // useEffect(
  //   () => {
  //     setPrevSelect(prevSelectedSkillNames)
  //     // console.log('should be true', prevSelectedSkillNames?.includes('Continuous Learning'))
  //   },
  //   [
  //     // reloadFuncProps
  //   ]
  // )
  useEffect(() => {
    // console.log('prev select outside if null', prevSelectedSkillNames)
    if (prevSelectedSkillNames != null) {
      // console.log('prev select inside if not null', prevSelectedSkillNames)
      if (prevSelectedSkillNames.length != 0) {
        // console.log('prev select  if array not empty', prevSelectedSkillNames)
        //clear all those previously clicked
        // console.log('first for loop start')

        // console.log('sorted skill name?', title)
        for (let index = 0; index < sortedSkills?.length; index++) {
          var checkbox = document.getElementById(
            sortedSkills[index].title + ' Check box'
          ) as HTMLInputElement
          if (checkbox?.checked == true) {
            // console.log(sortedSkills[index].title + 'was clicked in loop if length is not null')
            document.getElementById(sortedSkills[index].title + ' Check box')?.click()
          }
        }
        //click new ones
        // console.log('first for loop end')
        var skillsTitle = sortedSkills.map((s) => s.title)
        // console.log('skillstitles', skillsTitle)
        for (let index = 0; index < prevSelectedSkillNames?.length; index++) {
          // onSelectSkills(prevSelectedSkillNames[index], true)
          if (skillsTitle.includes(prevSelectedSkillNames[index])) {
            // console.log('skill clicked', prevSelectedSkillNames[index])
            document.getElementById(prevSelectedSkillNames[index] + ' Check box')?.click()
          }
        }
      }
      // else {
      //   //   // console.log(skills)
      //   //   console.log('prev selected if empty', prevSelectedSkillNames)
      //   for (let index = 0; index < sortedSkills?.length; index++) {
      //     // onSelectSkills(prevSelectedSkillNames[index], true)
      //     var checkbox = document.getElementById(
      //       sortedSkills[index].title + ' Check box'
      //     ) as HTMLInputElement
      //     if (checkbox?.checked) {
      //       console.log(sortedSkills[index].title + 'was clicked')
      //       document.getElementById(sortedSkills[index].title + ' Check box')?.click()

      //       // checkbox.click()
      //     }
      //   }
      // }
    }
  }, [prevSelectedSkillNames, sortedSkills])
  useEffect(() => {
    // console.log('prev select outside if null', prevSelectedSkillNames)
    if (prevSelectedSkillNames != null) {
      // console.log('prev select inside if not null', prevSelectedSkillNames)
      if (prevSelectedSkillNames.length == 0) {
        for (let index = 0; index < sortedSkills?.length; index++) {
          var checkbox = document.getElementById(
            sortedSkills[index].title + ' Check box'
          ) as HTMLInputElement
          if (checkbox?.checked) {
            // console.log(sortedSkills[index].title + 'was clicked')
            document.getElementById(sortedSkills[index].title + ' Check box')?.click()
          }
        }
      }
    }
  }, [prevSelectedSkillNames, sortedSkills])

  const handleSkillChange = (e: any, data: string) => {
    onSelectSkills(data, e.target.checked)
  }

  const skillsTooltip = (score: number, title: string, color: string) => (
    <Tooltip id='skillsToolTip' className={` ${color}`} style={{borderRadius: '10px'}}>
      <span className='text-gray-800' style={{fontSize: '12px'}}>
        {title}: {Math.round(score)}%
      </span>
    </Tooltip>
  )
  const indexOfFirstZeroScore = sortedSkills.findIndex((skill) => skill.score === null)

  return (
    // <div className={`card ${className}`} style={{height: '100% '}}>
    //   <div className='card-header flex-row border-0 pt-5'>
    //     <h3 className='card-title fw-bold align-items-start flex-column '>{title}</h3>
    //     <div className='card-toolbar'></div>
    //   </div>
    <>

      {/* <div className='d-flex flex-center'>
          {isCompact ? (
            <div className='d-flex flex-center me-5 pt-2'>
              <div
                id={chartIndex}
                ref={chartRef}
                style={{minWidth: `${chartSize / 2}px`, minHeight: `${chartSize / 2}px`}}
                data-kt-size={chartSize / 3}
                data-kt-line={chartLine / 2}
              ></div>
              <div style={{width: '50px'}}></div>
              <div className='fw-bolder text-white-700 text-xxl-end fs-2x'>
                {Math.round((pieTest['total score'] / pieTest['total skills']) * 100)}%
              </div>
            </div>
          ) : (
            <div className='d-flex flex-row flex-center w-75 me-2'>
              <div className='fw-bold  w-25 fs-4'>Overall Score</div>
              <div className='progress bg-light h-12px w-75' style={{minWidth: '200px'}}>
                <OverlayTrigger
                  placement='top'
                  overlay={skillsTooltip(
                    Math.round((pieTest['total score'] / pieTest['total skills']) * 100),
                    'Overall',
                    OverallColor
                  )}
                >
                  <div
                    className=' progress-bar'
                    role='progressbar'
                    style={{
                      width: `${Math.round(
                        (pieTest['total score'] / pieTest['total skills']) * 100
                      )}%`,
                      backgroundColor: OverallColor,
                    }}
                  ></div>
                </OverlayTrigger>
              </div>
            </div>
          )}
        </div> */}

      <div
        className='card-body w-100'
        style={isCompact ? {overflow: 'scroll'} : {height: '100%', overflowY: 'hidden'}}
      >
        <div className='table-responsive w-100'>
          {title ==='Career Path' && <h3 className='fs-2'>Assessed Competencies</h3>}

          <table className='table  table-row-gray-300 align-middle gs-0 gy-4 table-fixed'>
            <tbody>
              {sortedSkills.map((skill, index) => (
                <>
                  {index === indexOfFirstZeroScore && (
                    <>
                      {/* Render the separator row here */}
                      <tr key={`separator-${index}`}>
                        <td colSpan={3}>
                          {/* <hr /> */}
                          <h3 className='fs-2'>Not Assessed Competencies</h3>
                        </td>
                      </tr>
                    </>
                  )}
                  <tr
                    key={
                      `skill-${index}`
                      // index
                    }
                  >
                    {canselect && (
                      <td>
                        <input
                          id={skill.title + ' Check box'}
                          type='checkbox'
                          onChange={(e) => handleSkillChange(e, skill.title)}
                          style={{marginRight: '0.5rem'}} // Add a margin to create space between checkbox and title
                        />
                      </td>
                    )}
                    <td>{skill.title}</td>
                    <td>
                      {/* <div className='d-flex flex-column h-24px w-100 me-2'>
                      <div className='progress  h-12px w-100' style={{minWidth: '250px'}}>
                        <>
                          <div
                            className=' progress-bar bg-white'
                            role='progressbar'
                            style={{
                              width: `${skill.score * 100 <= 1 ? 1 : skill.score * 100}%`,
                              backgroundColor: 'white',
                            }}
                          >
                            <span style={{position: 'relative', alignItems: 'end', color: 'black'}}>
                              {skill.score * 100}%
                            </span>
                          </div>

                          {skill.score * 100 < approvedPerc && (
                            <OverlayTrigger
                              placement='top'
                              overlay={skillsTooltip(skill.score * 100, skill.title, skill.color)}
                            >
                              <div
                                className=' progress-bar progress-bar-striped'
                                role='progressbar'
                                style={{
                                  width: `${approvedPerc - skill.score * 100}%`,
                                  backgroundColor: 'red',
                                  opacity: '0.5',
                                }}
                              >
                                {' '}
                                <span>{approvedPerc}%</span>
                              </div>
                            </OverlayTrigger>
                          )}
                        </>
                      </div>
                    </div> */}
                      <div className='d-flex flex-column h-24px w-100 me-2'>
                        <div
                          className='progress bg-light h-12px w-100'
                          style={{minWidth: '250px'}}
                          onClick={() => {
                            setSelectedIndex(index)
                          }}
                        >
                          {/* <OverlayTrigger
                          placement='top'
                          overlay={skillsTooltip(skill.score * 100, skill.title, skill.color)}
                        > */}
                          <>
                            {noScore && (
                              <OverlayTrigger
                                placement='top'
                                overlay={skillsTooltip(skill.score * 100, skill.title, skill.color)}
                              >
                                <div
                                  className=' progress-bar'
                                  role='progressbar'
                                  style={{
                                    width: `${
                                      // skill.score * 100 <= 1 ? 1 :
                                      skill.score * 100
                                    }%`,
                                    backgroundColor: skill.color,
                                  }}
                                >
                                  <span style={{position: 'relative'}}>{Math.round(skill.score * 100)}%</span>
                                </div>
                              </OverlayTrigger>
                            )}

                            {noApprovePerc &&
                              (skill.score * 100 < approvedPerc ? (
                                <OverlayTrigger
                                  placement='top'
                                  overlay={skillsTooltip(
                                    approvedPerc,
                                    'Threshold Score to Achieve',
                                    skill.color
                                  )}
                                >
                                  <div
                                    className=' progress-bar progress-bar-striped'
                                    role='progressbar'
                                    style={{
                                      width: `${
                                        noScore ? approvedPerc - skill.score * 100 : approvedPerc
                                      }%`,
                                      // backgroundColor: 'var(--bs-warning)',
                                      backgroundColor: '#3f4254',
                                      opacity: '0.9',
                                    }}
                                  >
                                    {' '}
                                    <span
                                    //  style={{position:'relative', bottom:15, color:'black'}}
                                    >
                                      {Math.round(approvedPerc - skill.score * 100)}%
                                    </span>
                                  </div>
                                </OverlayTrigger>
                              ) : (
                                !noScore && (
                                  <OverlayTrigger
                                    placement='top'
                                    overlay={skillsTooltip(
                                      approvedPerc,
                                      'Threshold Score to Achieve',
                                      skill.color
                                    )}
                                  >
                                    <div
                                      className=' progress-bar progress-bar-striped'
                                      role='progressbar'
                                      style={{
                                        width: `${
                                          noScore ? approvedPerc - skill.score * 100 : approvedPerc
                                        }%`,
                                        backgroundColor: 'red',
                                        opacity: '0.9',
                                      }}
                                    >
                                      {' '}
                                      <span
                                      //  style={{position:'relative', bottom:15, color:'black'}}
                                      >
                                        {approvedPerc}%
                                      </span>
                                    </div>
                                  </OverlayTrigger>
                                )
                              ))}
                          </>
                          {/* </OverlayTrigger> */}
                        </div>
                      </div>
                    </td>
                    {noTick && (
                      <td>
                        {skill.score * 100 > approvedPerc ? (
                          <i
                            className='fa-solid fa-square-check'
                            style={{color: getCSSVariableValue('--bs-success')}}
                          ></i>
                        ) : (
                          <i className='fa-regular fa-square-check' style={{color: ''}}></i>
                        )}
                      </td>
                    )}
                  </tr>
                </>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
    // </div>
  )
}

export {CompetenciesTable}

const initChart = function (
  chartSize: number = 70,
  chartLine: number = 11,
  chartRotate: number = 145,
  number1: number,
  number2: number,
  chartIndex: string,
  isCompact: boolean
) {
  if (isCompact == true) {
    chartSize = chartSize / 2
    chartLine = chartLine / 2
    chartRotate = chartRotate / 2
  }
  const el = document.getElementById(chartIndex)
  if (!el) {
    return
  }
  el.innerHTML = ''

  var options = {
    size: chartSize,
    lineWidth: chartLine,
    rotate: chartRotate,
  }

  const canvas = document.createElement('canvas')
  const span = document.createElement('span')

  // @ts-ignore
  if (typeof G_vmlCanvasManager !== 'undefined') {
    // @ts-ignore
    G_vmlCanvasManager.initElement(canvas)
  }

  const ctx = canvas.getContext('2d')
  canvas.width = canvas.height = options.size

  el.appendChild(span)
  el.appendChild(canvas)

  // @ts-ignore
  ctx.translate(options.size / 2, options.size / 2) // change center
  // @ts-ignore
  ctx.rotate((-1 / 2 + options.rotate / 180) * Math.PI) // rotate -90 deg

  const radius = (options.size - options.lineWidth) / 2

  const drawCircle = function (color: string, lineWidth: number, percent: number) {
    percent = Math.min(Math.max(0, percent || 1), 1)
    if (!ctx) {
      return
    }

    ctx.beginPath()
    ctx.arc(0, 0, radius, 0, Math.PI * 2 * percent, false)
    ctx.strokeStyle = color
    ctx.lineCap = 'round' // butt, round or square
    ctx.lineWidth = lineWidth
    ctx.stroke()
  }

  let percentage = number2 / number1 // Assuming the maximum grade is 100
  let color = '#EDA500' // Default color for beginner

  if (percentage >= 0.75) {
    color = '#68B139' // Proficient (>= 100%)
  } else if (percentage >= 0.5) {
    color = '#40519C' // Experienced (>= 75%)
  } else if (percentage >= 0.25) {
    color = '#EDA500' // Intermediate (>= 50%)
  } else if (percentage <= 0.25) {
    color = '#C42B21' // Beginner (>= 25%)
  }
  if (percentage <= threshold) {
    percentage = threshold // Set percent to a specific value when it is zero
  }
  // Init 2
  //'#E4E6EF'
  drawCircle(getCSSVariableValue('--bs-gray-300'), options.lineWidth, number1 / number1)

  drawCircle(color, options.lineWidth, percentage)
}
