/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useRef, useState} from 'react'

import config from '../../../../config.json'
import {useUserContext} from '../../../../UserContext'
import {BIEPchart} from './BIEPchart'

type Props = {
  className?: string
  title?: string
  description?: string
}

const LeadershipBIEPChart: React.FC<Props> = ({className, title, description}) => {
  const {user} = useUserContext()
  const [chartData, setChartData] = useState<[]>([])

  async function callLeadershipData() {
    if (user) {
      const leadershipData = await fetch(
        config.domainName + ':' + config.port + '/api/allentitiesdata',
        {
          method: 'GET',
          headers: {'Content-Type': 'application/json'},
        }
      )
      const leadershipDataCont = await leadershipData.json()
      if (leadershipDataCont) {
        const entData = leadershipDataCont['entities data'].map((ent: any) => {
          if (ent['average score'] !== '-') {
            const entScorePercentage = Math.round(ent['average score'] * 100)
            return {...ent, 'average score': entScorePercentage}
          } else {
            return {...ent, 'average score': 0}
          }
        })

        setChartData(entData.map((ent: any) => ent['average score']))
      }
    }
  }

  useEffect(() => {
    callLeadershipData()
  }, [])

  return (
    <div className={`card ${className}`}>
      <div className='card-header border-0 pt-5'>
        {/* begin::Title */}
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>{title}</span>

          <span className='text-muted fw-semibold fs-7'>{description}</span>
        </h3>
        {/* end::Title */}
      </div>
      <div className='card-body'>
        <BIEPchart chartData={chartData} yaxisname='Number of Entities' />
      </div>
    </div>
  )
}

export {LeadershipBIEPChart}
