/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useRef, useState} from 'react'

import config from '../../../../config.json'
import {useUserContext} from '../../../../UserContext'
import {BIEPchart} from './BIEPchart'

type Props = {
  className?: string
  title?: string
  description?: string
  refreshBool?: boolean
}

const EntBIEPChart: React.FC<Props> = ({className, title, description, refreshBool}) => {
  const {user} = useUserContext()
  const [chartData, setChartData] = useState<[]>([])

  async function callEmpData() {
    if (user?.['user id']) {
      const allEmpDataSet = await fetch(config.domainName + ':' + config.port + '/api/employee', {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({
          entity_id: user['user id'],
        }),
      })

      const allEmpDataCont = await allEmpDataSet.json()
      //console.log(allEmpDataCont)
      const employeeData = allEmpDataCont.employees.map((emp: any) => {
        if (emp['employee score'] !== '-') {
          const empScorePercentage = Math.round(emp['employee score'] * 100)
          return {...emp, 'employee score': empScorePercentage}
        } else {
          return {...emp, 'employee score': 0}
        }
      })

      setChartData(employeeData.map((employee: any) => employee['employee score']))
    }
  }

  useEffect(() => {
    callEmpData()
  }, [])

  useEffect(() => {
    if (refreshBool == true) {
      callEmpData()
    }
  }, [refreshBool])

  return (
    <div className={`card ${className}`}>
      <div className='card-header border-0 pt-5'>
        {/* begin::Title */}
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>{title}</span>

          <span className='text-muted fw-semibold fs-7'>{description}</span>
          <span className='text-muted fw-semibold fs-7'>
            Beginner: 0-25%, Intermediate: 26-50%, Experienced: 51-75% and Proficient: 76-100%{' '}
          </span>
        </h3>
        {/* end::Title */}
      </div>
      <div className='card-body'>
        <BIEPchart chartData={chartData} />
      </div>
    </div>
  )
}

export {EntBIEPChart}
