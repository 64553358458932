/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useRef, useState} from 'react'
import {getCSSVariableValue} from '../../../../assets/ts/_utils'
import {useThemeMode} from '../../../layout/theme-mode/ThemeModeProvider'
import config from '../../../../../config.json'
import {EntityPendingInfo} from '../../../../../dataTypes'
import {useUserContext} from '../../../../../UserContext'
import WarningSpinner from '../../../../../app/modules/profile/components/WarningSpinner'
import {Link} from 'react-router-dom'
import {useCallInterval} from '../../../../../CallTimeIntervalContext'

type Props = {
  className: string
  title?: string
  subtitle: string
  chartSize?: number
  chartLine?: number
  chartRotate?: number
  color?: string

  img?: string
  fontColor?: string
}

const threshold = 0.02

const EntityPendingInfoDonut: FC<Props> = ({
  className,
  subtitle,
  chartSize = 100,
  chartLine = 20,
  chartRotate = 0,
  color = '#F1416C',
  img,
  fontColor,
}) => {
  const chartRef = useRef<HTMLDivElement | null>(null)
  const {mode} = useThemeMode()

  const [entityPendingInfo, setEntityPendingInfo] = useState<EntityPendingInfo>()
  const {user} = useUserContext()
  const {callInterval} = useCallInterval()

  async function callEntAssessmentInfo() {
    if (user?.['user id']) {
      const entPending = await fetch(config.domainName + ':' + config.port + '/api/entityquery', {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({
          function: 'PendingAssessments',
          entity_id: user['user id'],
        }),
      })

      const entPendingCont = await entPending.json()

      if (entPendingCont) {
        const entityPending: EntityPendingInfo = {
          'employees with no tasks': entPendingCont['employees with no tasks'],
          'employees with pending tasks': entPendingCont['employees with pending tasks'],
          'total employees': entPendingCont['total employees'],
          'completed employees':
            entPendingCont['total employees'] - entPendingCont['employees with pending tasks'],
        }

        setEntityPendingInfo(entityPending)
      }
    }
  }

  useEffect(() => {
    callEntAssessmentInfo()
  }, [])

  useEffect(() => {
    const interval = setInterval(() => {
      callEntAssessmentInfo()
    }, callInterval)

    return () => clearInterval(interval)
  }, [])

  useEffect(() => {
    refreshChart()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mode, entityPendingInfo])

  const refreshChart = () => {
    if (!chartRef.current || !entityPendingInfo) {
      return
    }

    setTimeout(() => {
      initChart(
        chartSize,
        chartLine,
        chartRotate,
        entityPendingInfo['total employees'],
        entityPendingInfo['completed employees'],
        entityPendingInfo['employees with pending tasks']
      )
    }, 10)
  }

  return (
    <div
      className={`card card-flush bgi-no-repeat bgi-size-contain bgi-position-x-end ${className}`}
    >
      <div className='card-header border-0 pt-5'>
        <div className='card-title d-flex flex-column'>
          <h3 className='card-title align-items-start flex-column'>
            <Link to={'/candidate-overview'}>
              <span className='card-label fw-bold fs-3 mb-1'>
                Total Users: {entityPendingInfo ? entityPendingInfo['total employees'] : '--'}
              </span>
            </Link>
          </h3>
        </div>
      </div>

      {entityPendingInfo ? (
        <div className='card-body pt-1 pb-4 d-flex flex-wrap align-items-center'>
          <div className='d-flex flex-center me-5 pt-2'>
            <div
              id='GradeChart'
              ref={chartRef}
              style={{minWidth: `${chartSize}px`, minHeight: `${chartSize}px`}}
              data-kt-size={chartSize}
              data-kt-line={chartLine}
            ></div>
          </div>

          <div className='d-flex flex-column content-justify-center flex-row-fluid'>
            <div className='d-flex fw-semibold align-items-center my-3'>
              <div className='bullet w-8px h-3px rounded-2 bg-primary '></div>
              <div className='text-white-500 flex-grow-1 px-2'>Completed</div>

              <div className='fw-bolder text-white-700 text-xxl-end'>
                {entityPendingInfo['completed employees']}
              </div>
            </div>
            <div className='d-flex fw-semibold align-items-center'>
              <div
                className='bullet w-8px h-3px rounded-2'
                style={{backgroundColor: '#E4E6EF'}}
              ></div>
              <div className='text-white-500 flex-grow-1 px-2'>Pending</div>
              <div className=' fw-bolder text-white-700 text-xxl-end'>
                {entityPendingInfo['employees with pending tasks']}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <WarningSpinner spinnerWidth='60px' canReload={true} reloadFunc={callEntAssessmentInfo} />
      )}
    </div>
  )
}

const initChart = function (
  chartSize: number = 70,
  chartLine: number = 11,
  chartRotate: number = 145,
  number1: number,
  number2: number,
  number3: number
) {
  const el = document.getElementById('GradeChart')
  if (!el) {
    return
  }
  el.innerHTML = ''

  var options = {
    size: chartSize,
    lineWidth: chartLine,
    rotate: chartRotate,
    //percent:  el.getAttribute('data-kt-percent') ,
  }

  const canvas = document.createElement('canvas')
  const span = document.createElement('span')

  // @ts-ignore
  if (typeof G_vmlCanvasManager !== 'undefined') {
    // @ts-ignore
    G_vmlCanvasManager.initElement(canvas)
  }

  const ctx = canvas.getContext('2d')
  canvas.width = canvas.height = options.size

  el.appendChild(span)
  el.appendChild(canvas)

  // @ts-ignore
  ctx.translate(options.size / 2, options.size / 2)
  // @ts-ignore
  ctx.rotate((-1 / 2 + options.rotate / 180) * Math.PI)

  const radius = (options.size - options.lineWidth) / 2

  const drawCircle = function (color: string, lineWidth: number, percent: number) {
    percent = Math.min(Math.max(0, percent || 1), 1)
    if (!ctx) {
      return
    }

    ctx.beginPath()
    ctx.arc(0, 0, radius, 0, Math.PI * 2 * percent, false)
    ctx.strokeStyle = color
    ctx.lineCap = 'round' // butt, round or square
    ctx.lineWidth = lineWidth
    ctx.stroke()
  }
  let percentage = number2 / number1 // Assuming the maximum grade is 100
  // Init 2
  if (percentage <= threshold) {
    percentage = threshold // Set percent to a specific value when it is zero
  }
  //#E4E6EF
  drawCircle(getCSSVariableValue('--bs-gray-300'), options.lineWidth, number1 / number1)
  drawCircle(getCSSVariableValue('--bs-primary'), options.lineWidth, percentage)
}

export {EntityPendingInfoDonut}
