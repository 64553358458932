/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState} from 'react'
import {Button} from 'react-bootstrap'

type Props = {
  className?: string
  buttonFunc?: any
  title?: string
  subtitle?: string
  buttonText?: string
  alertMessage?: string
  alertClassName?: string
}
const ButtonCard: React.FC<Props> = ({
  className,
  title = 'Button Card',
  subtitle = 'sample text for button card',
  buttonText = 'Button Text',
  alertMessage,
  alertClassName,
  buttonFunc = () => {},
}) => {
  const [showAlert, setShowAlert] = useState<boolean>(false)

  const handleButtonClick = () => {
    buttonFunc()
    setShowAlert(true)

    setTimeout(() => {
      setShowAlert(false)
    }, 3000)
  }

  return (
    <div className={`card ${className} overflow-scroll`}>
      <div className='card-header border-0 pt-4'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-0'>{title}</span>
        </h3>
      </div>
      <div className='card-body py-2'>
        <div className='pb-5'>{subtitle}</div>
        <Button onClick={handleButtonClick}>{buttonText}</Button>
        {showAlert && <div className={`alert ${alertClassName} mt-3`}>{alertMessage}</div>}
      </div>
    </div>
  )
}

export {ButtonCard}
