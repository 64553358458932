/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'

import {Skill} from '../../../../dataTypes'

type Props = {
  className: string
  skill: Skill
  selectedIndex: Number
}

const SkillsDescription: React.FC<Props> = ({className, skill, selectedIndex = -1}) => {
  const cardStyle = {
    border: skill?.color ? `2px solid ${skill.color}` : '2px solid #000000',
  }

  return (
    <div className={`card ${className}`} style={cardStyle}>
      <div className='card-body pb-0'>
        <div className='d-flex align-items-center mb-5'>
          <div className='d-flex align-items-center flex-grow-1'>
            {selectedIndex == -1 ? (
              <div className='d-flex flex-column'>
                <a href='#' className='text-gray-800 text-hover-primary fs-6 fw-bold'>
                  Description
                </a>
                <span className='text-gray-800 text-hover-primary fs-6 fw-bold'>
                  Click on the bars to get more information about each competency
                </span>
              </div>
            ) : (
              <div className='d-flex flex-column'>
                <a
                  href='#'
                  className='text-800  fs-2 fw-bold'
                  style={{color: skill?.color || '#000000'}}
                >
                  {skill?.title}
                </a>

                <span className='fs-6 text-gray-800'>
                  {skill?.description?.split('\n').map((line, index) => (
                    <React.Fragment key={index}>
                      {line}
                      <br />
                    </React.Fragment>
                  ))}
                </span>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export {SkillsDescription}
