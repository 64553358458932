import {Suspense} from 'react'
import {Outlet} from 'react-router-dom'
import {I18nProvider} from '../_metronic/i18n/i18nProvider'
import {LayoutProvider, LayoutSplashScreen} from '../_metronic/layout/core'
import {MasterInit} from '../_metronic/layout/MasterInit'
import {UserContextProvider} from '../UserContext'
// import {ProfileImageProvider} from '../ProfileImageContext'
import {CallTimeProvider} from '../CallTimeIntervalContext'

const App = () => {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <UserContextProvider>
        <I18nProvider>
          {/* <ProfileImageProvider> */}
          <CallTimeProvider>
            <LayoutProvider>
              <Outlet />
              <MasterInit />
            </LayoutProvider>
          </CallTimeProvider>
          {/* </ProfileImageProvider> */}
        </I18nProvider>
      </UserContextProvider>
    </Suspense>
  )
}

export {App}
