/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'

import {Skill, TrainingCourse} from '../../../../dataTypes'

type Props = {
  className: string
  skill: Skill
  selectedIndex: Number
  trainingArr?: string[]
  color?: string
  approvedThreshold?: number
}

const SkillsTrainingDescription: React.FC<Props> = ({
  className,
  skill,
  selectedIndex = -1,
  trainingArr,
  color,
  approvedThreshold = 70,
}) => {
  const [secureSkills, setSecureSkills] = useState<Skill[]>([])

  const cardStyle = {
    // border: color ? `2px solid ${color}` : '2px solid #000000',
    border: skill?.color ? `2px solid ${skill.color}` : '2px solid #000000',
  }
  // console.log('selected index in skill trisainign desc', selectedIndex)
  const foundationTraining: TrainingCourse[] = [
    {
      'course name': 'Microsoft Excel',
      'course type': 'Some Course',
    },
    {
      'course name': 'CSAP',
      'course type': 'Some Course',
    },
  ]
  const coreTraining: TrainingCourse[] = [
    {
      'course name': 'Microsoft Excel',
      'course type': 'Some Course',
    },
    {
      'course name': 'CSAP',
      'course type': 'Some Course',
    },
  ]
  const advancedTraining: TrainingCourse[] = [
    {
      'course name': 'Microsoft Excel',
      'course type': 'Some Course',
    },
    {
      'course name': 'CISSP+',
      'course type': 'Some Course',
    },
  ]
  const approvedPerc = approvedThreshold
  useEffect(() => {
    const aptitudeArray: Skill[] = [
      {
        title: 'Secure Coding Practices',
        score: 0.96,
        grade: 'proficient',
        color: '#66B32E',
        description:
          '• Secure Developers must have a strong grasp of secure coding principles, including input validation, output encoding, and proper error handling. They should be proficient in programming languages commonly used in web and application development to prevent common vulnerabilities such as SQL injection, Cross-Site Scripting (XSS), and Cross-Site Request Forgery (CSRF).',
      },
      {
        title: 'Vulnerability Assessment',
        score: 0.69,
        grade: 'proficient',
        color: '#40519C',
        description:
          '• Secure Developers should be skilled in identifying vulnerabilities within applications and systems. They need to conduct thorough security assessments and penetration testing to proactively discover weaknesses and potential threats. Understanding how to use tools like OWASP ZAP or Nessus is essential.          ',
      },
      {
        title: 'Security Frameworks and Standards',
        score: 0.42,
        grade: 'proficient',
        color: '#EDA500',
        description:
          '• Familiarity with security frameworks and standards such as OWASP (Open Web Application Security Project) Top Ten, NIST (National Institute of Standards and Technology) guidelines, and CIS (Center for Internet Security) benchmarks is vital. Secure Developers should follow these best practices to ensure the development of secure software..',
      },
      {
        title: 'Authentication and Authorization',
        score: 0.6,
        grade: 'proficient',
        color: '#EDA500',
        description:
          '• Familiarity with security frameworks and standards such as OWASP (Open Web Application Security Project) Top Ten, NIST (National Institute of Standards and Technology) guidelines, and CIS (Center for Internet Security) benchmarks is vital. Secure Developers should follow these best practices to ensure the development of secure software..',
      },
      {
        title: 'Security Frameworks and Standards',
        score: 0.42,
        grade: 'proficient',
        color: '#EDA500',
        description:
          '• Familiarity with security frameworks and standards such as OWASP (Open Web Application Security Project) Top Ten, NIST (National Institute of Standards and Technology) guidelines, and CIS (Center for Internet Security) benchmarks is vital. Secure Developers should follow these best practices to ensure the development of secure software..',
      },
      {
        title: 'Incident Response Planning',
        score: 0.76,
        grade: 'proficient',
        color: '#EDA500',
        description:
          '• Familiarity with security frameworks and standards such as OWASP (Open Web Application Security Project) Top Ten, NIST (National Institute of Standards and Technology) guidelines, and CIS (Center for Internet Security) benchmarks is vital. Secure Developers should follow these best practices to ensure the development of secure software..',
      },
      {
        title: 'Secure Coding Documentation',
        score: 0.6,
        grade: 'proficient',
        color: '#EDA500',
        description:
          '• Familiarity with security frameworks and standards such as OWASP (Open Web Application Security Project) Top Ten, NIST (National Institute of Standards and Technology) guidelines, and CIS (Center for Internet Security) benchmarks is vital. Secure Developers should follow these best practices to ensure the development of secure software..',
      },
      {
        title: 'Continuous Learning and Adaptability',
        score: 0.42,
        grade: 'proficient',
        color: '#EDA500',
        description:
          '• Familiarity with security frameworks and standards such as OWASP (Open Web Application Security Project) Top Ten, NIST (National Institute of Standards and Technology) guidelines, and CIS (Center for Internet Security) benchmarks is vital. Secure Developers should follow these best practices to ensure the development of secure software..',
      },
      {
        title: 'Communication and Collaboration',
        score: 0.76,
        grade: 'proficient',
        color: '#EDA500',
        description:
          '• Familiarity with security frameworks and standards such as OWASP (Open Web Application Security Project) Top Ten, NIST (National Institute of Standards and Technology) guidelines, and CIS (Center for Internet Security) benchmarks is vital. Secure Developers should follow these best practices to ensure the development of secure software..',
      },
    ]
    setSecureSkills(aptitudeArray)
  }, [])
  return (
    <div className={`card ${className}`} style={cardStyle}>
      <div className='card-body pb-0'>
        <div className='d-flex align-items-center mb-5'>
          <div className='d-flex align-items-center flex-grow-1'>
            {selectedIndex == -1 ? (
              <div className='d-flex flex-column'>
                <a href='#' className='text-gray-800 text-hover-primary fs-6 fw-bold'>
                  Description
                </a>
                <span className='text-gray-800 text-hover-primary fs-6 fw-bold'>
                  Click on the bars to get more information about each competency
                </span>
              </div>
            ) : (
              <div className='d-flex flex-column'>
                <a
                  href='#'
                  className='text-800  fs-2 fw-bold'
                  style={{color: skill?.color || '#000000'}}
                >
                  {skill?.title}
                </a>

                <span className='fs-6 text-gray-800'>
                  {skill?.description?.split('\n').map((line, index) => (
                    <React.Fragment key={index}>
                      {line}
                      <br />
                    </React.Fragment>
                  ))}
                </span>
                {skill.score != 0 && skill.score != null && skill.score * 100 <= approvedPerc && (
                  <>
                    <p></p>
                    <span className='fs-6 text-gray-800'>
                      You have already been assigned trainings, for a detailed look, check the
                      Training Path tab
                    </span>
                  </>
                )}
                {/* {skill.score == null && skill.score * 100 <= approvedPerc && (
                  <>
                    <span></span>
                    <br />
                  </>
                )} */}

                {foundationTraining.length != 0 && skill.score * 100 <= approvedPerc && (
                  <>
                    <p></p>
                    <span className='fw-bold fs-4'>Foundation Trainings</span>
                    <table className='table  align-middle gs-0 gy-4 table-fixed'>
                      <tbody>
                        {foundationTraining.map((training, index) => (
                          <tr>
                            <td>{training['course name']}</td>
                            <td>{training['course type']}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </>
                )}
                {coreTraining.length != 0 && skill.score * 100 <= approvedPerc && (
                  <>
                    <span className='fw-bold fs-4'>Core Trainings</span>
                    <table className='table  align-middle gs-0 gy-4 table-fixed'>
                      <tbody>
                        {coreTraining.map((training, index) => (
                          <tr>
                            <td>{training['course name']}</td>
                            <td>{training['course type']}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </>
                )}
                {advancedTraining.length != 0 && skill.score * 100 <= approvedPerc && (
                  <>
                    <span className='fw-bold fs-4'>Advanced Trainings</span>
                    <table className='table  align-middle gs-0 gy-4 table-fixed'>
                      <tbody>
                        {advancedTraining.map((training, index) => (
                          <tr>
                            <td>{training['course name']}</td>
                            <td>{training['course type']}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export {SkillsTrainingDescription}
