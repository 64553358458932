import React, {ReactNode, createContext, useContext, useState} from 'react'

interface CallTimeContextProps {
  callInterval: number
  setCallInterval: React.Dispatch<React.SetStateAction<number>>
}

const CallTimeContext = createContext<CallTimeContextProps | undefined>(undefined)

export const useCallInterval = (): CallTimeContextProps => {
  const context = useContext(CallTimeContext)
  if (!context) {
    throw new Error('useApiCallInterval must be used within an ApiCallTimeProvider')
  }
  return context
}

interface CallIntervalProviderProps {
  children: ReactNode // Define the children prop as ReactNode
}

export const CallTimeProvider: React.FC<CallIntervalProviderProps> = ({children}) => {
  const [callInterval, setCallInterval] = useState<number>(60000) // Default interval of 60 seconds

  return (
    <CallTimeContext.Provider value={{callInterval, setCallInterval}}>
      {children}
    </CallTimeContext.Provider>
  )
}
