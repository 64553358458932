/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import {Button, Modal} from 'react-bootstrap'
import {EmployeeEducation} from '../../../../dataTypes'

type Props = {
  className: string
  employeeEducation: EmployeeEducation[]
  setShowModal: any
}

const EducationTimeline: React.FC<Props> = ({className, employeeEducation, setShowModal}) => {
  function sortedEdu() {
    employeeEducation.forEach((education) => {
      if (education['year completed'] === '-') {
        education['year completed'] = new Date().getFullYear().toString()
      }
    })

    employeeEducation.sort((a, b) => parseInt(a['year completed']) - parseInt(b['year completed']))
  }
  const [selectedDegree, setSelectedDegree] = useState(0)

  // Add a useEffect hook to update the selectedDegree state when the edu state changes
  useEffect(() => {
    sortedEdu()
  }, [])

  useEffect(() => {
    if (employeeEducation.length > 0) {
      setSelectedDegree(Number(employeeEducation[0]['education id']))
    } else {
      setSelectedDegree(0)
    }
  }, [employeeEducation])

  return (
    <div className={`card ${className}`}>
      <div className='card-header align-items-center border-0 mt-4'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='fw-bold mb-2 text-dark'>Education</span>
        </h3>
        <div className='card-toolbar'>
          {/* Add a button to toggle the form visibility */}
          <Button
            onClick={() => setShowModal(true)}
            className='btn btn-primary'
            data-target='Form '
          >
            Add Education
          </Button>
        </div>
      </div>

      <div className='container-fluid py-5'>
        <div className='row'>
          <div className='col-lg-12'>
            <div className='horizontal-timeline'>
              <ul className='list-inline items d-flex flex-row justify-content-between'>
                {employeeEducation.map((item, index) => (
                  <>
                    <li
                      key={item['education id']}
                      className='list-inline-item items-list'
                      style={{
                        position: 'relative',
                        textAlign: 'center',
                        paddingTop: '70px',
                        marginRight: 0,
                        width: '300px',
                      }}
                    >
                      <div className='px-4'>
                        <div
                          className='event-date badge bg-primary d-flex justify-content-center'
                          style={{
                            position: 'absolute',
                            top: '36px',
                            left: 0,
                            right: 0,
                            width: '75px',
                            margin: '0 auto',
                            fontSize: '0.9rem',
                            paddingTop: '8px',
                            color: 'white',
                          }}
                        >
                          {item['year completed']}
                        </div>
                        <h5 className='pt-2'>{item.degree}</h5>
                        <p className='text-muted'>
                          {item.school} ({item.country})
                        </p>
                        <div>
                          <button
                            className='btn btn-primary btn-sm'
                            onClick={() => setSelectedDegree(Number(item['education id']))}
                          >
                            Read more
                          </button>
                        </div>
                        {selectedDegree === item['education id'] && (
                          <div>
                            <div style={{fontWeight: 'bold'}}>Competencies:</div>
                            <div>{item.competencies}</div>
                            <div style={{fontWeight: 'bold'}}>Accomplishments:</div>
                            <div>{item.accomplishments}</div>
                          </div>
                        )}
                      </div>
                    </li>
                    {index < employeeEducation.length - 1 && (
                      <hr
                        style={{
                          width: '80%',
                          marginTop: '40px',
                          borderTop: '3px dotted',
                        }}
                      ></hr>
                    )}
                  </>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export {EducationTimeline}
