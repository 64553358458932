import {FC, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../_metronic/layout/core'
import {GradesBreakdown} from '../../_metronic/partials/widgets/lists/GradesBreakdown'
import {EntityPendingInfoDonut} from '../../_metronic/partials/widgets/_new/cards/EntityPendingInfo'
import {OverallEntGrade} from '../../_metronic/partials/widgets/_new/cards/OverallEntGrade'
import {useUserContext} from '../../UserContext'
import config from '../../config.json'
import {OverallAssessments} from '../../_metronic/partials/widgets/_new/cards/OverallAssessments'
import {DepartmentsTable, GradesBreakdownTable} from '../../_metronic/partials/widgets'
import {EntAssessmentInfoTable} from '../../_metronic/partials/widgets/tables/EntAssessmentsInfoTable'
import {EntityTalentInfo} from '../../_metronic/partials/widgets/_new/cards/EntityTalentInfo'
import {TalentInfoTable} from '../../_metronic/partials/widgets/tables/TalentInfoTable'
import {DepartmentBreakdownChart} from '../../_metronic/partials/widgets/charts/DepartmentBreakdownChart'

import {EntBaseDashCard} from '../../_metronic/partials/widgets/_new/cards/EntBaseDashCard'
import {EntBIEPChart} from '../../_metronic/partials/widgets/charts/EntBIEPChart'
import {EntDeptDashCard} from '../../_metronic/partials/widgets/_new/cards/EntDeptDashCard'
import {YearOfExpChart} from '../../_metronic/partials/widgets/charts/YearOfExpChart'
import {EntMapCard} from '../../_metronic/partials/widgets/_new/cards/EntMapCard'
const DashboardPage: FC = (props) => {
  const [refreshFlip, setRefreshFlip] = useState(false)

  const setRefreshTrue = () => {
    setRefreshFlip(true)
    // console.log('refresh should be true now??')
  }

  useEffect(() => {
    if (refreshFlip == true) {
      // console.log('refresh then', refreshFlip)
      const timeoutId = setTimeout(() => {
        setRefreshFlip(false)
      }, 3500) //3.5 sec

      return () => clearTimeout(timeoutId)
    }

    // console.log('refresh now', refreshFlip)
  }, [refreshFlip])

  return (
    // <div>
    //   <div className='row g-2 mb-5' style={{height: '560px'}}>
    //     <div className='col-9 h-100'>
    //       <div className='row g-2 mb-5' style={{height: '150px'}}>
    //         <div className='col-4 h-100'>
    //           <OverallEntGrade
    //             className='h-md-3 h-100'
    //             title='Average Grade'
    //             chartSize={50}
    //             chartLine={8}
    //             chartRotate={0}
    //           />
    //         </div>
    //         <div className='col-4 h-100'>
    //           <EntityPendingInfoDonut
    //             className='h-md-3 h-100'
    //             subtitle=''
    //             chartSize={50}
    //             chartLine={8}
    //             chartRotate={0}
    //             color='white'
    //           />
    //         </div>
    //         <div className='col-4 h-100'>
    //           <OverallAssessments className='h-md-3 h-100' title='Overall Assessments' />
    //         </div>
    //       </div>
    //       <div className='row g-2 mb-5' style={{height: '400px'}}>
    //         <div className='col-8 h-100'>
    //           <GradesBreakdownTable className='h-md-3 h-100 ' title='Score Weight Breakdown' />
    //         </div>
    //         <div className='col-4 g-2 h-100'>
    //           <EntityTalentInfo className='h-md-3 h-100' subtitle='' color='white' />
    //         </div>
    //       </div>
    //     </div>
    //     <div className='col-3 h-100'>
    //       <GradesBreakdown title='Grades Breakdown' className='h-100' />
    //     </div>
    //   </div>
    //   <div className='row g-2 mb-5' style={{height: '400px'}}>
    //     <div className='col-4 h-100'>
    //       <DepartmentsTable className='h-md-3 h-100' title='Department Breakdown' />
    //     </div>
    //     <div className='col-4 h-100'>
    //       {/* <EntAssessmentInfoTable className='card card-xl-stretch ' title='Assessment Breakdown' /> */}
    //       <DepartmentBreakdownChart className='h-md-3 h-100' title='Department Breakdown' />
    //     </div>
    //     <div className='col-4 h-100'>
    //       <TalentInfoTable className='card card-xl-stretch ' title='Talent Pool' />
    //     </div>
    //   </div>
    // </div>

    <div>
          <div className='row g-2 mb-5' style={{height: '560px'}}>
            <div className='col-8 g-2 h-100'>
              <div className='row g-2 mb-5' style={{height: '150px'}}>
                <div className='col-3 h-100'>
                  <EntBaseDashCard
                    className='h-100 m-0'
                    svgIcon='briefcase'
                    color=''
                    iconColor='dark'
                    title='entity alias'
                    titleColor='dark'
                    description='Entity Name'
                    descriptionColor='dark'
                    refreshBool={refreshFlip}
                  />
                </div>
                <div className='col-3 h-100'>
                  <EntBaseDashCard
                    className='h-100 m-0'
                    svgIcon='chart'
                    title='entity score'
                    titleColor='white'
                    titleType='num'
                    iconColor='white'
                    description='Average Score'
                    descriptionColor='white'
                    refreshBool={refreshFlip}
                  />
                </div>
                <div className='col-3 h-100'>
                  <EntBaseDashCard
                    className='h-100 m-0'
                    svgIcon='profile-user'
                    color=''
                    iconColor='dark'
                    title='number of employees'
                    titleColor='dark'
                    description='Total Users'
                    descriptionColor='dark'
                    refreshBool={refreshFlip}
                  />
                </div>

                <div className='col-3 h-100'>
                  <EntDeptDashCard
                    className='h-100 m-0'
                    svgIcon='data'
                    color=''
                    iconColor='dark'
                    title='number of departments'
                    titleColor='dark'
                    description='Number of Departments'
                    descriptionColor='dark'
                    refreshBool={refreshFlip}
                  />
                </div>
                {/* <div className='col-2 h-100'>
              <DashCard
                className='h-100 p-2 m-0'
                svgIcon='book'
                color=''
                iconColor='dark'
                title='40'
                titleColor='dark'
                description='Number of Assessments'
                descriptionColor='dark'
              />
            </div> */}
              </div>
              <div className='row g-2 mb-5' style={{height: '400px'}}>
                <div className='col-6 h-100'>
                  <EntBIEPChart
                    className='h-md-3 h-100'
                    title='Grades Scored'
                    description='Number of Candidates who have scored these grades'
                    refreshBool={refreshFlip}
                  />
                  {/* <BIEPchart  /> */}
                </div>
                <div className='col-6 h-100'>
                  <GradesBreakdownTable
                    className='h-md-3 h-100 '
                    title='Score Weight Breakdown'
                    setRefreshFlip={setRefreshTrue}
                    refreshBool={refreshFlip}
                  />
                  {/*  */}
                </div>
              </div>
            </div>
            <div className='col-4 g-2 h-100'>
              <EntMapCard title={'Employee Nationality Distribution'} />
            </div>
          </div>
          <div className='row g-2 mb-5' style={{height: '450px'}}>
            <div className='col-3 g-2 h-100'>
              <DepartmentBreakdownChart className='h-md-3 h-100' title='Department Breakdown' />
            </div>
            <div className='col-4 h-100'>
              <DepartmentsTable
                className='h-md-3 h-100'
                title='Department Score Breakdown'
                refreshBool={refreshFlip}
              />
            </div>
            <div className='col-5 h-100'>
              <YearOfExpChart className='h-md-3 h-100' title='Years of Experience' />
            </div>
          </div>
          <div className='row g-2 mb-5' style={{height: '400px'}}>
            <div className='col-5 h-100'>
              {/* <YearOfExpChart className='h-md-3 h-100' title='Years of Experience'/> */}
              <EntAssessmentInfoTable className='h-md-3 h-100 ' title='Assessment Breakdown' refreshBool={refreshFlip}/>
            </div>

            <div className='col-3 h-100'>
              <EntityTalentInfo
                className='h-md-3 h-100'
                subtitle=''
                color='white'
                setRefreshFlip={setRefreshTrue}
                refreshBool={refreshFlip}
              />
            </div>
            <div className='col-4 h-100'>
              <TalentInfoTable
                className='h-md-3 h-100 '
                title='Subject Matter Expert Pool'
                refreshBool={refreshFlip}
              />
            </div>
          </div>
        </div>
  )
}

const EntDashboard: FC = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'ENTITY.DASHBOARD'})}</PageTitle>
      {<DashboardPage />}
    </>
  )
}

export {EntDashboard}
