import React, {useEffect, useState} from 'react'
import {Button, Card, Form, Table} from 'react-bootstrap'

interface SurveyData {
  nativeLanguage: string
  [key: string]: string // Index signature
}

interface Question {
  text: string
  options: string[]
}

export const Testing: React.FC = () => {
  const [surveyData, setSurveyData] = useState<SurveyData>({
    nativeLanguage: '',
  })

  const languages = [
    'English',
    'Spanish',
    'French',
    // Add more languages as needed
  ]

  const [currentQuestionIndex, setCurrentQuestionIndex] = useState<number>(0)

  const questions: Question[] = [
    {
      text: 'Question 1',
      options: [
        'Carefree, positive',
        'Flexible, yielding',
        'Argumentative, speaks out',
        'Stable, relaxed',
      ],
    },
    {
      text: 'Question 2',
      options: [
        'Optimistic, positive attitude to life',
        'Adjustable, agreeable',
        'Respectful, obedient',
        'Shows initiative, wants change',
      ],
    },
    {
      text: 'Question 3',
      options: [
        'Sociable, loves company',
        'Consistent, balanced',
        'Independent, own initiative',
        'Mild, reserved',
      ],
    },
    {
      text: 'Question 4',
      options: [
        'Well-meaning, pleasant',
        'Careful, cautious',
        'Decisive, not easily shaken',
        'Convincing, inspiring',
      ],
    },
    {
      text: 'Question 5',
      options: [
        'Kind, eager to help',
        'Subdued, tends to give in',
        'Earned attention, admirable',
        'Strong willed, firm',
      ],
    },
    {
      text: 'Question 6',
      options: [
        'Genial, makes friends easily',
        'Restrained, holds back',
        'Exact, precise',
        'Straightforward, outspoken',
      ],
    },
    {
      text: 'Question 7',
      options: [
        'Values information, specialist',
        'Team oriented, fits into group',
        'Temperamental, energetic',
        'Easy-going, tolerant of others',
      ],
    },
    {
      text: 'Question 8',
      options: [
        'Bold, strong-willed',
        'Considerate, well-mannered',
        'Contented, happy',
        'Smooth-talking, good speaker',
      ],
    },
    {
      text: 'Question 9',
      options: [
        'Avoid extremes, sensitive',
        'Easily used, self-sacrificing',
        'Centre of the group, lively',
        'Overbearing, aggressive',
      ],
    },
    {
      text: 'Question 10',
      options: [
        'Inquiring, observant',
        'Thoughtful, service-minded',
        'Strong-willed, goal-oriented',
        'Cheerful, good-tempered',
      ],
    },
    {
      text: 'Question 11',
      options: [
        'Humble, a follower',
        'Self-conscious, shy',
        'Confident, bold',
        'Enthusiastic, supportive',
      ],
    },
    {
      text: 'Question 12',
      options: [
        'Assertive, straight-forward',
        'Trusts people, good speaker',
        'Understanding, sympathetic',
        'Tolerant, accepting',
      ],
    },
    {
      text: 'Question 13',
      options: ['Amusing, witty', 'Complying, punctual', 'Tough, brazen', 'Unruffled, calm'],
    },
    {
      text: 'Question 14',
      options: [
        'Disciplined, self-controlled',
        'Lively, energetic',
        'Ready to help, well-meaning',
        "Doesn't give in, strong",
      ],
    },
    {
      text: 'Question 15',
      options: [
        'Relies on and trusts in people',
        'Peaceful, satisfied',
        'Confident, leaves no room for doubt',
        'Thorough, quiet',
      ],
    },
    {
      text: 'Question 16',
      options: [
        'Wants to win, competitive',
        'Sensitive, empathetic',
        'Sociable, likes company',
        'Adaptable, compliant',
      ],
    },
    {
      text: 'Question 17',
      options: [
        'Willing, helpful',
        'Adjustable, adaptable',
        'Enthusiastic, goes along',
        'Imaginative, self-confident',
      ],
    },
    {
      text: 'Question 18',
      options: [
        'Follower, obeys instructions',
        'Daring, tough',
        'Delightful, refreshing',
        'Faithful, responsible',
      ],
    },
    {
      text: 'Question 19',
      options: [
        'Risk-taker, confident',
        'Friendly, open',
        'Adjustable, flexible',
        'Moderate, careful',
      ],
    },
    {
      text: 'Question 20',
      options: [
        'Chatty, extrovert',
        'Restrained, moderate',
        'Organised, follows tradition',
        'Uncompromising, firm',
      ],
    },
    {
      text: 'Question 21',
      options: [
        'Quick, seeks change',
        'Reliable, predictable',
        'Popular, generally liked',
        'Well-organised, thorough',
      ],
    },
    {
      text: 'Question 22',
      options: [
        'Persuasive, convincing',
        'Reserved, shy',
        'Gentle, kind',
        "Individual, a 'character'",
      ],
    },
    {
      text: 'Question 23',
      options: [
        'Aligning, agreeable',
        'Stubborn, unshakeable',
        'Delightful, attractive',
        'Arouses sympathy, sweet',
      ],
    },
    {
      text: 'Question 24',
      options: [
        'Sense of responsibility, obedient',
        'Thoughtful, restrained',
        'Fun-loving, unruly',
        'Decisive, headstrong',
      ],
    },
  ]

  const totalPages = Math.ceil(questions.length / 4) + 2

  useEffect(() => {
    // Load saved survey data from local storage
    const savedSurveyData = localStorage.getItem('surveyData')
    if (savedSurveyData) {
      setSurveyData(JSON.parse(savedSurveyData))
    }
  }, [])

  useEffect(() => {
    // Save survey data to local storage whenever it changes
    localStorage.setItem('surveyData', JSON.stringify(surveyData))
  }, [surveyData])

  const handleSurveyDataChange = (question: string, value: string) => {
    const [questionIndex, option] = value.split('-')

    const conflictingRowColumn = Object.entries(surveyData).find(([key, val]) => {
      if (key === question) return false

      const [index, option2] = val.split('-')
      return index === questionIndex || option2 === option
    })

    setSurveyData((prevData) => ({
      ...prevData,
      [question]: value,
    }))

    // Log the current state of the radio button for each question
    // console.log('Survey Data:', surveyData)
  }

  const goToNextPage = () => {
    if (currentQuestionIndex < totalPages - 1) {
      if (currentQuestionIndex === 0 || currentQuestionIndex === 1) {
        setCurrentQuestionIndex((prevIndex) => prevIndex + 1)
      } else {
        const selectedCount = Object.values(surveyData).filter((value) => value !== '').length

        if (selectedCount >= 2) {
          setCurrentQuestionIndex((prevIndex) => prevIndex + 1)
        } else {
          alert('Please select at least 2 options.')
        }
      }
    }
  }

  const isRowSelected = (question: string, value: string) => {
    const [questionIndex] = value.split('-')

    const selectedOptions = Object.entries(surveyData)
      .filter(([key, val]) => key !== 'nativeLanguage')
      .map(([key, val]) => val)

    const selectedOptionsForRow = selectedOptions.filter(
      (option) => option.split('-')[0] === questionIndex
    )

    return selectedOptionsForRow.includes(value)
  }

  const renderPageQuestions = () => {
    if (currentQuestionIndex === 0) {
      return (
        <>
          <Form.Group controlId='nativeLanguage'>
            <Form.Label>
              <div className='text-gray-800 text-hover-primary fs-2 fw-bolder me-1'>
                Native Language
              </div>
            </Form.Label>
            <Form.Text>
              <div className='text-gray-800 fs-2'>
                <br />
                Kindly choose your preferred language from the options provided below.
                <br />
                If you cannot find your language, we kindly ask you not to proceed with the
                questionnaire.{' '}
              </div>
            </Form.Text>
            <br />
            <Form.Control
              as='select'
              value={surveyData.nativeLanguage}
              onChange={(e) => handleSurveyDataChange('nativeLanguage', e.target.value)}
            >
              <option value=''>Select your native language</option>
              {languages.map((language) => (
                <option key={language} value={language}>
                  {language}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
        </>
      )
    }

    if (currentQuestionIndex === 1) {
      return (
        <>
          <Form.Group controlId='question2'>
            <Form.Label>
              <div className='text-gray-800 text-hover-primary fs-2 fw-bolder me-1'>
                Instructions
              </div>
            </Form.Label>
            <br />
            <Form.Text>
              <div className='text-gray-800 fs-3'>
                Read the instructions carefully before you begin.
                <br />
                Imagine describing yourself at work while completing the questionnaire.
                <br />
                Each of the following 24 questions contains four descriptive lines. <br />
                1. Begin by choosing the line that describes you most and click the corresponding
                box in column M.
                <br />
                2. Then choose the line that describes you least and click the corresponding box in
                column L. <br />
                3. After that, go to the next question by clicking the Next button.
                <br />
                Note! Answer quickly. The whole questionnaire should take 7-10 minutes.
                <br />
              </div>
            </Form.Text>
          </Form.Group>
        </>
      )
    } else if (currentQuestionIndex >= 2 && currentQuestionIndex < totalPages - 1) {
      const question = questions[currentQuestionIndex - 2]

      return (
        <Form.Group controlId={`question${currentQuestionIndex}`}>
          <Form.Label>{question.text}</Form.Label>
          <Table bordered>
            <thead>
              <tr>
                <th>M</th>
                <th>L</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {question.options.map((option) => (
                <tr key={option}>
                  <td>
                    <Form.Check
                      type='radio'
                      name={`question${currentQuestionIndex}-column-M`}
                      value={`question${currentQuestionIndex}-${option}-M`}
                      checked={isRowSelected(
                        `question${currentQuestionIndex}`,
                        `question${currentQuestionIndex}-${option}-M`
                      )}
                      onChange={(e) =>
                        handleSurveyDataChange(`question${currentQuestionIndex}`, e.target.value)
                      }
                      disabled={isRowSelected(
                        `question${currentQuestionIndex}`,
                        `question${currentQuestionIndex}-${option}-L`
                      )}
                    />
                  </td>
                  <td>
                    <Form.Check
                      type='radio'
                      name={`question${currentQuestionIndex}-column-L`}
                      value={`question${currentQuestionIndex}-${option}-L`}
                      checked={isRowSelected(
                        `question${currentQuestionIndex}`,
                        `question${currentQuestionIndex}-${option}-L`
                      )}
                      onChange={(e) =>
                        handleSurveyDataChange(`question${currentQuestionIndex}`, e.target.value)
                      }
                      disabled={isRowSelected(
                        `question${currentQuestionIndex}`,
                        `question${currentQuestionIndex}-${option}-M`
                      )}
                    />
                  </td>
                  <td>{option}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Form.Group>
      )
    } else if (currentQuestionIndex === totalPages - 1) {
      return (
        <>
          <h3>Thank you for completing the questionnaire!</h3>
          <Button variant='primary' onClick={() => setCurrentQuestionIndex(0)}>
            Restart
          </Button>
        </>
      )
    }

    return null
  }

  return (
    <>
      <Card>
        <Card.Body>
          <Form>
            {renderPageQuestions()}
            <br />
            {currentQuestionIndex < totalPages - 1 && (
              <Button variant='primary' onClick={goToNextPage}>
                Next
              </Button>
            )}
          </Form>
        </Card.Body>
      </Card>
    </>
  )
}
