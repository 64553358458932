import {Route, Routes, Outlet, Navigate, useLocation} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../../_metronic/layout/core'
import {FC, useState, useEffect} from 'react'
import {UsersListCompetenciesWrapper} from '../../apps/user-management/users-list/UsersListCompetencies'

const usersBreadcrumbs: Array<PageLink> = [
  {
    title: 'User Management',
    path: '/user-management',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: false,
    isActive: false,
  },
]

const EmployeeCompetencies: FC = () => {
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  var employeeQueryFromSearch = searchParams.get('employeeQuery')

  const [selectedEmployee, setSelectedEmployee] = useState('')

  useEffect(() => {}, [selectedEmployee])
  return <UsersListCompetenciesWrapper />
}
export {EmployeeCompetencies}
