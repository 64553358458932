import {useEffect} from 'react'
import {Navigate, Routes} from 'react-router-dom'

export function Logout() {
  const logout: any = null
  useEffect(() => {
    logout()
    document.location.reload()
  }, [logout])

  return (
    <Routes>
      <Navigate to='/loginpage' />
    </Routes>
  )
}
