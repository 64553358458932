import {UserListCard, UserListCompetencies} from '../../../../../_metronic/partials/widgets'

const UsersListCompetencies = () => {
  return (
    <>
      <UserListCompetencies className='card-xxl-stretch mb-5 mb-xl-8' />
    </>
  )
}

const UsersListCompetenciesWrapper = () => <UsersListCompetencies />

export {UsersListCompetenciesWrapper}
